export default {
	"homePage": {
		"welcome": "Welcome",
		"name": "home"
	},
	"resultPage": {
		"title": "Results",
		"name": "Search Results"
	},

	"addUser": {
		"fName": "First Name",
		"lName": "Last Name",
		"emailId": "Email",
		"pwd": "Password",
		"role": "Role",
		"addUser": "Add User",
		"addTitle": "Create User",
		"addDesc": "Create an organized profile for new users with  admin details and roles.",
		"update": "Update",
		"updateTitle": "Edit User",
		"updateDesc": "Update details pertaining to the existing user's account. ",
		"cancel": "Cancel",
		"invalid": "Invalid User Details",
		"addFailed": "Unable to add User",
		"ok": "OK",
		"addSuccess": "Success! {fname} {lname} added successfully!",
		"updateSuccess": "Success! {fname} {lname} update succesful!",
		"agent": "Agency",
		"system": "System",
		"yes": "Yes",
		"no": "No",
		"confirmUpdate": "Save the changes for {fname} {lname}?"
	},
	"exchngRt": {
		"title": "Exchange Rate",
		"editBtn": "Edit",
		"saveBtn": "Save",
		"backBtn": "Go Back",
		"okBtn": "OK",
		"actions": "Actions",
		"exrate": "Exchange Rate",
		"lastUpdtd": "Last Update",
		"fromCurrency": "From Currency",
		"toCurrency": "To Currency",
		"updateSuccess": "Success! Exchange rate update successful ",
		"download": "Download ExchangeRate.xls",
		"desc": "Save, edit and update the exchange rates between foreign currencies based on the existing market rates."
	},
	"userStatus": {
		"agencyName": "Agency Name",
		"firstName": "First Name",
		"lastName": "Last Name",
		"backBtn": "Go Back",
		"title": "Manage Users",
		"desc": "Manage User is a directory of users with their profile details and status pertaining to this account.",
		"name": "Name",
		"emailId": "Email",
		"pwd": "Password",
		"role": "Role",
		"update": "Update",
		"status": "Status",
		"currentStatus": "Current Status",
		"inactive": "Inactive",
		"active": "Active",
		"notActive": "Not Active",
		"activate": "Activated",
		"deactivate": "Deactivated",
		"reset": "Reset Password",
		"cancel": "Cancel",
		"ok": "OK",
		"activateSuccess": "Success! {fname} {lname} activation successful",
		"deactivateSuccess": "{fname} {lname} has been deactivated ",
		"yes": "Yes",
		"no": "No",
		"confirmUpdate": "Are you sure you wish to update {fname} {lname}?",
		mailSuccess: "Password Reset link has been sent to {email}. Please check your inbox.",
		pwResetConfirmation: "Are you sure you want to reset password for {email}?"
	},
	"manageUser": {
		"totalUsers": "Users",
		"active": "Active",
		"inactive": "Inactive",
		"newUser": "New User",
		"edit": "Edit",
		"listEmpty": "We are sorry, but no users were found for your search",
		"desc": "Develop, organize and manage the profiles of the Agency Login account's users.",
		"manage": "Manage",
		"name": "Name",
		"emailId": "Email",
		"actions": "Actions",
		"status": "Status",
		"role": "Role",
		"agencyName": "Agency Name",
		"title": "Users",
		"clearFilter": "Clear Filters",
		"admindesc": "Develop, organize and manage the profiles of the Agency Login and System Login accounts'' users."
	},
	"rolesPermissions": {
		"title": "Roles & Permissions",
		"listEmpty": "Please fill out the fields to continue",
		"ok": "OK",
		"select": "Role",
		"func": "Function",
		"save": "Save",
		"R": "Read",
		"W": "Write",
		"D": "Delete",
		"A": "Approve",
		"Y": "Yes",
		"roleName": "Role",
		"addRolePlaceholder": "Please enter role name",
		"isAgentRole": "Is Agency Role?",
		"yes": "Yes",
		"no": "No",
		"confirmUpdate": "Update {roleName} role?",
		"success": "Success! Update successful!",
		"desc": "Permissions control access to administrative tasks. Specify permissions for the role and assign specific roles to users. "
	},
	"langTest": {
		"langTest": "Welcome to Your Multi Language App"
	},
	"language_picker_helper": {
		"language_picker_helper": "Preferred language"
	},
	"languages": {
		"english": "English",
		"norwegian": "Norwegian"
	},
	"agentRegPage": {
		"registration": "Agency Registration",
		"coDetails": "Business Details",
		"agencyName": "Agency Name",
		"busRegNo": "Business Number",
		"iataNo": "IATA Number",
		"agencyPlace": "Agency Name",
		"busRegPl": "Enter agency's business ID",
		"iataNoPl": "Enter IATA number",
		"contDet": "Contact Information",
		"agtName": "Name",
		"agtAdrs": "Billing Address",
		"agtCity": "City",
		"agtState": "Province",
		"agtCountry": "Country",
		"agtPostal": "Postal Code",
		"emailName": "Main Agency Email",
		"agtMblNo": "Main Phone",
		"namePl": "Enter your name",
		"adrsPl": "Address",
		"cityPl": "Enter city name",
		"statePl": "Province",
		"postPl": "Postal Code",
		"mailPl": "Enter your Email ",
		"mblPl": "Enter your contact number",
		"logDet": "Login Details",
		"logMail": "Email Address",
		"logPwd": "Password",
		"captcha": "Captcha",
		"logPl": "Email",
		"pwdPl": "Password",
		"cptPl": "Add the captcha code you see above",
		"submit": "Register",
		"thnkyou": "Thank you for registering with us. We will be contacting you via email shortly. ",
		"backTo": "Go Back",
		"home": "Home",
		"haveAcct": "Registered Users",
		"loginHere": "Have an account? LOGIN",
		"login": "LOGIN"
	},
	"loginPg": {
		"loginTitle": "Agency Login",
		"fillIn": "Create an account to get started",
		"emailPl": "Email",
		"pwdPl": "Password",
		"frgtPwd": "Forgot your password?",
		"login": "Login",
		"email": "Email Address",
		"pwd": "Password",
		"noAcct": "New Agency?",
		"reg": "REGISTER",
		"invalid": "Invalid data",
		"cptPl": "Enter the captcha code",
		jwtExpiredValue: 'Your session has expired. Please enter your email/password again to continue.'

	},
	"sysLogin": {
		"loginTitle": "System Login",
		"desc": "Please contact your system administrator.",
		"emailPl": "Enter Email",
		"email": "Email",
		"pwd": "Password",
		"pwdPl": "Enter password",
		"frgtPwd": "Forgot your password?",
		"login": "Login",
		"noAcct": "New Agency?",
		"reg": "REGISTER",
		"invalid": "Invalid data"
	},
	"forgtPwd": {
		"frgtPwdTxt": "Forgot your password?",
		"enterMail": "Please provide the email address associated with your account. A password reset link will be sent to that address.",
		"fgMailPl": "Email Address",
		"pwdResetTxt": "Link for password change request has been sent!",
		"successfully": "Success!",
		"resetPwd": "Reset Password",
		"resetTxt": "If you haven't received any Email, re-enter your Email to generate a new link.",
		"mailSuccess": "Link has been sent to your Email address",
		"backTo": "Go Back",
		"login": "Login"
	},
	"chngPwd": {
		"title": "Reset Password",
		"desc": "You can change your account's password here, periodically, for security reasons. ",
		"currPwd": "Current Password",
		"currPwdPl": "Enter current password",
		"newPwd": "New Password",
		"newPwdPl": "Enter new password",
		"cnfrmPwd": "Confirm New Password",
		"cnfrmPl": "Confirm new password",
		"chngPwd": "Update",
		"cancel": "Cancel",
		"ok": "OK",
		"alert": "Password reset successful!",
		"toolTip": "Minimum 8, Maximum 20 characters, Atleast 1 special character, 1 capital letter and 1 digit."
	},
	"approveAgt": {
		"title": "Approve Agency",
		"invalidPaymentType": "Select Payment Method",
		"apprvAgtTxt": "Approve or deny an agency's request to engage in business with you.",
		"slctName": "Select Agency",
		"bcItem": {
			"agncyInfo": "Agency Info",
			"selCity": "Select City",
			"paymethod": " Payment Method",
			"agtType": "Agency type"
		},
		"agentName": "Name",
		"action": "Action",
		"approve": "Approve",
		"mailId": "Email Address",
		"regDate": "Registered Date",
		"status": "Status",
		"pending": "Pending",
		"approved": "Approved",
		"rejected": "Rejected",
		"change": "Change Agency",
		"agencyName": "Agency Name",
		"busRegNo": "Business Number",
		"iataNo": "IATA Number",
		"agtName": "Name",
		"agtAdrs": "Billing Address",
		"agtCity": "City",
		"agtState": "Province",
		"agtCountry": "Country",
		"agtPostal": "Postal Code",
		"emailName": "Email Address",
		"agtNo": "Mobile Phone Number",
		"approveBtn": "Approve",
		"rejectBtn": "Reject",
		"reject": "Do you wish to reject {agent}?",
		"yesBtn": "Yes",
		"noBtn": "No",
		"samAgtCode": "Sample agency code",
		"nextBtn": "Next",
		"cancel": "Cancel",
		"goBack": "Go Back",
		"currName": "Currency",
		"payName": "Payment Methods",
		"agtType": "Agency Type",
		"preAgt": "Prepaid Agency",
		"postAgt": "Postpaid Agency",
		"weekly": "Weekly",
		"twice": "Twice per week",
		"monthly": "Monthly",
		"paySchedule": "Payment Schedule",
		"creditLimit": "Credit Limit",
		"creditPl": "Enter credit limit",
		"creditLimitType": "Credit Limit Type",
		"approvMsg": "Success! Agency approved successfully!",
		"agentCode": "Agency code: {code}",
		"agtCode": "Agency Code",
		"setUpComm": "Setup Commission Percentage",
		"agtTypeWeek": "If Weekly",
		"agtTypeTwice": "If Twice per week",
		"agtTypeMonthly": "If Monthly",
		"agtWeeklyS1Txt": "Schedule-{id} System sends invoice by Monday and payment has to be settled by Wednesday",
		"agtWeeklyS2Txt": "Schedule-{id} System sends invoice by Wednesday and payment has to be settled by Friday",
		"agtTwiceS1Txt": "First invoice will be sent by Sunday and payment has to be settled by Tuesday.",
		"agtTwiceS2Txt": "Second invoice will be sent by Wednesday and payment has to be settled by Friday.",
		"agtMonthS1Txt": "Schedule-{id} System will send invoice by the month end and payment has to be settled within the 5th day of the next month.",
		"noAgt": "No Pending Agencies",
		"confirmApprove": "Approve {agent}?",
		"fixed": "Fixed",
		"repWeekly": "Replenish Weekly",
		"repTwice": "Replenish Twice per Week",
		"repMonthly": "Replenish Monthly",
		"advSettings": "Advanced Settings",
		"maxExtTime": "Maximum statement extension time",
		"perStatement": "Per statement",
		"statementType": "Statement Type",
		"noState": "Please fill out this field",
		"noList": "List is Empty",
		"btnName": "Add Province",
		"back": "Go Back",
		creditLimStatement: "The Agent can book until the credit limit in that {type}. Once the period is over, Invoice will be sent to them. Also, in the next {type}, The agent will get same credit limit again. Every {type}, creditlimit will be replenished again regardless of previous.",
		"fixedStatement": "The Credit Limit is fixed and will not be replenished. If Agent exceeds the credit limit, He can't make any ticketing. They have to make payment for existing pending invoices first.",
		"blkTckt": 'Block ticketing even if one statement is pending'
	},
	"crdtNote": {
		"title": "Credit Note",
		"crdtAmt": "Deposit ",
		"desc": "Credit Note is a reward, offered to the agencies who are making extraordinary progress. The amount will be deposited in the Agency's account.",
		"ref": "Reference",
		"bookId": "Reservation ID",
		"crdt": "Credit",
		"slctCurncy": "Preferred Currency",
		"slctAgnt": "Select Agency Name",
		"yesBtn": "Yes",
		"noBtn": "No",
		"agentCode": "Agency code : {agntCode}",
		"confirmationMsg": "Deposit {code} {crdtAmt} to {agncNam} ({agntCode})?",
		"viewStmt": "View Statement",
		"okBtn": "OK",
		"crdtSuccess": "{code} {crdtAmt} has been deposited to {agncNam} account",
		"pnr": "PNR",
		"passenger": "Passenger",
		"reservId": "Reservation ID",
		"search": "Search",
		"cancel": "Cancel",
		"agntAlert": "Select Agency",
		"amtPl": "Enter credit amount",
		"referencePl": "Enter Reference ID",
		"creditBalances": "Credit Balance",
		"debitBalances": "Debit Balance",
		"totalDueAmt": "Total Due"
	},
	"topUpPage": {
		"paymentFee": "Total Payment Fee",
		"topUpTitle": "Topup",
		"topUpAmt": "Topup Amount",
		"topUpAmtPl": "Enter your topup amount",
		"topUpPayFees": "Payment fees",
		"totalAmt": "Total Amount",
		"paymentRef": "Payment Reference",
		"confirm": "Pay Now",
		"cancel": "Cancel",
		"approveMsg": "has been topup to agency's account",
		"amount": "Amount",
		"viewStatement": "View statement",
		"rejectMsg": "Confirm Topup?",
		"ok": "OK",
		"topUpDesc": "Recharge via online payment to continue access for the account."
	},
	"passInfo": {
		"passenger": "Passenger",
		"lName": "Last Name",
		"lastPl": "Please enter your Last Name",
		"fName": "First Name",
		"frstPl": "Please enter your First Name",
		"dob": "Date of Birth",
		"dobPl": "Date of Birth",
		"nation": "Country of Residence",
		"gender": "Gender",
		"idType": "ID Type",
		"idNo": "ID Number",
		"idNPl": "ID Number",
		"expryDate": "Passport Expiry Date",
		"cntDet": "Contact Information",
		"email": "Email Address",
		"mailPl": "Email",
		"mblNo": "Mobile Phone Number",
		"mblPl": "Number",
		"cName": "Contact Name",
		"contPl": "Enter your name",
		"bookRef": "Booking reference",
		"continue": "Continue",
		"tryAgain": "Try Again",
		"hlpTxt": "Please fill out the fields. Enter passenger name as it appears in passport or ID type.",
		"depDobInvalid": "Date of Birth",
		"arrDobInvalid": "The {pType} passenger will be {age} years old on the return date. Please book a {nxtPType} ticket for the return flight separately.",
		"adult": "Adult",
		"child": "Child",
		"infant": "Infant",
		"of": "of",
		"invalid": "is Invalid",
		"paxPageBtnTooltip": "Please fill out the fields to continue",
		"mobileInfo": "Please provide your mobile phone number for us to contact you in case of last minute changes to the itinerary.",
		"mailInfo1": "Your booking confirmation and check-in information will be sent to this email address.",
		"mailInfo2": "If you haven't received any confirmation email from us after booking, please check the spam folder.",
		"mandatory": "Please fill out the field.",
		"idTypeResultEmpty": "Please enter a valid id type",
		"enterValid": "Please enter valid",
		"nrc": "NRC",
		"passport": "Passport",
		"bookingSummary": "Booking Summary",
		"idInfo": "Please make sure your ID is valid for at least six months from your date of travel.",
		"cSearchPax": {
			"typeToSearch": "Please type to search",
			"noMatchesFound": "No matche found!",
			"threeCharactersToSearch": "Please type atleast 3 characters to search"
		},
		"sameId": "Same ID number should not be accepted.",
		"sameName": "Same passenger name cannot be accepted.",
		"required": "Please fill all the mandatory fields.",
		countryResultEmpty: 'Select country as in your passport'

	},


	cEditTickets: {
		listEmpty: 'Apply filters to view your bookings',
		filterAppliedlistEmpty: 'No bookings Found for your given filter',
		emptyFilterDesc: 'Please Select at least one filter',

		refund: 'Refund',
		exchange: 'Exchange',
		refundRequested: 'Refund Requested',
		refundProcessing: 'Refund Processing',
		newRefundReq: 'New Refund Requested',
		newExchangeReq: 'New Exchange Requested',
		refundSuccess: 'Refund Success',
		refundFailed: 'Refund Declined',
		refundCancelled: 'Refund Declined',


		exchangeRequested: 'Exchange Requested',
		exchangeProcessing: 'Exchange Processing',
		exchangeCancelled: 'Exchange Declined',
		exchangeSuccess: 'Exchange Success',
		exchangeFailed: 'Exchange Declined',

		bookDate: 'Booking Date',
		bookingDetails: 'Booking Details',
		desc: 'This page is designed for a detailed view towards the  ticket management  process which includes passenger details, ticket and flight details, adding and removal of tickets.',
		retryPayment: 'Retry Payment',
		ticketNumber: 'Ticket numbers',
		nationality: 'Nationality',
		issuedDate: 'Issued Date',
		cancelledDate: 'Cancelled Date',
		goBackBtn: "Go Back",
		passenger: "Passenger",
		passengers: "Passengers",
		oneWay: "One Way",
		roundTrip: "Round Trip",
		multiCity: "Multi City",
		ticketDetails: "Ticket Details",
		reservationId: "Reservation Id",
		ticketingDeadline: "Ticketing Deadline",
		pnr: "PNR",
		dateOfBooking: "Date of Booking",
		travelDate: "Travel Date",
		bookBy: "Book by",
		flightDetails: "Flight Details",
		paymentDetails: "Payment Details",
		status: "Status",
		userId: "User Id",
		transactionId: "Transaction ID",
		paymentType: "Payment Type",
		approvalCode: "Approval Code",
		currency: "Currency",
		orderAmt: "Booking Amount",
		paymentFee: "Payment Fee",
		totalAmt: "Total Amount",
		name: "Name",
		gender: "Gender",
		idNumber: "Passport/Document ID",
		dob: "Date of Birth",
		male: "Male",
		female: "Female",
		passengerDetails: "Passenger Details",
		failed: "Failed",
		ticketed: "Ticketed success",
		hold: "Hold",
		pending: 'Ticketing Pending',
		payStatusPending: 'Payment Pending',
		ticketIssueDate: 'Ticket Issue Date',
		ticketDeadline: 'Ticket Deadline',
		amount: 'Amount',
		contactDetails: 'Contact Details',
		reference: 'Reference',
		route: 'Route',
		flightNumber: 'Flight Number',
		number: ' No.',
		bookingStatus: 'Booking Status',
		bookedBy: 'Booked by',
		pricingDetails: 'Pricing Details',
		view: 'View',
		departure: 'Departure',
		return: 'Return',
		leg: 'Leg',
		apply: 'Apply',
		more: 'More',
		filter: 'Filter',
		noMatchFound: 'No match found',
		fromDateLabel: 'From date',
		toDateLabel: 'To date',
		clearAll: 'Clear All',
		cancel: 'Cancel',



	},
	"systemTopUpPage": {
		"ok": "OK",
		"topUpBtn": "Topup",
		"cancel": "Cancel",
		"topUpAmtPl": "Enter your topup amount",
		"topUpAmt": "Topup Amount",
		"systemTopUpTitle": "Topup",
		"systemTopUpDesc": "Recharge online, with preferred amount, to continue access to the account.",
		"selectAgentName": "Select Agency Name",
		"agentCode": "Agency code",
		"businessRegNum": "Business Number",
		"paymentRef": "Payment Reference",
		"amount": "Amount",
		"approveMsg": "Topup of amount {code} {amount} to Agency : {agent}({b2bcode}) is successful!",
		"viewStatement": "View statement",
		"agtCode": "Agency code: {code}",
		"rejectMsg": "Topup {currency} {amount} to Agency: {agent} ({code})?",
		"noBtn": "No",
		"yesBtn": "Yes",

	},
	"searchPage": {
		"searchBtn": "Search",
		"below": "below",
		"years": "yrs",
		"above": "above",
		"paxDesc": "Please select exact number of passengers to view the best prices",
		"paxHeading": "Passengers",
		"currName": "Currency",
		"from": "From",
		"to": "To",
		"headerFlight": "Flights",
		"select": "Select",
		"adult": "Adult",
		"child": "Child",
		"infant": "Infant",
		"airlineClass": "Class",
		"searchPlaceHolder": "Type to search",
		"tripInvalid": "Invalid Trip",
		"routeLengthInvalid": "Invalid route length",
		"routeInvalid": "Please select route",
		"returnDateInvalid": "Return date cannot be earlier than the date of departure",
		"minAdult": "Atleast one adult is mandatory",
		"maxPax": "Maximum 9 passengers allowed per booking",
		"dateInvalid": "Please select a date later than today",
		"rcntSrch": "Recent Searches",
		"journeyDate": "Departure",
		"noMatFound": "No match found",
		"search": "Search",
		"selectDeparture": "Please select departure city",
		"noArrivalsFound": 'There are no arrivals',
		"infantEqualToAdult": "Infant count should be equal or less than Adult count",
		"selectCurrency": "Please select currency",
		"close": "Clear All",
		"passenger": "Passenger",
		"passengers": "Passengers",
		"addAnotherFlight": "Add Another flight",
		"return": "Return",
		"citizenLabel": "Canadian citizen?",
		citizenName: 'Canadian citizen',
		foreigner: 'Foreigner',
		"infants": "Infant",
		"title": "Available Flights",
		"secondTitle": "in one place!",
		"yes": "Yes",
		"no": "No",
		"economy": "Economy/Premium Economy",
		"buisness": "Business",
		"buisFirst": "Business/First",
		"premium": "Premium Economy",
		"first": "First",
		"oneWay": "One-way",
		"roundTrip": "Round-trip",
		"multiCity": "Multi-city",
		"subTitle": "Search and book flights on the go!"
	},
	"flightResults": {
		"headingFirst": "Available Flights",
		"adult": "{n} Adult | {count} Adults",
		"child": "{n} Child | {count} Children",
		"infant": "{n} Infant | {count} Infants",

		"flight": "{n} Flight Available | {count} Flights Available",
		"flightNotAvail": "We're sorry. No flights are available for the given date.",
		"noDepartureFlights": "We're sorry. There are no flights for departure for the given date. Refine your search",
		"noArrivalFlights": "We're sorry. There are no return flights for the given date. Refine your search",
		trip: 'Trip',
		"sortBy": "Sort by",
		"oneWay": "One-way",
		"roundTrip": "Round-trip",
		"multiCity": "Multi-city",
		"airlines": "Carrier",
		"fliResFailed": "pricing flight failed &selected flight is invalid",
		"continue": "Continue",
		"cheapest": "Cheap",
		"quickest": "Quick",
		"lowest": "Lowest",
		"highest": "Highest",
		"earliest": "Earliest",
		"arrival": "Return flights",
		"shortest": "Shortest",
		"duration": "duration",
		"best": "Best",
		"priceDesc": "Prices are per person and include all taxes and fees. All flight times and dates are in local time.",
		"book": "Book",
		"departure": "Departure",
		"showall": "Show All",
		"to": "to",
		"allStops": "All Stops",
		"stops": "Stops",
		"stop": "Stop",
		"nonstop": "Non-Stop",
		"footerBtnTooltip": "Please select flight(s) to continue",
		"price": "price",
		"noElementsFoundSearch": "No elements found. Consider changing the search query.",
		"listIsEmpty": "List is empty.",
		fewer: 'or fewer',
		"cMLFlightResCard": {
			"showMore": "Show More",
			"noOfDaysTooltip1": "Flight lands the next day",
			"noOfDaysTooltip2": "Flight lands after",
			"days": "days"
		},
		"cMLSuggFlightResCard": {
			"pricePerPassenger": "Price per passenger",
			"book": "Book"
		},
		"cEmptyFlightRes": {
			"noFlightsFrom": "There are no flights from",
			"to": "to",
			"noFlightsForRoute": "There are no flights for these routes",
			"noFlightsForAppliedFilter": "There are no flights for applied filters"
		},
		"apply": "Apply",
		"modifySearch": "Refine Search",
		"flightsAvailable": "Available Flights",
		"noFlights": "We're sorry. No flights are available for the given date.",
		"searching": "Searching",
		"return": "Return",
		"sortOptions": {
			"lowest": "Lowest price",
			"highest": "Highest price",
			"earliest": "Earliest Departure",
			"earliestArr": "Earliest Arrival",
			"shortest": "Shortest Duration"
		}
	},
	"dbtNote": {
		"title": "Debit Note",
		"desc": "The Debit Note is a process of taking an amount of money from the agency’s account in case of mistaken payment.",
		"dbtAmt": "Debit Amount",
		"ref": "Reference",
		"bookId": "Reservation ID",
		"dbt": "Debit",
		"slctCurncy": "Select Currency",
		"slctAgnt": "Select Agency Name",
		"yesBtn": "Yes",
		"noBtn": "No",
		"agentCode": "Agency code : {agntCode}",
		"confirmationMsg": "Debit {code} {dbtAmt} to Agency {agncNam} ({agntCode})?",
		"viewStmt": "View Statement",
		"okBtn": "OK",
		"dbtSuccess": "{code} {dbtAmt} debit from {agncNam} account success!!",
		"search": "Search",
		"cancel": "Cancel",
		"amtPl": "Enter debit amount",
		"referencePl": "Enter reference"
	},
	"prepaidReport": {
		"title": "Prepaid Statements",
		"fileName": "Prepaid Report",
		"desc": "A directory of detailed and organized list of all the financial statements, in a summarized form. The report helps you to stay on track with transactions done."
	},
	"report": {
		fromLabel: 'From date',
		toLabel: 'To Date',
		"createdBy": "Created by",
		"createdAt": "Created at",
		"email": "Email",
		"contact": "Contact",
		"transactionRpt": "Transaction Report from",
		"title": "Agency Transaction Report",
		"rprtLabel": "Agency transaction report",
		"slctAgnt": "Select Agency Name",
		"slctDate": "Select Transaction Date",
		"sumitBtn": "Submit",
		"frm": "From",
		"to": "To",
		"transactionDate": "Transaction Date",
		"transactionId": "Transaction ID",
		"reference": "Reference",
		"by": "By",
		"credit": "Credit",
		"debit": "Debit",
		"openingBalance": "Opening Balance",
		"closingBalance": "Closing Balance",
		"openingDueAmt": "Opening Due Amount",
		"closingDueAmt": "Closing Due Amount",
		"description": "Description",
		"transactionType": "Transaction Type",
		"orderId": "Order ID",
		"currency": "Currency",
		"selectCurrency": "Select Currency",
		"noRecordsFound": "No Transactions found",
		"desc": "A directory of detailed and organized list of all the financial statements, in a summarized form. The report helps you to stay on track with transactions done.",
		"download": "Download Pdf",
		"cancel": "Cancel",
		"yes": "Yes",
		"ok": "OK",
		"downloadConfirm": "Do you wish to download {name} as PDF?",
		"transType": "Transaction Type",
		"downldSuccess": "File download success!"
	},
	"pricing": {
		"title": "Setup Markup / Markdown",
		"markUpDwn": "Markup / Markdown",
		"amount": "Amount"
	},
	"notFoundErr": {
		"go": "Home",
		"title": '404',
		"subtitle": "Oops! You're lost",
		"desc": "The page you are looking for is not found."
	},
	"tempErr": {
		"go": "Home",
		"title": '500',
		"subtitle": "Houston, we have a problem!",
		"desc": "The page you are looking for is temporarily unavailable."
	},
	"sectorType": {
		"ALL": "ALL",
		"DOM": "DOMESTIC",
		"INT": "INTERNATIONAL"
	},
	"setupPricing": {
		"totalTier": "Total Tiers",
		"active": "Active",
		"inactive": "Inactive",
		"pricingTier": "Pricing Tier Name",
		"status": "Status",
		"actions": "Actions",
		"title": "Setup Pricing & Tiers",
		"currStatus": "Current Status",
		"manage": "Manage",
		"new": "New Tier",
		"listEmpty": "No Tiers Found",
		"clone": "Clone",
		"rename": "Rename",
		"delete": "Delete",
		"ok": "OK",
		"cancel": "Cancel",
		"deleteStatus": "Do you wish to permanently delete {name}?",
		"cloneStatus": "{name} cloned successfully!",
		"desc": "Create and manage different pricing tiers for selected airlines and launch them for a specific travel period to promote booking!",
		"yes": "Yes",
		"no": "No"
	},
	"perPaxPriceInfo": {
		"adult": "Adults",
		"child": "Children",
		"infant": "Infants",
		"fare": "Fare",
		"taxFees": "Tax & Fees",
		"tax": "Tax",
		"baseFare": "Base Fare",
		"total": "Total",
		"paymentFee": "Payment Fee"
	},
	"totalpriceInfo": {
		"totalAmt": "Total Price",
		"tax": "Tax",
		"baseFare": "Base Fare",
		"total": "Total"
	},
	"selFlightInfo": {
		"selflights": "Selected Flights",
		"trackPrice": "Track prices",
		"nonStp": "Non-stop",
		"departFlight": "Depart Flight",
		"TravlTime": "Travel Time",
		"fareRules": "Fare rules",
		"kiloGram": "Kg",
		"viewFareRules": "View Fare Rules",
		"departureFlight": "Departure flight",
		"returnFlight": "Return Flight",
		"flightDetails": "Flight Details",
		"modifySearch": "Refine Search",
		"bookingSummary": "Booking Summary",
		"class": "Class",
		stop: "Stop",
		stops: "Stops"
	},
	"managePricing": {
		"tierName": "Tier Name",
		"tierStatus": "Tier Status",
		"addPrice": "Add pricing to continue",
		"addTier": "Adding Tire Name to continue",
		"pricing": "Pricing",
		"new": "Add New Pricing",
		"save": "Save",
		"statusDesc": "Are you sure want to change?",
		"cancel": "Cancel",
		"edit": "Edit",
		"delete": "Delete",
		"activate": "Activated",
		"deactivate": "Deactivated",
		"active": "Active",
		"inactive": "Inactive",
		"currStatus": "Current Status",
		"nameUpdated": "Tier name updated successfully",
		"activeStatus": "{name} has been deactivated",
		"deactivateStatus": "{name} activated successfully ",
		"cloneSuccess": "Selected tier cloned successfully",
		"deleteInfo": "Do you wish to permanently delete {name} ?",
		"deleteSuccess": "Selected tier deleted successfully",
		"ok": "OK",
		"goBack": "Go Back",
		"travelFrom": "The Travel Period is from",
		"to": "To",
		"manageTitle": "Manage Tier|Manage Tiers",
		"addTitle": "Add Pricing & Tiers",
		"manageDesc": "Edit, clone or delete and manage the pricing values for selected airlines under the tier. You can deactivate or activate a tier here.",
		"addDesc": "Create and manage different pricing tiers for selected airlines and launch them for a specific travel period and promote booking",
		"yes": "Yes"
	},
	"createPricing": {
		fromLabel: 'From date',
		toLabel: 'To Date',
		"pModalDesc": "This is where you can define the mark up/down setting for the route and class. Once you select/enter your settings, click {buttonText} to close the box.",
		"confirm": "Are you sure want to {status}",
		"listEmpty": "No Pricing Changes",
		"travelPeriod": "Travel Period",
		"createPricing": "Create Pricing",
		"updatePricing": "Update Pricing",
		"PERC": "Percentage Based",
		"PERBOOK": "Per Booking",
		"PERPAX": "Per Passenger",
		"FIX": "Fixed Amount",
		"UP": "Mark Up",
		"DOWN": "Mark Down",
		"edit": "Edit",
		"amount": "Amount",
		"percentage": "Percentage",
		"appAll": "Apply All",
		"clearAll": "Clear All",
		"clear": "Delete",
		"ok": "OK",
		"cancel": "Cancel",
		"name": "Pricing Name",
		"goBack": "Go Back",
		"updateStatus": "Selected pricing was updated successfully",
		"createStatus": "Pricing was added successfully",
		"desc": "Create and manage different pricing tiers for selected airlines and launch them for a specific travel period and promote booking",
		"create": "Create {name} pricing?",
		"update": "Update {name} pricing?",
		"yes": "Yes",
		"clearAllConfmDesc": "Are you sure to \"Clear All\"?",
		"applyAllConfmDesc": "Are you sure to \"Apply All\"?",
		"tierName": "Tier Name",
		"selectSector": "Select Sector",
		"selectAirline": "Select Airlines",
		"applyPricing": "Apply Pricing to :",
		"pastDate": 'Please select future dates.',
		"sector": 'Sector'
	},
	"paxTitle": {
		"MR": "Mr",
		"MRS": "Mrs",
		"MISS": "Miss",
		"MSTR": "Mstr"
	},
	"paxId": {
		"PASSPORT": "PASSPORT",
		"NRIC": "NRC"
	},
	"assignPricing": {
		no: 'No',
		"bkFrom": 'Ticketing Date',
		"to": "to",
		"cancel": "Cancel",
		"title": "Assign Tiers",
		"pricingDetails": "Booking from {fromDate} to {toDate}.",
		"slctAgnt": "Select Agency",
		"edit": "Edit",
		"addNew": "Assign tier to Agency",
		"agentName": "Agency",
		"pricing": "Pricing & Tiers",
		"actions": "Actions",
		"yes": "Yes",
		"ok": "OK",
		"noTiers": "Please Add Pricing to Continue",
		"updateSuccess": "Tier assigned successfully for {name}",
		"assignSuccess": "Tier assigned successfully for {name}",
		"desc": "Assign Tiers page helps to add, allot and manage different tiers to different agencies."
	},
	"addNewPricing": {
		"title": "Assign Tiers",
		"tiers": "Tiers",
		"assign": "Assign",
		"slctAgnt": "Select Agency",
		"status": "Tier Status",
		"booking": "Ticketing Period",
		"cancel": "Cancel",
		fromLabel: 'From date',
		emptyTierDesc: 'Please select atleast one tier',
		toLabel: 'To Date',
		"anyChange": "Any changes in Pricing & Tiers will take 15 minutes to take effect in system.",
		"confirmText": "Are you sure to assign the selected tiers for Agent : {agent} ({agencyCode}) ?",
		"desc": "Assign Tiers page helps to add, allot and manage different tiers to different agencies.",
		"editDesc": "Clearly-defined page to update the assigned tiers based on your pricing strategies and market research."
	},
	"paymentSetup": {
		"title": "Payment Setup",
		"desc": "Create,activate and manage different modes of online payment for all your payments.",
		"new": "Add New",
		"cancel": "Cancel",
		"production": "Production",
		"staging": "Staging",
		"save": "Save",
		"ok": "OK",
		"updateStatus": "Payments order updated successfully",
		"active": "Active",
		"inactive": "Inactive"
	},
	"paymentFee": {
		"goBack": "Go Back"
	},
	"cB2BPayment": {
		"b2b": "B2B",
		"b2c": "B2C",
		"setup": "Setup",
		"perc": "Percentage",
		"fixedAmount": "Fixed Amount",
		"save": "Save",
		"ok": "OK",
		"name": "Name",
		"fee": "Payment Fee",
		"feeStatus": "Payment Fee Created successfully",
		"desc": "Create,activate and manage different modes of online payment for all your payments.",
		"payment": "Payment"
	},
	"addPayment": {
		cancel: 'Cancel',
		"ok": "OK",
		"save": "Save",
		"active": "Active",
		"inactive": "Inactive",
		"updateTitle": "Manage Payment",
		"updateDesc": "Modify your payment mode here",
		"btnDesc": "Do you wish to continue?",
		"activate": "Activated",
		"deactivate": "Deactivated",
		"status": "Status",
		"currency": "Currency",
		"provider": "Payment Gateway Provider",
		"group": "Payment Group",
		"name": "Name",
		"create": "The {name} Payment was created successfully",
		"update": "The {name} Payment was updated successfully",
		"statusUpdate": "The {name} has been deactivated",
		"title": "New Payment",
		"desc": "Add and set up your new payment methods to make new payments in an easier way."
	},
	"routes": {
		"title": "Routes",
		"download": "Download",
		"new": "Add New Route",
		"newAp": "Add New Airport",
		routeCode: 'Route code',
		editApirport: 'Edit Airport',
		"depCode": "Departure Code",
		"arrCode": "Arrival Code",
		"status": "Status",
		"actions": "Actions",
		"active": "Active",
		"con": "Country",
		"city": "City",
		"ok": "OK",
		"cancel": "Cancel",
		"yes": "Yes",
		"apName": "Airport",
		"inactive": "Inactive",
		"manage": "Manage",
		"desc": "This page lists all the pre-defined airports found all over the world, to choose between the departure and destination airport or city to fly. In case, if a city or airport is not listed, it can be included here.",
		"statusUpdateConfirm": "Do you want to {status} the route from {dep} to {arr} ?",
		"updateStatusSuccess": "Route {status} successfully.",
		"newRouteConfirm": "Are you sure to create route from {dep} to {arr} ?",
		"createdSuccess": "Route created successfully from {dep} to {arr}",
		"newAirportConfirm": "Are you sure to create airport {code}?",
		"editAirportConfirm": "Are you sure to edit airport {code}?",
		"airportSuccess": "{code} airport created successfully.",
		"airportEditSuccess": "{code} airport updated successfully.",

		"sameCode": "Please select different departure and arrival code",
		"listEmpty": "No Routes Found"
	},

	"pdfModal": {
		"cancel": "Cancel",
		"download": "Download",
		"desc": "Do you want to download {name} as Pdf File"
	},
	"jsonExcelModal": {
		"cancel": "Cancel",
		"download": "Download",
		"desc": "Do you want to download {name} as Excel File"
	},
	"flightDatePicker": {
		"night": "Night",
		"nights": "Nights",
		"sun": "Sun",
		"mon": "Mon",
		"tue": "Tue",
		"wed": "Wed",
		"thur": "Thu",
		"fri": "Fri",
		"sat": "Sat",
		"checkIn": "Check-in",
		"jan": "January",
		"feb": "February",
		"mar": "March",
		"apr": "April",
		"may": "May",
		"jun": "June",
		"july": "July",
		"aug": "August",
		"sep": "September",
		"oct": "October",
		"nov": "November",
		"dec": "December"
	},
	"classes": {
		"title": "Class",
		"delete": "Delete",
		"addClass": "Add Class",
		"clsName": "Class Name",
		"add": "Add",
		"no": "No",
		"yes": "Yes",
		"ok": "OK",
		"addNew": "Add New Class",
		"cancel": "Cancel",
		"addSuccess": "Class added successfully",
		"saveSuccess": "Class saved successfully",
		"nationalClass": "Canada National Class :",
		"foreignClass": "Foreigner Class :",
		"save": "Save Class",
		"delCnfrmTxt": "Are you sure to delete class '{clsItem}'?",
		"deleteSuccess": "Class '{clsItem}' has been deleted",
		"existAlert": "Class already exists.",
		"desc": "Create and keep track of the new classes added to the airlines and differentiate fares, seatings and services provided."
	},
	"cPax": {
		"paxInfoDesc": "* Passengers who do not have first name may enter their last name in the boxes for both last name and first & middle names.",
		"passport": "Passport",
		"paxInfoPassNum": "9999",
		"paxInfoExName": "TONY EDWARD",
		"paxInfoNameTitle": "First & Middle name",
		"paxInfoExLName": "JONES",
		"paxInfoExLNameTitle": "Last Name",
		"paxInfoCardTitle": "Notice on Name Entry",
		"paxInfoNameDesc": "Passenger name must be identical with the name displayed on the selected ID type",
	},
	"creditReport": {
		"title": "Credit Report",
		"slctAgnt": "Select Agency",
		"agntCode": "Agency Code",
		"agntName": "Agency Name",
		"ref": "Reference",
		"resId": "Reservation ID",
		"date": "Date & Time",
		"issuedBy": "Issued By",
		"currency": "Currency",
		"amount": "Amount",
		"reportdate": "Report Date",
		"listEmpty": "No report for {agent} from {from} to {to} .",
		"credit": "Credit",
		"resultEmpty": "No match found",
		"optionEmpty": "List is empty",
		fromLabel: 'From date',
		toLabel: 'To Date',
		"desc": "This page gives an organized brief detailed report on all the credit payments done, in a summarized form. The report helps to stay on track with credit details."
	},
	"cResIdSearchModal": {
		"pnr": "PNR",
		"passenger": "Passenger Name",
		"reservId": "Reservation ID",
		"date": "Booking Date",
		"amount": "Amount",
		"action": "Action",
		"select": "Select",
		"listEmpty": "No Reservation ID Found",
		"invalidSearch": "Please enter a field to search",
		"desc": "Get the Reservation ID, filter your search by entering PNR or Passenger Name or Reservation ID. Click 'Search' to retrieve the details.",
		"heading": "Search Reservation",
		"search": "Search"
	},
	"cAgentDetail": {
		"agntCode": "Agency Code",
		"agencyName": "Agency Name",
		"busRegNo": "Business Number",
		"iataNo": "IATA Number",
		"agtName": "Name",
		"agtAdrs": "Billing Address",
		"agtCity": "City",
		"agtState": "Province",
		"agtPostal": "Postal Code",
		"emailName": "Email Address",
		"agtNo": "Mobile Phone Number",
		"availBal": "Available Limit",
		"ticktQue": "Ticketing Queue",
		"booking": "Issued Bookings",
		"pending": "Pending",
		"agencyType": "Agency Type",
		"prepaid": "Prepaid",
		"postpaid": "Deposit",
		"availCreditLimit": "Current Due Amount",
		"issuedBookingTooltip": "Issued Bookings in current month",
		"assignedTiers": "Assigned Tiers",
		ticketingDate: 'Ticketing Date',
		"to": "To",
		"editBtn": "Edit",
		"addBtn": "Add",
		// deposit:'Deposit',
	},
	"debitReport": {
		"title": "Debit Report",
		"slctAgnt": "Select Agent",
		"agntCode": "Agency Code",
		"agntName": "Agency Name",
		"ref": "Reference",
		"resId": "Reservation ID",
		"date": "Date & Time",
		"issuedBy": "Issued By",
		"currency": "Currency",
		"amount": "Amount",
		"reportdate": "Report Date",
		"listEmpty": "No report for {agent} from {from} to {to} .",
		"debit": "Debit",
		fromLabel: 'From date',
		toLabel: 'To Date',
		"desc": "This page gives a organized brief details of all the debit payments done, in a summarized form. The report helps you to stay on track with debit details."
	},
	"cAgent": {
		"select": "Select Agency",
		"resEmpty": "No match found",
		"optEmpty": "List is Empty",
		noAgent: 'No {agtType} found'
	},
	"cSortDropDown": {
		"select": "Select"
	},
	"cFilter": {
		"filter": "Filter",
		"clearAll": "Clear All",
		"clear": "Clear",
		"time": "Time",
		"price": "Price",
		"stop": "Stops",
		"allStops": "All Stops",
		"nonStop": "Non-Stop",
		"duration": "Duration",
		"departureTimes": "Departure Times",
		"airlines": "Carrier",
		"more": "More",
		"less": "Less",
		priceFilter: "Price Filter",
		durFilter: "Duration Filter",
		stopFilter: "Stop Filter",
		alFilter: "Airline Filter",
		timeFilter: "Departure Time Filter"
	},

	"manageAgent": {
		"title": "Manage Agencies",
		"agtCode": "Agency Code",
		"agtName": "Agency Name",
		"bussNum": "Registration No.",
		"iata": "IATA",
		"name": "Name",
		"mail": "Email Address",
		"contact": "Mobile Phone Number",
		"country": "Country",
		"state": "Province",
		"agtType": "Agency Type",
		"currency": "Currency",
		"status": "Status",
		"action": "Actions",
		"manage": "Manage",
		"prepaid": "Prepaid",
		"postpaid": "Deposit",
		"active": "Active",
		"inactive": "Inactive",
		"listEmpty": "No Agencies Found",
		"desc": "This page helps in keeping track and managing the details and activity of all the approved agencies worldwide.",
		"resultEmpty": "No match found",
		"optionEmpty": "List is empty",
		"itemsPerPage": "Items Per Page",
		"totalAgent": "Total Agencies",
		"activeAgents": "Active Agencies",
		"yes": "Yes",
		"no": "No",
		"assignedTier": "Assigned Tiers",
		// deposit:'Deposit'

	},
	"manage": {
		"btnDesc": "Do you wish to change?",
		"activate": "Activated",
		"deactivate": "Deactivated",
		"payMethod": "Payment Method",
		"postpaidType": "Postpaid Type",
		"crdtLimit": "Credit Limits",
		"addCurrency": "Add Currency",
		"save": "Save",
		"cancel": "Cancel",
		"ok": "OK",
		"confirm": "Are you sure want to save the changes to Agency : {agent} ({code}) ?",
		"updtSuccess": "Agency detail updated successfully",
		"title": "Manage Agencies",
		"agtCode": "Agency Code",
		"agtName": "Agency Name",
		"bussNum": "Business Number",
		"iata": "IATA",
		"name": "Name",
		"mail": "Email Address",
		"contact": "Mobile Phone Number",
		"country": "Country",
		"state": "Province",
		"agtType": "Agency Type",
		"currency": "Currency",
		"status": "Status",
		"action": "Actions",
		"advSettings": "Advance Settings",
		"manage": "Manage",
		"prepaid": "Prepaid",
		"postpaid": "Deposit",
		"active": "Active",
		"inactive": "Inactive",
		"listEmpty": "No agency found",
		"weekly": "Weekly",
		"onceAWeek": "Twice per Week",
		"monthly": "Monthly",
		"payScheduleType": "Payment Schedule Type",
		"schedule": "Schedule",
		"desc": "This page helps to manage the details and activity of the selected agent.",
		"yes": "Yes",
		"creditLimit": "Credit Limit Type",
		"extensnTime": "Extension Time",
		"fixed": "Fixed",
		"repWeekly": "Replenish Weekly",
		"repTwice": "Replenish Twice per Week",
		"repMonthly": "Replenish Monthly",
		"perSch": "Per Statement"
	},
	"header": {
		"dashboard": "Dashboard",
		"mybooking": "My Bookings",
		"ticktQue": "Ticketing Queue",
		"search": "Search"
	},
	"statementTitle": {
		"name": "Currency",
		"statementNo": "Statement No.",
		"invoice": "Invoice",
		"dueDate": "Payment Due Date",
		"dueAmount": "Total Outstanding",
		"paidAmount": "Paid Amount",
		"balanceDue": "Balance Due",
		"select": "Select",
		"action": "Action"
	},
	"agentDeposit": {
		"outStanding": "Outstanding",
		"numOfOutstanding": "Number of pending unpaid statements",
		"numOfPaid": "Number of paid statements",
		"numOfPartialPaid": "Number of partially paid statements",
		"numOfUnpaidStatements": "Number of unpaid statements",
		"noMatchFound": "No match found",
		"clear": "Clear",
		"statementNo": "Statement No.",
		"stmntDate": "Statement Date",
		"paymntDueBy": "Payment Due By",
		"select": "Select",
		"status": "Status",
		"unpaid": "Unpaid",
		"partialPaid": "Partial Paid",
		"agencyName": "Agency Name",
		"agencyCode": "Agency Code",
		"statement": "Postpaid Statements",
		"download": "Download",
		"rp": "Report Period",
		"paid": "Paid",
		"pay": "Pay",
		"balDue": "Balance Due",
		"currency": "Currency",
		"dueAmount": "Total Outstanding",
		"sysExRate": "Exchange Rate",
		"convTotAmnt": "Converted Total Amount",
		"amntToPayment": "Amount To Payment",
		"totalAmt": "Total Amount",
		"noResult": "No Statements Found",
		"print": "Print",
		"title": "Deposit Payment",
		"desc": "Verify payments, manage due dates and pay balance dues via online globally, from anywhere, in one click.",
		"stmtTitle": "Postpaid Statements",
		"stmtdesc": "View the summary of payment details, over a given period of time, comprising all information associated with the payments.",
		"email": "Email",
		"address": "Address",
		"to": "to",
		"continue": "Continue",
		"allPaid": "Click to view all paid statements",
		fromLabel: 'From date',
		toLabel: 'To Date'
	},
	"deposit": {
		"noInvoicesFound": "No Invoices Found.",
		"noHisFound": "No History Found.",
		"payBtn": "Pay",
		"extendDuedate": "Extend Due Date",
		"paymentDueBy": "Payment Due By",
		"statementDate": "Statement Date",
		"outstanding": "Outstanding",
		"numOfAgentFound": "Number of agencies with statements",
		"postpaidBooking": "Postpaid Booking",
		"prepaidBooking": "Prepaid Booking",
		"postpaidCredit": "Postpaid Credit",
		"postpaidDebit": "Postpaid Debit",
		"prepaidCredit": "Prepaid Credit",
		"prepaidDebit": "Prepaid Debit",
		"postpaidPayment": "Postpaid Payment",
		"prepaidTopup": "Prepaid Topup",
		"transactionType": "Transaction Type",
		"transactionStmtFileNme": "Transaction Statement",
		"payHistoryFileName": "Payment History",
		"statementsFileName": "Statements",
		"invoiceFileName": "Invoice",
		"extendDueFileName": "ExtendDueHistory",
		"oneWay": "One-way",
		"roundTrip": "Round-trip",
		"multiCity": "Multi-city",
		"transactionStatement": "Transaction Statement",
		"statementNo": "Statement no.",
		"timestamp": "Last Updated",
		"changedBy": "Changed By",
		"extendAt": "Extend at",
		"extendedBy": "Extended by",
		"extendDueDateHis": "Extend Due Date History",
		"oldDate": "Actual Due Date",
		"newDate": "Extended Due Date",
		"dueAmt": "Due Amount",
		"time": "Time",
		"agentFound": "Agency Found",
		"goBack": "Go back",
		"invoices": "Invoices",
		"number": "No.",
		"passengerName": "Passenger Name",
		"numOfPax": "No.of pax",
		"route": "Route",
		"journeyType": "Journey Type",
		"orderId": "Reservation ID",
		"transactiondate": "Transaction Date",
		"name": "Name",
		"amount": "Amount",
		"transactionNum": "Transaction No.",
		"currency": "Currency",
		"paymentBy": "Payment Updated By",
		"pRef": "Payment Reference",
		"select": "Select Agency",
		"status": "Status",
		"unpaid": "Unpaid",
		"partialPaid": "Partial Paid",
		"rpDate": "Statement Date",
		"email": "Email",
		"address": "Address",
		"to": "to",
		"agencyName": "Agency Name",
		"agencyCode": "Agency Code",
		"statement": "Postpaid Statements",
		"paymentHistory": "Payment History",
		"statementPeriod": "Statement period",
		"download": "Download",
		"print": "Print",
		"rpFrom": "Report Period From",
		"rp": "Postpaid Payment",
		"balDue": "Balance Due",
		"DdCurrency": "Payment Currency",
		"payAll": "Pay All",
		"sort": "Sort By Statement Date",
		"totalAmt": "Total Amount",
		"paymentDet": "Payment Details",
		"paymentMethod": "Payment Method",
		"receivedDate": "Received Date & Time",
		"amntToPayment": "Amount To Pay",
		"paid": "Paid",
		"dueStmnts": "No of Due Statements",
		"dueAmount": "Total Outstanding",
		"currDueDate": "Current Due Date",
		"extendDueDate": "Extend Due Date",
		"sysExRate": "System Exchange Rate",
		"chngExRate": "Change Exchange Rate",
		"convTotAmnt": "Converted Total Amount",
		"apply": "Apply",
		"cancel": "Cancel",
		"extend": "Extend Due Date",
		"pay": "Pay Now",
		"weekly": "Weekly",
		"twiceAWeek": "Twice per Week",
		"monthly": "Monthly",
		"yes": "Yes",
		"no": "No",
		"depositSuccModal": "Deposit of amount {curr} {amount} to Agency : {agencyName}({agtCode}) is done!",
		"extendDateSuccDesc": "Date extended successfully",
		"modalDesc": "Are you sure to continue with the deposit {curr} {amount} to Agency : {agencyName} ({agtCode})?",
		"modalDescSucc": "Are you sure want to Extend Due Date Till ,{date}",
		"ok": "OK",
		"noResult": "No Statements Found",
		"paidAmount": "Paid Amount",
		"paymentHis": "Payment History",
		"paymentTras": "Payment Transactions",
		"sysTitle": "Deposit Payment",
		"sysDesc": "Verify payments, manage due dates and pay via online to your agencies globally, from anywhere, in one click.",
		"sysStmtTitle": "Deposit Statements",
		"sysStmtdesc": "View the summary of an agency's payment details, over a given period of time, comprising all information associated with the payments.",
		"extendDesc": "Extend the deadline of the due date and set it to a different possible date to pay your pending due amount.",
		"noMatchFound": "No match found",
		fromLabel: 'From date',
		toLabel: 'To Date'
	},
	exchangeDet: {
		alertPastDate: 'No exchange for the past date.',
		alertTc: ' Please accept terms & conditions',
		agree: 'I agree with the',
		tc: 'terms & conditions',
		proceed: 'by proceeding this Exchange',
		agencyName: 'Agency Name',
		confirm: 'Do you want to proceed this  exchange?',

		yes: 'Yes',
		no: 'No',
		ok: "OK",
		reqBy: 'Requested By',
		agencyCode: 'Agency Code',
		resID: 'Reservation ID',
		pnr: 'PNR',
		pricingSummary: 'Pricing Summary',
		orderDet: 'Order Amount',
		paymentFees: 'Payment Fees',
		total: 'Total',
		goBack: 'Go Back',
		exchange: " Proceed Exchange",
		updateStatus: 'Update Status',
		exchangeProcessing: 'Exchange Processing',
		succDesc: 'Exchange status was updated successfully',
		exchangeSuccess: 'Exchange Success',
		multiCity: 'Multi City',
		oneWay: 'One Way',
		roundTrip: 'Round Trip',
		exchangeFailed: 'Exchange Declined',
		exchangeReq: 'Exchange Requested',
		newExchangeReq: 'New Exchange Requested',
		exchangeFailedAlert: "We couldn't process your  exchange request",
		ref: 'Reference',
	},
	exchange: {
		agtExchangeDesc: "Manage requests to change the date of an issued ticket's booking and their up-to-date status. Any issued ticket's travel date alone can be changed.",
		sysExchangeDesc: "Exchange / Deny a request for change of a ticketed flight's date. Any issued ticket's travel date alone can be changed.",
		exchange: 'Exchange',
		exchangeDate: 'Exchange Date',
		remarks: 'Remarks',
		agree: 'I agree with the',
		tc: 'terms & conditions',
		proceed: 'by proceeding this Exchange',
		goBack: 'Go Back',
		btnTooltip: 'Exchange is not allowed',
		confirm: 'Do you want to proceed to exchange?',
		yes: 'Yes',
		no: 'No',
		succDesc: 'Exchange request  has been sent to system',
		alert: 'Please select atleast one flight to continue.',
		alertTc: ' Please accept terms & conditions',
		ok: "OK",
	},
	"ticketingQueue": {
		refund: 'Refund',
		exchange: 'Exchange',
		"title": "Ticketing Queue",
		"resId": "Reservation ID",
		"name": "Passenger Name",
		"airline": "Airlines",
		"departure": "Departure",
		"arrival": "Arrival",
		"journeyType": "Journey Type",
		"depDate": "Departure Date",
		"retDate": "Return Date",
		"pnr": "PNR",
		"deadLine": "Ticketing Deadline",
		"amount": "Amount",
		"agtName": "Agency Name",
		"user": "User",
		"oneWay": "One-way",
		"roundTrip": "Round-trip",
		"multiCity": "Multi-city",
		"listEmpty": "Apply filters to view your bookings",
		filterAppliedlistEmpty: 'No bookings Found for your given filter',
		"mail": "Mail ID",
		"mobile": "Mobile Number",
		"actions": "Actions",
		"tcktNow": "Ticket Now",
		"cancelBk": "Cancel Booking",
		"view": "View",
		"cancelConfirm": "Are you sure you want to cancel the booking on hold? Reservation ID : {resId}",
		"trvlDate": "Travel Date",
		"bookDate": "Booking Date",
		"currency": "Currency",
		"desc": "This page is designed for a detailed view towards the ticket queue management process which includes passenger details, ticket and flight details, adding and removal of tickets.",
		"contact": "Contact",
		"route": "Route",
		"travelDate": "Travel Date",
		"bookedBy": "Booked By",
		"filterAlert": "Please select anyone filter to search.",
		"admindesc": "Ticketing Queue page shows details of all the passengers and their tickets along with the flight details and provides a detailed view on the ticket queue management process of all your agencies",
		"status": "Status",
		"cancelBtn": "Cancel Booking",
		"yesBtn": "Yes",
		"noBtn": "No",
		"cancelConfirmation": "Are you sure want to cancel booking?",
		"okBtn": "OK",
		"cancelBookingSuccessdesc": "Your booking has been successfully cancelled",
		"reservationId": "Reservation ID",
		"flightNumber": "Flight",
		"retryPaymentModalDesc1": "There is previous payment has been initiated and System did not get any status from your bank yet. If you want to proceed with another payment, There are possibility that there may be multiple duplicate payments for same booking.",
		"retryPaymentModalDesc2": "Do you want to Proceed with new payment?",
		"apply": "Apply",
		"more": "More",
		"filter": "Filter",
		"noMatchFound": "No match found",
		"fromDateLabel": "From date",
		"toDateLabel": "To date",
		"clearAll": "Clear All",
		"cancel": "Cancel",
		"cancelled": "Cancelled",
		"ticketed": "Ticketed",
		"failed": "Failed",
		"pending": "Payment Pending",
		"hold": "Hold",
		"retryPayment": "Retry Payment",
		"ticketedSuccess": "Ticketed Success",
		"ticketingPending": "Ticketing Pending",
		"bookingCancelled": "Booking Cancelled",
		"paymentStatusPending": "Payment Pending",

	},
	"cTC": {
		"newTab": "New Tab"
	},
	"paymentPage": {
		goBack: 'Go Back',
		"paymentSelHeading": "Payment Selection",
		"bookingSummary": "Booking Summary",
		"TC": "The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website and accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services/products, in accordance with and subject to, prevailing law of India. Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.",
		"tcHeading": "Terms & Conditions",
		"agreeWid": "I agree with the",
		"byProceedPay": "by proceeding with this payment",
		"note": "Note: Please ensure all contact information are correct,then click button to continue. The next screen you may be the part of payment verification process",
		"selectPayAlert": "Please select payment type",
		"selPayAlertOkBtn": "OK",
		"okButton": "OK",
		"payNowBtn": "Pay now",
		"noPaySelEnabled": "There is no payment channels enabled for",
		"contactAdmin": "Please contact administrator .",
		"currency": "Currency",
		"proceedBtn": "Proceed",
		"toolTipBtnText": "Please select payment method to continue",
		totalAmountInCurr: 'Total amount in {curr}',
		remarks: 'Remarks',
		payReferenceNum: 'Payment Reference No.',
		staffName: 'Staff Name',
		remarksPl: 'Enter remarks',
		staffNamePl: 'Enter staff name',
		payReferenceNumPl: 'Enter payment reference number',
		smallAgent: 'Select small agent / customer profile',
		smallAgentPl: 'Select small agent',
		cashReceivedPay: 'Cash Received Pay in {curr}',
		cashReceivedPayPl: 'Enter amount',
		balanceAmount: 'Balance Amount {curr}',
		name: 'Name',
		mobile: 'Mobile',
		email: 'Email ID',
		address: 'Address',
		creditBalance: 'Credit Balance',
		listEmpty: 'No match found',


	},
	cancellationNew: {
		update: 'Update',
		room: 'Room',
		desc: 'Create and manage the limit to when and how a customer can cancel a booking.',
		val: 'Check-In Date',
		roomPh: 'Select room',
		ratePlan: 'Rate Plan',
		ratePlanPh: 'Select rate plan',
		noRoom: ' Please select Hotel Room',
		noPlans: 'No Rate plans found',
		createFee: 'Create Cancellation Fee',
		setup: 'Setup',
		setupFee: 'Setup Cancellation Fee',
		noOfDays: 'Enter No. of days',
		feePerc: 'Enter Cancellation fees percentage',
		add: 'Add',
		ok: 'Ok',
		title: 'Cancellation Policies',
		createTitle: 'Create Cancellation Policies',
		updateTitle: 'Update Cancellation Policies',
		createSetUp: 'Create Setup',
		editSetup: 'Edit Setup',
		"Day": "{n} Day | {count} Days",
		"Hour": "{n} Hour | {count} Hours",
		noOfHours: 'Enter No. of Hours',
		cancelFee: 'Refund Fee',
		addConfirm: 'Are you sure you want to create cancellation policy?',
		addSuccess: 'Cancellation policy created successfully',
		editConfirm: 'Are you sure you want to edit cancellation policy?',
		editSuccess: 'Cancellation policy edited successfully',
		yes: 'Yes',
		no: 'No',
		create: 'Create',
		save: 'Save',
		cancel: 'Cancel'
	},

	ratePlanNew: {
		title: 'Create Rate Plan',
		updateTitle: 'Update Rate Plan',
		titleDesc: 'Contract of Carriage.Transportation of Passengers and Baggage on flights operated by United Airlines, Inc...',
		ratePlanInfo: 'Rate Plan Info',
		descTxt: 'Set and manage the rates at which a room will be provided.',
		advPurDaysTooltip: 'Please enter the number of days by which advance booking is allowed before check-in.Eg. If the Advance Purchase days is 2, rate plan will applicable to book before 2 days of the check in time of booking date .',
		lastminTooltip: 'Change of price for the rate plan is allowed only until before the mentioned days of booking. ',
		guestTooltip: 'Enter the number of maximum guests permitted for this rate plan while booking. Maximum guest in rate plan will override the maximum occupancy of the room. ',
		extraAdtTooltip: 'Please enter the charges for extra adult per guests in the rateplan. If the extra adult is allowed for this rate plan,it will calculate extra adult rate  from the price per night. ',
		extrachdTooltip: 'Please enter the charges for extra child per guests in the rateplan. If the extra child is allowed for this rate plan,it will calculate extra child rate  from the price per night.',
		ratePlan: 'Rate Plan',
		ratePlanCurr: 'Rate Plan Currency',
		ratePlanPh: 'Enter rate plan name',
		desc: 'Description',
		descPh: 'Enter Description',
		appDays: 'Applicable Days',
		minStay: 'Minimum Stay',
		minStayPh: 'Enter Min No.of night stay',
		maxStayPh: 'Enter Max No.of night stay',
		maxStay: 'Maximum Stay',
		val: 'Validity',
		fromDate: 'from date',
		toDate: 'to date',
		appRate: 'Applicable Rate',
		advPurchase: 'Advance Purchase Days',
		noOfDays: 'Enter No of Days',
		percPh: 'Enter percentage',
		priceDrop: 'Last Minute Price Change',
		incGuest: 'Guest Included in Rate',
		incGuestPh: ' Enter max No. of guest',
		curr: 'Currency',
		selCurr: 'Select Currency',
		fixed: 'Fixed',
		perc: 'Percentage',
		extraAdult: 'Extra Adult Rate',
		extraChildRate: 'Extra child Rate',
		enterAmount: 'Enter the amount',
		cancel: 'Cancel',
		create: 'Create',
		update: 'Update',
		confirmDesc: 'Are you sure want to leave?',
		succDesc: 'The Rate plan was created Successfully',
		updateDesc: 'The Rate plan was updated Successfully',
		active: 'Active',
		inactive: 'Inactive',
		status: 'Status',
		stay: 'Stay',
		leave: 'Leave',
		Ok: 'Ok',
		days: 'Days',
		"Day": "{n} Day | {count} Days"


	},
	simplePricing: {
		create: 'Create Pricng',
		update: 'Update Pricing',
		pricingInfo: "Pricing Info",
		pricingName: 'Pricing Name',
		pricingNamePl: 'Enter pricing name',
		travel: 'Travel Period',
		from: 'From Date',
		to: 'To Date',
		airlines: 'Airlines',
		allAirlines: 'All Airlines',
		selectAirlines: 'Select Airlines',
		sector: 'Sector',
		allSector: 'All Sector',
		selectSector: 'Select Sector',
		bookingClass: 'Booking Class',
		bookingClassPl: 'Add Booking Class',
		applicablePricing: 'Applicable Pricing',
		applicableTo: 'Applicable To',
		perPax: 'Per Pax',
		perBooking: 'Per Booking',
		price: 'Price',
		fixed: 'Fixed',
		perc: 'Percentage',
		cancel: 'Cancel',
		noAirlines: 'Please select airlines',
		listEmpty: 'list is empty',
		noResult: 'No Results found',
		addTag: 'Add this as new Class'



	},
	cHotelTaxSetup: {
		priceUnit: 'Price per night',
		selCountry: 'Please select a country',
		listEmpty: 'No hotels found',
		hotel: 'Hotel',
		hotelPl: 'Select Hotels',
		active: 'Active',
		inactive: 'Inactive',
		statusDesc: 'Are you sure you want to update the status?',
		status: 'Status',
		countryTitle: 'Create country tax',
		countryDesc: 'Counntry Tax',
		updateCountry: 'Update country tax',
		succDesc: 'The  tax was created Successfully',
		updateDesc: 'The tax was updated Successfully',
		noResult: 'No Results found',
		taxCode: 'Tax Code',
		taxCodePh: 'Enter tax code',
		desc: 'Description',
		showMore: 'Show More',
		showLess: 'Show Less',
		descPh: 'Enter tax description',
		appTypes: 'Applicable Types',
		taxTypePh: 'Select tax type',
		taxType: 'Tax Type',
		roomType: "Room Type",
		searchPh: 'Search Room type',
		guestType: "Guest Type",
		adt: 'Adult',
		chd: 'Child',
		inf: 'Infant',
		country: 'Country',
		countryPh: 'Select Country',
		checkIn: 'Check In Date',
		from: 'check in ',
		to: 'check out',
		appValue: 'Applicable Value',
		fixed: 'Fixed Value',
		perc: 'Percentage',
		amount: 'Tax Amount',
		amountPh: 'Enter the amount',
		curr: 'Currency',
		currPh: 'Select Currency',
		cancel: 'Cancel',
		add: 'Add',
		update: 'Update',
		percPh: 'Enter percentage',
		createTitle: 'Create hotel tax',
		updateTitle: 'Update hotel tax',
		titleDesc: 'Setup and manage tax details for every room type in the hotel.'




	},

	cAllHotelTax: {
		noTax: "No tax added",
		all: 'All',
		hotel: 'Hotel',
		taxCode: 'Tax Code',
		taxType: 'Tax Type',
		appFrom: ' Applicable From To',
		guestType: 'Guest Type',
		taxPerc: 'Tax Percentage',
		fixedFree: 'Fixed Free',
		view: 'view',
		edit: 'Edit',
		delete: 'Delete',
		addHotelTax: 'Add Hotel Tax',
		countryTax: 'Country Based Tax',
		addCountryTax: 'Create Country Tax',
		country: 'Country',
		hotelTax: 'Hotel Tax',
		roomType: 'Room Type',
		deleteDesc: 'Are you sure want to Delete ?',
		yes: 'Yes',
		no: 'No',
		noResult: 'No Results found',
		deleteSucc: ' The  tax was successfully deleted',
		ok: 'Ok',
		listEmpty: 'No Tax found for your given filter'

	},

	surchargeNew: {
		stay: 'Stay',
		leave: 'Leave',
		Ok: 'Ok',
		breakfast: "Breakfast per Guest",
		earlyCheckin: 'Early checkin',
		earlyCheckout: 'Late checkout',
		roomService: 'Room Service',
		title: 'Create Surcharge',
		updateTitle: 'Update Surcharge',
		surchargeDet: 'Surcharge Details',
		name: 'Surcharge Name',
		namePh: 'Enter Surcharge Name',
		type: 'Surcharge Type',
		typePh: 'Select Surcharge Type',
		roomPh: 'Select Hotel Room',
		room: 'Hotel Rooms',
		ratePlan: ' Rate Plans',
		appDays: 'Applicable Days',
		val: "Stay date",
		week: 'Applicable Days of Week ',
		surValue: 'Surcharge Value',
		fixed: 'Fixed Value',
		perc: 'Percentage',
		amount: 'Surcharge Amount',
		amountPh: 'Enter the amount',
		percPh: 'Enter percentage',
		desc: 'Add "Surcharge" details of the hotel.',
		cancel: 'Cancel',
		create: 'Create',
		update: 'Update',
		confirmDesc: 'Are you sure want to leave?',
		succDesc: 'The Surcharge was created Successfully',
		updateDesc: 'The Surcharge was updated Successfully',
		ratePlanPh: 'Select rate plan',
		noRoom: ' Please select Hotel Room',
		noPlans: 'No Rate plans found',

	},
	"reserPassword": {
		"desc": "Contact your System Admin for further assistance.",
		"title": "Reset Password",
		"newPwd": "New Password",
		"confrmPwd": "Confirm Password",
		"newPwdPl": "Enter new password",
		"confrmPwdPl": "Confirm password",
		"submit": "Submit",
		"resetSuccess": "Password reset successful",
		"misMatch": "Password mismatch!",
		"ok": "OK"
	},
	"dashBoard": {
		"title": "Dashboard",
		"desc": "Dashboard is a platform designed to help you in monitoring the status, progress and performance of your account / webpage.",
		"cChartBarDouble": {
			"salesCompLabel1": "Today",
			"salesCompLabel2": "On this day, last week"
		},
		"cAgtDBBookingNo": {
			"todaySales": "Today Sales",
			"monthlySales": "Monthly Sales",
			"weeklySales": "Weekly Sales",
			"sales": "Sales",
			"todayIssuedBooking": "Today Issued Booking",
			"monthlyIssuedBooking": "Monthly Issued Booking",
			"weeklyIssuedBooking": "Weekly Issued Booking",
			"issuedBooking": "Issued Booking",
			"outstandingPostpaidInvoice": "Outstanding Postpaid Invoice"
		},
		"cAgtDBSearchCount": {
			"todaySearchCount": "Today Search Count",
			"monthlySearchCount": "Monthly Search Count",
			"weeklySearchCount": "Weekly Search Count",
			"searchCount": "Search Count"
		},
		"cAgtDBTotalBookings": {
			"ticketingQueue": "Ticketing Queue",
			"issuedBookings": "Issued Bookings",
			"failedBookings": "Failed Bookings",
			todayBooking: 'Today Booking',
			monthlyBooking: 'Monthly Booking',
			weeklyBooking: 'Weekly Booking',
			booking: 'Booking',
			noOfTicketing: 'No. of TicketingQueue',
			noOfIssued: 'No. of Issued Bookings',
			noOfFailed: 'No. of Failed Bookings',
		},
		"cAgtDBWeeklySales": {
			"todaySales": "Today Sales",
			"monthlySales": "Monthly Sales",
			"weeklySales": "Weekly Sales",
			"sales": "Sales",
			"today": "Today",
			"thisMonth": "This Month",
			"thisWeek": "This Week",
			"thisPeriod": "This Period",
			"yesterday": "Yesterday",
			"lastMonth": "Last Month",
			"lastWeek": "Last Week",
			"lastPeriod": "Last Period"
		},
		"cSysMiniAgentDB": {
			"week": "Week",
			"month": "Month",
			"today": "Today",
			"custom": "Custom",
			fromLabel: 'From date',
			toLabel: 'To Date'
		},

	},
	"documentTitle": {
		"system": "System Portal - B2B Flight Engine",
		"agent": "Agent Portal - B2B Flight Engine"
	},
	"agentDepositPay": {
		paymentFees: 'Payment fees',
		paymentAmount: 'Payment amount',
		"paymentFee": "Payment Fee",
		"total": "Total",
		"paymentRef": "Payment reference",
		"postpaidPay": "Postpaid Payment",
		"desc": "Verify payments, manage due dates and pay balance dues via online globally, from anywhere, in one click.",
		"reference": "Reference",
		"topUpFees": "Topup fee",
		"totalAmount": "Total Amount",
		"cantBook": "Sorry, This booking cant go-through.Please try with other flight combination."
	},
	"cLoginInfo": {
		"pendingBooking": "Pending Booking",
		"noPendingBooking": "No Pending Booking",
		"pendingBookings": "Pending Bookings",
		"availBalance": "Available Limit",
		"availCreditLimit": "Available Limit"
	},
	"bookingStatus": {
		"yourFlight": "Your Flight",
		"success": "Booking Successful",
		"failed": "Booking Failed",
		"pending": "Booking Pending",
		"hold": "Booking Hold",
		"sessExp": "Your session has expired. ",
		"code": "Error Code:",
		"bookingDet": "Booking Details",
		"bookResId": "Booking Reservation ID",
		"pnr": "PNR",
		"tickDeadLine": "Ticketing Deadline",
		"ticktNum": "Ticket Number",
		"backToHome": "Back to Home",
		"flightDet": "Flight Details",
		"paxDet": "Passenger Details",
		"payDet": "Payment Details",
		"totCharge": "Total Charges",
		"fareBreak": "Fare Breakup",
		"baseFare": "Base Fare",
		"tax": "Tax",
		"fees": "Fees",
		"total": "Total",
		"ticketIssuedShortly": "Ticket will be issued shortly",
		"adult": "Adult",
		"child": "Child",
		"infant": "Infant"
	},
	"cPaymentStatus": {
		"paymentSuccessful": "Payment Successful",
		"paymentFailed": "Payment Failed",
		"paymentPending": "Payment Pending",
		"bookingDet": "Booking Details",
		"bookResId": "Booking Reservation ID",
		"payDet": "Payment Details",
		"totCharge": "Total Charges",
		"fareBreak": "Fare Breakup",
		"baseFare": "Base Fare",
		"tax": "Tax",
		"fees": "Fees",
		"total": "Total",
		"transactionId": "Transaction ID",
		paymentAmt: 'Payment Amount'
	},
	"currency": {
		"title": "Currency",
		"desc": "This page is a platform designed with multiple currencies options helps to handle the choice of currency for payment.",
		"country": "Country",
		"currency": "Currency Code",
		"rounding": "Rounding",
		"numFormat": "Number Format",
		"actions": "Actions",
		"addCurr": "Add Currency",
		"cancel": "Cancel",
		"yes": "Yes",
		"ok": "OK",
		"editCurr": "Edit Currency",
		"save": "Save",
		"editConfirm": "Are you sure you want to save changes?",
		"addConfirm": "Are you sure you want to add new currency?",
		"editSuccess": "Changes updated successfully",
		"addSuccess": "Currency added successfully",
		"listEmpty": "No Currency List Found"
	},
	"stateProvince": {
		"title": "Province",
		"desc": "This page is a platform designed with multiple currencies options helps to handle the choice of currency for payment.",
		"addState": "Add Province",
		"editState": "Edit Province",
		"country": "Country",
		"countryCode": "Country Code",
		"state": "Province",
		"stateCode": "Province Code",
		"actions": "Actions",
		"cancel": "Cancel",
		"save": "Save",
		"add": "Add",
		"yes": "Yes",
		"ok": "OK",
		"delete": "Delete",
		"addCountry": "Add Country",
		"manage": "Manage",
		"addStateConfirm": "Are you sure you want to add new province?",
		"editStateConfirm": "Are you sure you want to save changes?",
		"deleteConfirm": "Are you sure you want to delete the province?",
		"addCountryConfirm": "Are you sure you want to add new country?",
		"addStateSuccess": "Province added successfully",
		"editSuccess": "Changes updated successfully",
		"deleteSuccess": "Province deleted successfully",
		"addCountrySuccess": "Country added successfully",
		"listEmpty": "No Provinces found in the list"
	},
	"cTicketingDetail": {
		"bookingDetails": "Booking Details",
		"desc": "This page is designed for a detailed view towards the ticket queue management process which includes passenger details, ticket and flight details, adding and removal of tickets.",
		"retryPayment": "Retry Payment",
		"ticketNumber": "Ticket numbers",
		"nationality": "Nationality",
		"issuedDate": "Issued Date",
		"cancelledDate": "Cancelled Date",
		"goBackBtn": "Go Back",
		"passenger": "Passenger",
		"passengers": "Passengers",
		"oneWay": "One-way",
		"roundTrip": "Round-trip",
		"multiCity": "Multi-city",
		"ticketDetails": "Ticket Details",
		"reservationId": "Reservation ID",
		"ticketingDeadline": "Ticketing Deadline",
		"pnr": "PNR",
		"dateOfBooking": "Date of Booking",
		"travelDate": "Travel Date",
		"bookBy": "Book by",
		"flightDetails": "Flight Details",
		"paymentDetails": "Payment Details",
		"status": "Status",
		"userId": "User Id",
		"transactionId": "Transaction ID",
		"paymentType": "Payment Type",
		"approvalCode": "Approval Code",
		"currency": "Currency",
		"orderAmt": "Booking Amount",
		"paymentFee": "Payment Fee",
		"totalAmt": "Total Amount",
		"name": "Name",
		"gender": "Gender",
		"idNumber": "Passport/Document ID",
		"dob": "Date of Birth",
		"male": "Male",
		"female": "Female",
		"passengerDetails": "Passenger Details",
		"failed": "Failed",
		"ticketed": "Ticketed success",
		"hold": "Hold",
		"cancelled": "Booking Cancelled",
		"pending": "Ticketing Pending",
		"payStatusPending": "Payment Pending",
		"ticketIssueDate": "Ticket Issue Date",
		"ticketDeadline": "Ticket Deadline",
		"amount": "Amount",
		"contactDetails": "Contact Details",
		"reference": "Reference",
		"route": "Route",
		"flightNumber": "Flight Number",
		"number": "No.",
		"bookingStatus": "Booking Status",
		"bookedBy": "Booked by",
		pricingDetails: 'Pricing Details',
		view: 'View',
		departure: 'Departure',
		return: 'Return',
		leg: 'Leg'
	},
	"cAlertModal": {
		"success": "Success",
		"failed": "Failed",
		"confirmatiom": "Confirmation"
	},
	"topUpReport": {
		"agentCode": "Agency Code",
		"agencyName": "Agency Name",
		"reference": "Reference",
		"dateAndTime": "Date & Time",
		"issuedBy": "Issued By",
		"currency": "Currency",
		"amount": "Amount"
	},
	"cAlert": {
		"errorCode": "ErrorCode",
		"noData": "No Data",
		"resEmpty": "No match found",
		"optEmpty": "List is empty",
		"noAgent": "No Agency registration pending.",
		"buttonName": "Add Country"
	},
	"cAirline": {
		"resEmpty": "No match found",
		"optEmpty": "List is empty",
		"select": "Select"
	},
	"cSector": {
		"resEmpty": "No match found",
		"optEmpty": "List is empty",
		"select": "Select"
	},
	"extensionSetUp": {
		"cancel": "Cancel",
		"yes": "Yes",
		"ok": "OK",
		"editStateConfirm": "Are you sure you want to save changes?",
		"editSuccess": "Changes updated successfully",
		"cancelBtn": "Cancel",
		"editBtn": "Edit",
		"saveBtn": "Save",
		"paymtExt": "Payment Extension",
		"desc": "Extend the due date of payment and set a different deadline, with possible options.",
		"allowExt": "Allow extension 'n' days before settlement date",
		"maxExt": "Maximum extension days at a time",
		"maxExtTime": "Maximum Extension Times",
		"weekly": "Weekly",
		"twice": "Twice per Week",
		"monthly": "Monthly",
		"Day": "{n} Day | {count} Days",
		"Time": "{n} Time | {count} Times"
	},
	"contract": {
		"title": "Contract",
		"desc": "Contract Of Carriage. Transportation of passengers and Baggage on flights operated by United Airline,Inc...",
		"addContract": "Add Contract",
		contractCode: 'Contract Code',
		selectContract: 'Select Contract',
		"airlineCode": "Airline Code",
		"travelDate": "Travel Date",
		"ticketingDate": "Ticketing Date",
		"active": "Active",
		inactive: 'Inactive',
		deleteConfirm: 'Are you sure you want to delete contract?',
		deleteSuccess: 'Contract deleted successfully',
		cancel: 'Cancel',
		yes: 'Yes',
		no: 'No',
		ok: 'OK',
		modalDesc: 'One way fare for all routes {airlines} flight',
		advPurReq: 'Advance Purchase Request',
		advPurReqDesc: 'Allow this fare on before {days} days departure',
		balckoutDates: 'BlackoutDates',
		to: 'to',
		dayTimeRes: 'Day/Time Travel Restriction',
		dayDesc: 'This fare is applied for following days: ',
		timeDesc: 'This fare is applied for following time ranges: ',
		flightApplicability: 'Flight Applicability',
		flightApplicabilityDesc: 'This Fare will only be applied to following flights: ',
		season: 'Season',
		tcktDeadline: 'Ticketing Deadline',
		tcktdeadlineDesc: '{count} {units} TTL will be applied if Departure date is before {from} {units} till {to} {units}',
		delete: 'Delete',
		modalEdit: 'Edit',
		modalDelete: 'Delete',
		viewTooltip: 'View',
		deleteTooltip: 'Delete',
		editTooltip: 'Edit',
		noFares: 'No fares found. Please add fare',
		flightScheduleToolTip: 'Flight Schedule',
		fareRuleTooltip: 'Fare Rule',
		fareUploadTooltip: 'Fare Upload',
		contractName: 'Contract Name'

	},
	"addContract": {
		update: 'Update',
		"addNewContract": "Add New Contract",
		"desc": 'Create New Schedule, Schedule Filghts, Upload Fare, Define Fare Rule',
		"EditContract": "Edit Contract",
		"active": "Activated",
		"inactive": "Deactivated",
		"btnDesc": "Are you sure want to change?",
		"travelDate": "Travel Date",
		"ticketingDate": "Ticketing Date",
		"addFlightSchedule": "Flight Schedule",
		"addFareUpload": "Fare Upload",
		"addFareRule": "Fare Rule",
		flightSch: 'Flight Schedule / Contract Name',
		flightSchPl: 'Enter Contract Name',
		status: 'Status',
		"description": "Description",
		"descPl": "Enter Description",
		"airlineCode": "Airline Code",
		"airlinePl": "Select Airline",
		"gdsPl": 'Please select provider',
		"gds": "GDS / CRS",
		enableGds: 'Enable seat availability, Booking, Ticketing from GDS / CRS. Only use fares from this contract',
		addConfirm: 'Are you sure you want to add new contract?',
		editConfirm: 'Are you sure you want to save changes?',
		cancel: 'Cancel',
		addSuccess: 'Contract created successfully',
		editSuccess: 'Changes updated successfully',
		yes: 'Yes',
		no: 'No',
		ok: 'OK',
		fromLabel: 'From date',
		toLabel: 'To date',
		saveBtn: 'Save',
		cancelBtn: 'Cancel',
		advSettings: "Advance Settings",
		isDepBasedCurrency: 'Is Departure Based Currency?',
		nucCurrencyLabel: 'NUC Currency',
		taxSetup: 'Tax Setup',
		homeCountry: 'Home Country',
		currencyPl: 'Select Currency',
		countryPl: 'Select Country',

	},
	"scheduleAll": {
		"title": "Flight Schedule",
		"desc": "Contract Of Carriage.Transportation of passengers and Baggage on flights operated by United Airline,Inc...",
		"addFlightSchedule": "Add Flight Schedule",
		status: 'Status',
		"from": "From",
		"to": "To",
		"airline": "Airline",
		"flightNumber": "Flight No.",
		"via": "Via",
		"aircraftType": "Aircraft Type",
		"departureTime": "Departure Time",
		"arrivalTime": "Arrival Time",
		"weeklyOrMonthly": "Weekly / Monthly",
		"saleableBusinessSeats": "Saleable Business Seats",
		"saleableEconomySeats": "Saleable Economy Seats",
		"overBookSaleableBusinessSeats": "Overbooking Saleable Business Seats",
		"overBookSaleableEconomySeats": "Overbooking Saleable Economy Seats",
		"goBack": "Go back",
		"noSchedulesFound": "No Schedules Found",
		"changeStatusToPauseSellingConfirm": "Are you sure want to change the status deactivated to pause selling?",
		"changeStatustoDeactiveConfirm": "Are you sure want to change the status pause selling to deactivated?",
		"changeStatusActiveToDeactiveConfirm": "Are you sure want to change the status active to deactivated?",
		"deleteScheduleConfirmation": "Are you sure want to delete schedule?",
		"statusChangeSuccDesc": "Status changed successfully",
		"deleteScheduleSuccDesc": "Flight schedule has been successfully deleted",
		"okBtn": "OK",
		"yesBtn": "Yes",
		"noBtn": "No",
		"download": "Download",
		"flightSchedules": "Flight Schedules",
		"depCityPl": "Select departure city",
		"arrCityPl": "Select arrival city",
		"airlineCodePl": "Select airline code",
		"flightNumPl": "Enter flight number",
		"viaPl": "Select via (optional)",
		"aircraftTypePl": "Select aircraft type",
		"saleableBusinessSeatsPl": "Enter number of business class seats",
		"saleableEconomySeatsPl": "Enter number of economy class seats",
		"listEmpty": "No match found",
		"cancelBtn": "Cancel",
		"createBtn": "Create",
		"updateBtn": "Update",
		"createScheduleConfirmation": "Are you sure want to create schedule?",
		"updateScheduleConfirmation": "Are you sure want to update schedule?",
		"scheduleCreatedSuccDesc": "Schedule created successfully",
		"scheduleUpdatedSuccDesc": "Schedule updated successfully",
		editTooltip: 'Edit',
		deleteTooltip: 'Delete',
		activeTooltip: 'Active',
		inactiveTooltip: 'inactive',
		deactivatedTooltip: 'Deactivated',
		pauseSellingToolip: 'Pause selling',
		depArrViaNotEqual: 'depature and arrival and via city are must be not equal',
		arrTimeIfNotNextday: 'arriving time must be greater than depart time (if it is not next day)',
		allDaysSelected: 'All days selected',
		allDatesSelected: 'All dates selected',
		isSelected: 'is selected',
		areSelected: 'are selected',
		nextDay: 'Next Day',
		weekly: 'Weekly',
		monthly: 'Monthly'


	},
	"cDataTable": {
		"itemsPerPage": "Items per page",
		"cPaginationLenOption": {
			"to": "to",
			"of": "of"
		}
	},
	"fareUpload": {
		"title": "Fare Upload",
		"desc": "Contract Of Carriage.Transportation of passengers and Baggage on flights operated by United Airline,Inc...",
		"addFareUpload": "Add Fare Upload",
		"from": "From",
		"to": "To",
		"type": "Type",
		"currency": "Currency",
		"fare": "Fare",
		"passengerType": "Passenger Type",
		"class": "Class",
		"maxSeats": "Maximum Seats",
		"fareRule": "Fare Rule",
		"airlineFareBasisCode": "Airline Farebasis Code",
		"goBack": "Go back",
		"depCityPl": "Select departure city",
		"arrCityPl": "Select arrival city",
		"fareTypePl": "Select fare type",
		"currencyPl": "Select Currency",
		"farePl": "Enter base fare",
		"passengerTypePl": "Select passenger type",
		"classPl": "Enter class",
		"fareRulePl": "Select fare rule",
		"fareBasisCode": "Fare Basis Code",
		"fareBasisCodePl": "Enter fare basis code",
		maxSeatsPl: 'Enter Maximum seats',
		"actions": "Actions",
		"cancelBtn": "Cancel",
		"createBtn": "Create",
		"updateBtn": "Update",
		"noFaresFound": "No Fares Found",
		"deleteFareConfirmation": "Are you sure want to delete fare?",
		"listEmpty": "No match found",
		importSuccDesc: 'Successfully uploaded',
		"deleteFareSuccDesc": "Flight fare has been successfully deleted",
		"okBtn": "OK",
		"yesBtn": "Yes",
		"noBtn": "No",
		"flightFares": "Flight Fares",
		"download": "Download",
		"deleteToolTip": "Delete",
		"editTooltip": "Edit",
		"cabinType": "Cabin Type",
		"cabinTypePl": "Select cabin type",
		"createFareConfirmation": "Are you sure want to create flight fare?",
		"updateFareConfirmation": "Are you sure want to update flight fare?",
		"fareCreatedSuccDesc": "Flight fare created successfully",
		"fareUpdatedSuccDesc": "Flight fare updated successfully",
		"downloadTemplate": "Download Template",
		"importFareUpload": "Import Fare Upload",
		addNewRule: 'Add New Rule',
		depArrViaNotEqual: 'depature and arrival city are must not be equal',
		invalidExcelFormat: 'Invalid Excel format',
		weekly: 'Weekly',
		monthly: 'Monthly',
		oneWay: 'One way',
		roundTrip: 'Round Trip',
		adult: 'Adult',
		child: 'Child',
		infant: 'Infant',
		economy: 'Economy',
		business: 'Business',
		error: 'Error'
	},
	"index": {
		"leaveCnfrmTxt": "Are you sure you want to leave this page? You have unsaved changes.",
		leave: 'Leave',
		stay: 'Stay',
	},
	"userInfo": {
		"Logout": "Logout"
	},
	"taxAll": {
		title: 'Tax List',
		desc: 'Contract Of Carriage.Transportation of passengers and Baggage on flights operated by United Airline,Inc...',
		addTax: 'Add Tax',
		country: 'Country Code',
		taxAmount: 'Tax percentage',
		fixedFee: 'Fixed fee',
		airport: 'Airports',
		allAirport: 'All Airports',
		taxCode: 'Tax Code',
		taxDesc: 'Description',
		from: 'Applicable from to',
		taxtype: 'Tax Type',
		paxType: 'Pax Type',
		cabType: 'Cabin type',
		status: 'Status',
		noSchedulesFound: 'No Schedules Found',
		active: 'Active',
		inactive: 'Inactive',
		action: 'Action',
		domestic: 'Domestic',
		international: 'International',
		business: 'Business',
		economy: 'Economy',
		infant: 'Infants',
		chd: 'Childs',
		adt: 'Adults',
		"deleteTaxSuccDesc": "Tax has been successfully deleted",


	},

	taxSetup: {
		title: 'Tax Setup',
		desc: 'Contract Of Carriage.Transportation of passengers and Baggage on flights operated by United Airline,Inc...',
		taxCode: 'Tax Code',
		active: 'Active',
		inactive: 'Inactive',
		taxCodePl: 'Enter tax code',
		taxType: 'Tax Type',
		description: 'Description',
		descriptionPl: 'Enter description',
		cabinType: 'Cabin Type',
		paxType: 'Pax Type',
		departureCountry: 'Departure Country',
		departureAirports: 'Departure Airports',
		airportPl: 'Select airport',
		taxAmount: 'Tax Percentage',
		taxAmountPl: 'Enter tax percentage',
		of: 'of',
		sum: 'sum',
		fixedFees: 'Fixed fees',
		fixedFeesPl: 'Enter fixed fees',
		currencyPl: 'Select currency',
		ticketingDate: 'Ticketing Date',
		to: 'To',
		cancelBtn: 'Cancel',
		updateBtn: 'Update',
		createBtn: 'Create',
		addTaxBtn: 'Add Tax',
		listEmpty: 'No match found',
		yesBtn: 'Yes',
		noBtn: 'No',
		okBtn: 'OK',
		createTaxConfirmation: 'Are you sure want to create tax?',
		updateTaxConfirmation: 'Are you sure want to update tax?',
		taxCreatedSuccDesc: 'Tax created successfully',
		taxUpdatedSuccDesc: 'Tax updated successfully',
		adult: 'Adults',
		child: 'Childs',
		infant: 'Infants',
		economy: 'Economy',
		business: 'Business',
		domesticFlights: 'Domestic Flights',
		InternationalFlights: 'International Flights',
		allAirports: 'All Airports',
		custom: 'Custom',
		taxSetupPl: 'Select tax setup'


	},
	excel: {
		dropExcelFiles: 'Drop excel file here or',
		browse: 'Browse',
		continue: 'Continue',
		close: 'Close',
		okBtn: 'OK',
		supportOneFile: 'Only support uploading one file!',
		onlySupportFormats: 'Only supports upload .xlsx, .xls, .csv suffix files',
	},
	editFlight: {
		depFlight: 'Departure Flight',
		retFlight: 'Return Flight',
		flightDet: "Flight Details",
		class: 'Class',
		stops: 'Stops',
		leg: "Leg",
		nonStop: 'Non Stop',
		exchangeDate: 'Exchange Date',
		exchangedDate: 'Exchanged Date',

	},
	editPassn: {
		paxDet: 'Passenger Details',
		pName: '  Passenger Name',
		gender: 'Gender',
		rep_doc_id: ' Passport/NRC ID',
		dob: '  Date of birth',
		nat: 'Nationality',
		tkt_num: 'Ticket Number',
		male: 'Male',
		female: 'Female'

	},
	tc: {
		exchange: 'Exchange',
		refund: 'Refund',
		title: '{title}',
		titleContent: 'You can access your e-tickets online through us and modify them, subject to below mentioned terms and conditions. <br /> <br /> In case, if you no longer require the booking & ticket, you can proceed to refund as well. <br />',
		tc: 'Terms & Conditions ',
		tcContent: '<li>The fare is subject to date change and cancellation penalty.</li> <li>Access to change the date(s) of ticket(s) booked is allowed. Access is available only until the scheduled departure date & time of the first flight mentioned in your booking.</li> <li>Access to change the date of the ticket alone is permitted with applicable additional charges. No change of seats or additional services is permitted. </li> <li> If you decide to change flights, we will notify you with the applicable additional charges. </li><li>Cancellation / refund for bookings made through us alone are accepted, with applicable charges both from us and the airlines.</li> <li>If you decide to cancel the booking and refund  your ticket, we will notify you of the applicable airline cancellation charges and residual refund value. Refunds will be credited to the same online payment account used at the time of purchase of the ticket.</li><li>If you decide to cancel the booking and refund  your ticket, we will notify you of the applicable airline cancellation charges and residual refund value. Refunds will be credited to the same online payment account used at the time of purchase of the ticket.</li><li> Tickets purchased by cash, cheque invoice or other form of payments are not eligible for refund through the web.</li>',
		pointsToNote: 'Points to Note',
		pnContent: '<li>On-board meal amount is non-refundable. (Refundable applicable only if the flight gets cancelled by the respective airlines).</li> <li> Some airfares booked may be non-refundable as per the respective airline policy.</li> <li>There will be no refund for ‘no-shows’ or any partially unused flights. </li><li> In case, if the airline discontinues it’s flight operations or cancels the flight, we will not be accountable for any refunds, wherein, the same has not been refunded by the airlines.</li><li>No refunds will be made on “Non-refundable” fares.</li> <li>In case, if the passenger has cancelled the bookings directly with the airlines, he/ she will need to inform the Company with valid documentation to initiate the refund process through us. It is advisable to contact us and notify within 48 Hours of the cancellation request. </li> <li>Processing time of cancellation and refunds request may vary. The refund will be initiated from us, only after receipt & confirmation of refund from airlines is received. </li>'
	},

	refund: {
		agtRefundDesc: 'Manage and stay in track with the status of the refund requests.',
		sysRefundDesc: 'This page is a resource to manage high volume of refund requests directly, based on policies and guidelines.',
		agree: 'I agree with the',
		tc: 'terms & conditions',
		proceed: 'by proceeding this Refund',
		goBack: 'Go Back',
		refund: "Proceed Refund",
		confirm: 'Do you want to proceed this refund?',
		yes: 'Yes',
		no: 'No',
		succDesc: 'Refund request  has been sent to System',
		alert: 'Please select atleast one flight to continue.',
		alertTc: ' Please accept terms & conditions',
		ok: "OK",
		btnTooltip: 'Refund is not allowed'
	},
	refundDet: {
		alertPastDate: 'No refund for the past date.',
		agree: 'I agree with the',
		tc: 'terms & conditions',
		proceed: 'by proceeding this Refund',
		agencyName: 'Agency Name',
		confirm: 'Do you want to change status to {name}?',
		yes: 'Yes',
		multiCity: 'Multi City',
		oneWay: 'One Way',
		roundTrip: 'Round Trip',
		no: 'No',
		ok: "OK",
		reqBy: 'Requested By',
		agencyCode: 'Agency Code',
		resID: 'Reservation ID',
		pnr: 'PNR',
		pricingSummary: 'Pricing Summary',
		orderDet: 'Order Amount',
		paymentFees: 'Payment Fees',
		total: 'Total',
		goBack: 'Go Back',
		refund: " Proceed Refund",
		updateStatus: 'Update Status',
		refundProcessing: 'Refund Processing',
		succDesc: 'Refund status was updated successfully',
		refundSuccess: 'Refund Success',
		alertTc: ' Please accept terms & conditions',
		refundFailedAlert: "We couldn't process your refund request",
		refundFailed: 'Refund Declined',

		refundReq: 'Refund Requested',
		newRefundReq: 'New Refund Requested',
		ref: 'Reference'
	},
	systemReport: {

		ticketIssueDate: 'Ticket Issued Date',
		invoiceNo: 'Invoice No',
		agencyName: 'Agency Name',
		salesPerRef: 'Sales Person Reference',
		ticketNo: 'Ticket No',
		sector: 'Sector',
		paxType: 'Pax Type',
		ticketClass: ' Ticket Class',
		tourCode: 'Tour Code',
		cash_credit: 'Cash/Credit Sales',
		cashSales: 'Cash Sales',
		credit_bank: "Credit Card / bank transfer",
		prepaidAgent: 'Prepaid Agent Sales',
		postpaidAgent: 'Postpaid Agent Sales',
		submit: 'Submit'
	},

	helpTip: {
		ticketingQue_resId: 'Click to view in latest / oldest on top',
		approveAgtCrdtlimit: 'Change credit limit',
		approveAgtSchedule: 'Schedule frequency of payment day(s)',
		approveAgtExtensionTime: 'Number of times to extend an unpaid statement',
		addUserSys: 'Select to add new user to System Login',
		addUserAgt: 'Select to add new user to Agency Login',
		userStatusResetpwd: 'A link will be sent to the registered Email, to approve password reset.',
		cAgtDtlAssTier: 'Click to add/deactivate a tier',
		manageExTime: 'Number of times to extend a statement',
		manageCrdtLmt: "This is the Maximum Limit of amount to credit. If the limit's reached, clear the pending dues to proceed with booking",
		rolesPermissionsRead: 'Read-only option for the role',
		rolesPermissionsApprove: 'Permission to approve listed role',
		rolesPermissionsWrite: 'Add & Edit option for the role',
		rolesPermissionsDelete: 'Permission to delete listed role',
		setupPricingClone: 'Make a copy of the tier',
		createPricingSector: 'Flight Route',
		createPricingMarkup: 'Increase Pricing',
		createPricingMarkdwn: 'Decrease Pricing',
		createPricingPercntBase: 'Increase / Decrease by percentage',
		createPricingFixd: 'Fix the price ',
		cBkngDtlTcktQue: 'Number of tickets in queue for the agency',
		cBkngDtlIssuedBook: 'Number of issued bookings for the current calendar month',
		sysDepositPaid: 'Number of paid statements',
		sysDepositUnPaid: 'Number of unpaid statements',
		sysDepositPartPaid: 'Number of partially paid statements',
		sysDepositAgtFound: 'Number of agencies with statements',
		sysDepositOutStand: 'Number of  pending unpaid statements',
		sysDepositAllPaid: 'Click to view all paid statements',
		chngPswd: 'MUST : Characters : 8-20, 1 special character, 1 digit, 1 Capital Letter',
		cSearchPassngr: 'Atleast 1 passenger must be above 16 years of age. Atleast 1 adult should accompany  an infant.',
		crsFlightScheduleNewType: 'If the days & dates are left unselected in Weekly & Monthly, all the days & dates will be selected'

	},
	fareView: {
		title: 'Fare View',
		desc: 'List of fare views on upcoming days',
		bookingDate: 'Booking Date',
		availSeats: 'Available Seats',
		"noMatFound": "No match found",
		"selectDeparture": "Please select departure city",
		"from": "From",
		"to": "To",
		"searchPlaceHolder": "Type to search",
		"journeyDate": "Departure",
		"return": "Return",
		"currName": "Currency",
		"searchBtn": "Search",
		"routeInvalid": "Please select route",
		"yes": "Yes",
		"no": "No",
		"oneWay": "One-way",
		"roundTrip": "Round-trip",
		optional: 'Optional'

	},
	guide: {
		QsSearchTitle: 'How can we help you?',
		searchPl: 'Describe your issue',
	},
	dialog: {
		searchSession: {
			title: 'Session limit expired',
			// desc:'Refresh search for recent flight prices that change frequently due to  availability and demand. We would like to recommend you the best prices, always.',
			okBtn: 'Refresh',
		},
		jwtSession: {
			title: 'Session Expired',
			okBtn: 'Ok'
		}
	},
	agentGroup: {
		title: "Agent Grouping",
		desc: "List view for agent grouping",
		addAgtGrp: "New Agent Group",
		selectGrp: 'Select Group',
		viewTooltip: 'View',
		deleteTooltip: 'Delete',
		editTooltip: 'Edit',
		deleteConfirm: 'Are you sure you want to delete agent group?',
		deleteSuccess: 'Agent group deleted successfully',
		cancel: 'Cancel',
		yes: 'Yes',
		listEmpty: 'No match found',
	},

	newAgentGroup: {
		addAgtGrp: 'Add Agent Group',
		editAgtGrp: 'Edit Agent Group',
		agtGrpDesc: 'Create New Agent Group',
		grpName: 'Group Name',
		desc: "Description",
		addAgents: "Add Agents",
		grpNamePl: 'Enter group name',
		descPl: "Enter description",
		addAgtPl: 'Searh Agent',
		cancelBtn: 'Cancel',
		saveBtn: 'Save',
		addConfirm: 'Are you sure you want to add new agent group?',
		editConfirm: 'Are you sure you want to save changes?',
		cancel: 'Cancel',
		addSuccess: 'Agent group added successfully',
		editSuccess: 'Changes updated successfully',
		no: 'No',
		yes: 'Yes',
		ok: 'OK'
	},

	connectingFlight: {
		flightDetails: 'Flight Details',
		travelDuration: 'Travelling Duration',
		class: 'Class',
		transitDuration: 'Transit Duration {dur} in {place}',
		Duration: 'Duration'
	},

	cReport: {
		agentPrepaidReport: 'Prepaid Agent Report',
		agentPrepaidSummary: 'Prepaid Agent Summary',
		agentPostpaidReport: 'Postpaid Agent Report',
		agentPostpaidSummary: 'Postpaid Agent Summary',
		dailyOrPeriodicalReport: 'Daily / Periodical Sales Summary Report',
		TopupForecastReport: 'Topup Forecast Report',
		TopupForecastReportPostpaid: "Credit Limit Forecast For Postpaid",
		TopupForecastReportPrepaid: "Topup Forecast For Prepaid",
		detailReport: 'Daily / Periodical Sales Details Report',
		topupProjectionReport: 'Topup Projection Report',
		desc: 'This page gives a periodic detailed and organized brief details of all the financial statements done,in summarized form. The report helps you to stay on track with payments and payment details.',
		agent: 'Agent',
		currency: 'Currency',
		reportDate: 'Report Date',
		transactionDate: 'Transaction Date',
		transactionId: 'Transaction ID',
		description: 'Description',
		reference: 'Reference',
		transactionType: 'Transaction Type',
		reservationId: 'Reservation ID',
		agentSales: 'Agent Sales',
		agentPaymentSettlement: 'Agent Payment / Settlement',
		creditDebit: 'Credit(+)/Debit(-)',
		balance: 'Balance',
		selPeriodAgentSales: 'Selected period of agent sales',
		selPeriodAgentPayment: 'Selected period of Agent Payment / Settlement',
		selPeriodCreditOrDebit: 'Selected period of credit(+)/debit(-)',
		fromDate: 'From date',
		toDate: 'To date',
		submitBtn: 'SUBMIT',
		availableBalance: 'Available Balance',
		applyFilter: 'Apply filters to view reports',
		noItemsView: 'No records found',
		agencyName: 'Agency Name',
		agencyCode: 'Agency Code',
		openingBalance: 'Opening Balance',
		closingBalance: 'Closing Balance',
		selPeriodCredit: 'Selected period of credit',
		selPeriodDebit: 'Selected period of debit',
		selPeriodOpeningBalance: 'Selected period of opening balance',
		selPeriodClosingBalance: 'Selected period of closing balance',
		orderID: 'Order ID',
		invoiceNo: 'Invoice No.',
		availableLimit: 'Available Limit',
		outstanding: 'Outstanding',
		creditdebitNotes: 'Credit(+) / Debit(-) Notes',
		closingOutstandingBal: 'Closing Outstanding Balance',
		total: 'Total',
		agentPayment: 'Agent Payment',
		currencyCode: 'Currency',
		airline: 'Airline',
		airlinePl: 'Select airline',
		gds: 'GDS',
		gdsPl: 'select GDS',
		listEmpty: 'No match found',
		ticketingDeadline: 'Ticketing Deadline',
		ticketDeadlineDate: 'Ticket Deadline Date',
		bookedBy: 'Booked by',
		availableLimitAsAtToday: 'Available limit as at today',
		predictedTicketIssueAmount: 'Predicted ticket issue amount',
		overdue: 'Outstanding',
		selectPeriod: 'Select Period',
		ticketIssuedDate: 'Ticket Issued Date',
		salesPerson: 'Sales Person',
		ticketNum: 'Ticket No.',
		sector: 'Sector',
		paxType: 'Pax Type',
		ticketClass: 'Ticket Class',
		tourCode: 'Tour Code',
		cashorCreditSales: 'Credit Sales',
		cashSales: 'Cash Sales',
		creditCardOrBankTransfer: 'Credit Card / Bank Transfer',
		prepaidAgentSales: 'Prepaid Agent Sales',
		postpaidAgentSales: 'Postpaid Agent Sales',
		bookingDate: 'Booking Date',
		reservationDate: 'Reservation Date',
		daysLeft: 'Days left',
		holdBookings: 'Hold Bookings',
		openingCreditLimit: 'Opening Credit Limit',
		closingCreditLimit: 'Closing Credit Limit',
		openingOutstandingBalance: 'Opening Outstanding Balance',
		selectTransactionDate: 'Select transaction date',
		pnr: 'PNR',
		creditBalance: 'Credit Balance'


	},
	cCurrencyDropdown: {
		"noMatchFound": "No match found",
	},

	systemTicketNow: {
		verSucc: 'Verification Success',
		title: 'Ticket Now',
		selectUser: 'Select User',
		verification: 'Verification',
		issueTicket: 'Issue Ticket',
		reservationId: 'Reservation Id',
		dpTitle: 'Please select agent user to send verification code',
		dpPlaceholder: 'Select agent user',
		cancelBtn: 'Cancel',
		sendVerCode: 'Send Verification code',
		verdesc: '{name} has received an email ({email}) with verification code.',
		verMsg: 'Please enter Verification code below.',
		verLabel: 'Enter Verification Code',
		goBackBtn: 'Go Back',
		verBtn: 'Verify',
		issueBtn: 'Issue Ticket Now',
		cnfrmTck: 'Are you sure to ticket Reservation id :',
		tcktCnfrm: 'Are you sure do you want to ticket this id?',
		succTxt: 'Ticket Successfully',
		cancel: 'Cancel',
		yes: 'Yes',
		ok: 'OK'
	},

	paymentReceived: {
		title: 'Payment Received',
		desc: 'Add new customer profile details here',
		agtName: 'Agent Name',
		mbl: 'Mobile',
		email: 'Email ID',
		busRegNo: 'Business Registration Number',
		add: 'Address',
		currCreditBal: 'Current Credit Balance',
		payRec: 'Payment Received',
		payRecDate: 'Payment Receive Date',
		payRemarks: 'Payment Remarks',
		newCreditBal: 'New Credit Balance',
		cancelBtn: 'Cancel',
		payRecBtn: 'Payment Received',
		addConfirm: 'Are you sure you want to do payment?',
		addSuccess: 'Payment Received successfully',
		amtAlertMsg: 'Please enter a amount less than or equal to ',
		cancel: 'Cancel',
		yes: 'Yes',
		ok: 'OK'
	},

	addProfile: {
		title: 'Profile Type',
		dirCustomer: 'Direct Customer',
		agent: 'Small Agent',
		agtName: 'Small Agent Name',
		address: 'Address',
		mbl: 'Mobile',
		altMbl: 'Alternative Contact No.',
		email: 'Email ID',
		busRegNo: 'Business Registartion No.',
		custName: 'Customer Name',
		custAdd: 'Address',
		custMbl: 'Mobile',
		custAlt: 'Alternative Contact No.',
		custEmail: 'Email ID',
		custId: 'ID Number',
		cancelBtn: 'Cancel',
		saveBtn: 'Save',
		editBtn: 'Edit',
		updateBtn: 'Update',
		updateProfileConfirm: 'Are you sure want to save the changes for this profile?',
		editSuccess: 'Profile edited successfully',
		addConfirm: 'Are you sure you want to add this Profile?',
		addSuccess: 'Profile added successfully',
		cancel: 'Cancel',
		yes: 'Yes',
		ok: 'OK'
	},

	profileList: {
		title: 'Credit Sale Report',
		desc: 'Add new customer profile details here',
		agtName: 'Agent / Customer Name',
		mbl: 'Mobile No.',
		email: 'Email ID',
		idNo: 'Business Registration / ID number',
		creditBal: 'Credit Balance',
		actions: 'Actions',
		paymentReceive: 'Received Payment',
		viewTransaction: 'View Transactions',
		selAgent: 'Select Agent / Customer Name',
		addCust: 'Add Customer / Small Agent',
		editBtn: 'Edit'
	},
	cManageColumns: {
		manageColumns: 'Manage Columns',
		addNewColumn: 'Add new column',
		saveBtn: 'Save',
		cancelBtn: 'Cancel',
		listEmpty: 'No match found',
	},

	bookingDetail: {
		ticketedUser: ' Ticket Issued By',
		reservationId: 'Reservation ID',
		dateOfBooking: 'Date of Booking',
		ticketingDeadline: 'Ticketing Deadline',
		tcktIssueDate: 'Ticket Issue Date',
		bookedBy: 'Booked by',
		amount: 'Amount',
		ref: 'Reference',
		contactDet: 'Contact Details',
		contactName: 'Contact Name',
		emailId: 'Email Id',
		contactNum: 'Contact Number',
		paymentDet: 'Payment Details',
		transactionId: 'Transaction ID',
		bookingAmt: 'Booking Amount',
		paymentType: "Payment Type",
		paymentFee: 'Payment Fee',
		approvalCode: 'Approval Code',
		exchangeFee: 'Exchange Fee',
		paymentHistory: 'Payment History',
		totalAmt: 'Total Amount',
		success: 'Success',
		failed: 'Failed',
		hold: 'Hold',
		exchangeSuccess: 'Exchange Success',
		exchangeReq: 'Exchange Requested',
		exchangeFail: 'Exchange Declined',
		exchangeProcessing: 'Exchange Processing',
		refundSuccess: 'Refund Success',
		refundReq: 'Refund Requested',
		refundFail: 'Refund Declined',
		refundProcessing: 'Refund Processing',
		newRefundReq: 'New Refund Requested',
		newExchangeReq: 'New Exchange Requested',

	},

	hotelSearch: {
		goinPlace: "Where are you going?",
		checkIn: "Check-in",
		checkOut: "Check-out",
		guest: "Guest(s)",
		infants: "(<24 mths)",
		hotel: "Hotel",
	},

	hotelResults: {
		room: "{n} Room | {count} Rooms",
		adult: "{n} Adult | {count} Adults",
		child: "{n} Child | {count} Children",
		infant: "{n} Infant | {count} Infants",
		filter: {
			star: 'Star Rating'
		}
	},



	notificationSetUp: {
		title: 'Email Notification SetUp',
		desc: 'Add email id(s) and customize email notifications to receive alerts when you receive emails.',
		agtMgt: 'Agent Management',
		payment: 'Payment & Topup',
		refundExc: 'Flight Refund & Exchanges',
		flightBook: 'Flight Booking Notifications',
		deadNotify: 'Deadline Notification',
		tcktDeadlineNotify: 'Ticketing Deadline Notification',
		agtReg: 'Agent Registration',
		preTop: 'Prepaid TopUp',
		postPay: 'Postpaid Payment',
		refReq: 'Refund Request',
		excReq: 'Exchange Request',
		booking: 'Booking Notifications',
		enabled: 'Enabled',
		disabled: 'Disabled',
		emailPl: 'Enter email id',
		email: 'Email',
		addBtn: 'Add',
		moreEmail: '+ More Emails',
		lessEmail: '- Less Emails',
		alrtMsg: 'Email id is already added',
		addEmailCnfrm: 'Are you sure you want to add the email id',
		remveEmailCnfrm: 'Are you sure you want to remove the email id',
		yes: 'Yes',
		no: 'No',
		ok: 'OK',
		cancel: 'Cancel',
		statusUpdateCnfrm: 'Are you sure you want to change the status',
		updateSucc: 'Status updated successfully',
		addSucc: 'Email id added successfully',
		remSucc: 'Email id removed successfully',
		goBack: 'Go Back'
	},

	supplierGroup: {
		emailId: 'Email ID',
		title: 'Supplier Groups',
		desc: 'Create and manage your list of suppliers in your database.',
		actions: 'Actions',
		supGrpPlc: 'Supplier Group',
		contactPerPlc: 'Contact Person Name',
		countryPlc: 'Country',
		status: 'Status',
		apply: 'Apply',
		addNewBtn: 'Add New',
		suppGrpName: 'Supplier Group Name',
		country: 'Country',
		address: 'City',
		contPersonName: 'Contact Person Name',
		contNumber: 'Contact Number',
		action: 'Action',
		view: 'View',
		edit: 'Edit',
		delete: 'Delete',
		suppGrps: 'Supplier Groups',
		active: 'Active',
		inactive: 'Inactive',
		deleteConfirm: 'Are you sure you want to delete supplier group?',
		deleteSuccess: 'Supplier group deleted successfully',
		yes: 'Yes',
		no: 'No',
		ok: 'OK',
		listEmpty: 'No Supplier Group found for your given Fliter'
	},

	newSupplierGroup: {
		apply: 'Apply',
		title: 'Create Supplier Group',
		updateTitle: 'Update Supplier Group',
		create: 'Create',
		update: 'Update',
		desc: 'Add and manage details of a new supplier in your database.',
		city: 'City',
		contactPer: 'Contact Person Name',
		country: 'Country',
		email: 'Email ID',
		suppGrpName: 'Supplier Group Name',
		contNumber: 'Contact Number',
		commSetup: 'Commission Setup',
		edit: 'Edit',
		save: 'Save',
		cancel: 'Cancel',
		select: 'Select',
		contNumPlc: 'Enter Contact Number',
		namePlc: 'Enter Contact Person Number',
		emailPlc: 'Enter Email',
		grpNamePlc: 'Enter Supplier Group Name',
		addConfirm: 'Are you sure  want to create supplier group?',
		addSuccess: 'Supplier group created successfully',
		editConfirm: 'Are you sure  want to edit supplier group?',
		editSuccess: 'Supplier group edited successfully',
		active: 'Active',
		inactive: 'Inactive',
		yes: 'Yes',
		no: 'No',
		ok: 'OK'
	},

	allHotels: {
		title: 'Hotel List',
		desc: 'Create and manage the list of hotels available in your database.',
		hotelDp: 'Select Hotel',
		cntryDp: 'Select Country',
		cityDp: 'Select City',
		statusDp: 'Select Status',
		applyBtn: 'Apply',
		hotelName: 'Hotel Name',
		hotelCode: 'Hotel Code',
		starRating: 'Star Rating',
		country: 'Country',
		city: 'City',
		conNum: 'Contact Number',
		supplier: 'Supplier',
		status: 'Status',
		action: 'Action',
		addNew: 'Add New',
		view: 'View',
		edit: 'Edit',
		delete: 'Delete',
		active: 'Active',
		inactive: 'Inactive',
		deleteConfirm: 'Are you sure you want to delete supplier group?',
		deleteSuccess: 'Supplier group deleted successfully',
		yes: 'Yes',
		no: 'No',
		ok: 'OK',
		listEmpty: 'No Hotels found for your given Fliter'
	},
	createHotel: {
		errDesc: 'could not active this hotel.Please fill all mandatory fields',
		contNumTooltip: 'Separate two contact numbers by a , (comma). Enter a contact number without space.<br />Eg. +12130006767,+12131112424',
		starRatingTooltip: 'Select the star rating based on list of services provided.. on the scale of 1-10. 1 being the lowest (poor)  and 10  being the highest (Great!).',
		title: 'Create Hotel',
		desc: 'Add and manage details of a new hotel in your database.',
		hotelInfo: 'Hotel Info',
		adtAgeSetup: 'Adult Age',
		address: 'Address',
		contactDetails: 'Contact Details',
		bankDetails: 'Bank Details',
		reservation: 'Reservation',
		sales: 'Sales',
		supplier: 'Supplier',
		review: 'Review',
		hotelName: 'Hotel Name',
		hotelNamePl: 'Enter hotel name',
		hotelOldName: 'Hotel Old Name',
		hotelOldNamePl: 'Enter hotel old name',
		select: 'Select',
		Description: 'Description',
		ifAny: 'If Any',
		starRating: 'Star Rating',
		website: 'Website',
		webUrlPl: 'Enter web url',
		description: 'Description',
		descriptionPl: 'To be displayed on hotel searches',
		back: 'BACK',
		next: 'NEXT',
		create: 'CREATE',
		update: 'UPDATE',
		listEmpty: 'No match found',
		country: 'Country',
		countryPl: 'Select country',
		city: 'City',
		cityPl: 'Select city',
		postalCode: 'Postal Code',
		postalCodePl: 'Select postal code',
		nearestCities: 'Nearest Cities',
		nearesrCitiesPl: 'Select nearest cities',
		nearestAirport: 'Nearest Airport',
		nearestAirportPl: 'Select nearest airport',
		contactNumber: 'Contact Number',
		emailId: 'Email ID',
		accountHolderName: 'Account Holder Name',
		accountHolderNamePl: 'Enter Account Holder Name',
		accountNumber: 'Account Number',
		accountNumberPl: 'Enter Account Number',
		bankName: 'Bank Name',
		bankNamePl: 'Enter Bank Name',
		branch: 'Branch',
		branchPl: 'Enter Branch',
		contactPersonName: 'Contact Person Name',
		contactPersonNamePl: 'Enter contact person name',
		reservationDetails: 'Reservation Details',
		salesPersonName: 'Sales Person Name',
		salesPersonNamePl: 'Enter Sales Person Name',
		designation: 'Designation',
		designationPl: 'Select Designation',
		salesDetails: 'Sales Details',
		supplierGroup: 'Supplier Group',
		supplierGroupPl: 'Select supplier',
		ifscCode: 'IFSC Code',
		swiftCode: 'Swift Code',
		addressDetails: 'Address Details',
		"createHotelConfirmation": "Are you sure want to create hotel?",
		"updateHotelConfirmation": "Are you sure want to update hotel?",
		"hotelCreatedSuccDesc": "Hotel created successfully",
		"hotelUpdatedSuccDesc": "Hotel updated successfully",
		"statusUpdateSuccDesc": "Status updated successfully",
		noBtn: 'No',
		yesBtn: 'Yes',
		okBtn: 'OK',
		"deleteHotelConfirmation": "Are you sure want to delete hotel?",
		"deleteHotelSuccDesc": "Hotel has been successfully deleted",
		"inactive": "Inactive",
		"active": "Active",
		updateHotelInfo: 'Update Hotel Info',
		edit: 'Edit',
		noInitialSpace: 'This field does not allow space at initial',
		maxChar: 'At max. 1000 characters.'


	},
	hotelInfo: {
		datePickerToolTip: 'Both from date and to date are inclusive',
		availability: 'Availability',
		infAgeTooltip: "Guests within infant age range will be assigned as 'Infant'. If the entered age is not within infant's age range, they will be assigned under Child or Adults, based on the age.",
		childAgeTooltip: "Guests within Child age range will be assigned as 'Child'. If the entered age is not within child's age range, they will be assigned under either under Infants or Adults, based on the age.",
		adtAgeTooltip: "Guests within Adult age range will be assigned as 'Adults'. If the entered age is not within adult's age range, they will be assigned under Child or Infant, based on the age.",
		extraBedTooltip: 'Extra bed will be provided upon request, only if the guest age is within entered age of extra bed age.',

		info: 'Hotel Info',
		settings: 'Settings',
		roomSett: 'Room Settings',
		amenities: 'Amenities',
		loc: 'Locations',
		gallery: 'Gallery',
		facts: 'Helpful Facts',
		guestAge: 'Guest Age Setup',
		infAge: 'Infant Age',
		childAge: 'Child Age',
		adtAge: 'Adult Age',
		extraBed: 'Extra Bed',
		yearsTo: 'years to',
		yearsOld: 'years old',
		childDesc: ' Allow children stay free on  existing beds',
		toolTip: 'Includes start date and excludes the day of end year',
		stay: 'Stay',
		leave: 'Leave',
		desc: 'Are you sure you want to close ? You may  have unsaved changes.',
		warn: 'Warning',
		description: 'Description',
		contactDetails: 'Contact Details',
		bankDetails: 'Bank Details',
		reservation: 'Reservation',
		sales: 'Sales',
		phone: 'Phone',
		mobile: 'Mobile',
		emailId: 'Email ID',
		edit: 'Edit',
		cancel: 'Cancel',
		save: 'Save',
		"inactive": "Inactive",
		"active": "Active",
		accountHolderName: 'Account Holder Name',
		accountNumber: 'Account Number',
		bankName: 'Bank Name',
		branch: 'Branch',
		contactName: 'Contact Name',
		uploadTariff: 'Upload Tariff',
		ratePlan: 'Rate Plan',
		cancellationPolicies: 'Cancellation Policies',
		surcharge: 'Surcharge',
		cHotelSelection: {
			hotelSelectPl: 'Select hotel'
		},

		taxSettings: 'Tax Settings',
		error: 'Error',
		dialogTxt: 'Are you sure you want to leave this tab? You have unsaved changes.'

	},

	helpfulFacts: {
		title: 'Helpful Facts',
		checkIn: 'Check-In',
		checkOut: 'Check-Out',
		noOfBars: 'Number of Bars',
		noOfBarsplc: 'Enter number of Bars',
		noOfFloors: 'Number of Floors',
		noOfFloorsplc: 'Enter number of Floors',
		noOfRest: 'Number of Restaurants',
		noOfRestplc: 'Enter number of Restaurants',
		nearest: 'Nearest Essentials',
		airports: 'Airports',
		airportPlc: 'Enter airport name',
		select: 'Select',
		travel: 'Travel Type',
		distance: 'Distance',
		duration: 'Duration',
		pubBus: 'Public Bus',
		pubTrain: 'Public Train',
		stopName: 'Enter stop name',
		bankName: 'Enter Bank or ATM name',
		stationName: 'Enter station name',
		hospital: 'Hospital or Clinic',
		hospPlc: 'Enter hospital or clinic name',
		mall: 'Shopping Mall',
		mallPlc: 'Enter shopping mall name',
		bank: 'Bank or ATM'
	},

	locations: {
		title: 'Hotel Locations',
		latitude: 'Latitude',
		longitude: 'Longitude',
		saveBtn: 'Save'
	},

	hotelImage: {
		hotelmain: 'Hotel Main Image',
		imgGallery: 'Image Gallery',
		restaurantImg: 'Restaurant Images'
	},



	cHotelAlertSetup: {
		title: 'Currency and Notification',
		emailAlert: 'Email Booking Alert',
		email: 'Email Address',
		emailPh: 'Enter a email address',
		empty: 'No e-mail ids were added',
		yes: 'Yes',
		no: "No",
		alertDesc: 'Are you sure want to Delete ?'
	},
	cAvailability: {
		avail: 'Availability',
		rates: 'Rates in',
		days: 'Days'
	},

	cHotelLang: {
		title: 'Languages',
		searchPl: 'Search languages',
		moreLang: 'More Language',
		selectAll: 'Select All',
		showMore: 'Show More',
		showLess: 'Show Less',
		noResult: 'No Languages found'

	},
	cWeekDays: {
		SUN: 'SUN',
		MON: 'MON',
		TUE: 'TUE',
		WED: 'WED',
		THU: "THU",
		FRI: 'FRI',
		SAT: 'SAT',


	},



	cHotelRoomDet: {
		active: 'Active',
		inactive: 'Inactive',
		statusDesc: 'Are you sure you want to update the status?',
		roomImage: 'Room Image',
		titleHeader: 'Create Hotel Room',
		updateTitle: 'Update Hotel Room',
		titleDesc: 'Contract of Carriage.Transportation of Passengers and Baggage on flights operated by United Airlines, Inc...',
		descTxt: 'Add and manage room(s) details of the hotel for booking.',
		sizeTooltip: 'Please enter the size of the room. Only numbers are allowed.<br />Eg. If the room size is 10*10 sq.ft, please enter 100.',
		maxOccTooltip: 'Maximum number of guests that can stay in a room. This includes both adults and child guests.<br />Please note : While creating Rate Plan, you can override this settings.',
		maxAdtTooltip: 'Maximum number of adult guests allowed in the room. System adds this count as the maximum limit.',
		maxChdTooltip: 'Maximum number of child guests allowed in the room. System adds this count as the maximum limit.',
		maxExtraBedTooltip: 'Maximum number of "Extra beds" that can be provided  for a room, on request. Systems regards this count as the maximum limit.',
		add: 'Add',
		ratePlan: 'Rate Plan',
		title: 'Room Details',
		typeId: 'Room Type Id',
		roomName: 'Room Name',
		namePh: 'Enter Room Name',
		typePh: 'Select Room Type',
		roomType: "Room Type",
		view: 'View',
		viewPh: 'Select View',
		maxOcc: 'Maximum Occupancy',
		maxOccPh: 'Enter Max No.of Guest',
		maxAdt: 'Maximum Adult',
		maxAdtPh: 'Enter Max No.of Adult',
		maxChild: 'Maximum child',
		maxChildPh: 'Enter Max No.of Child',
		maxExtraBedPH: 'Enter Max No. of Extra Bed',
		maxExtraBed: 'Maximum Extra Bed',
		lang: "Language",
		langPh: 'Select Language',
		rooms: 'No. of Rooms',
		totRooms: 'Total No.of Rooms',
		roomSize: 'Room Size',
		roomSizePh: 'Enter room size in sq.ft',
		desc: 'Description',
		action: 'Action',
		roomTypeId: 'Room Id',
		noOfRooms: 'No of Rooms',
		edit: 'Edit',
		delete: 'Delete',
		status: 'Status',
		bedTypes: 'Bed Types',
		roomTypes: 'Room Type',
		cancel: 'Cancel',
		createRoom: 'Create Room',
		updateRoom: 'Update Room',
		deleteDesc: 'Are you sure want to delete Hotel Room ?',
		yes: 'Yes',
		no: 'No',
		succDesc: ' The hotel Room was deleted successfully ',
		ok: 'Ok',
		roomInfo: 'Room Info',
		guestDetails: 'Guest Details',
		showRP: 'Show Rate Plan',
		hideRP: "Hide Rate Plan",
		noRooms: 'No rooms added'


	},
	cRoomAmenities: {
		title: 'Amenities',
		searchPl: 'Search  Amenities',
		moreAmenities: 'More Amenities',
		selectAll: 'Select All'
	},

	cBedTypes: {
		title: 'Bed Types',
		searchPl: 'Search bed type',
		moreAmenities: 'More bed types',
		selectAll: 'Select All',
		showMore: 'Show More',
		showLess: 'Show Less'

	},
	cHotelImages: {
		yes: 'Yes',
		no: 'No',
		ok: 'OK',
		addImgType: 'Add Hotel Image Type',
		updateSuccess: 'Updated Successfully',
		deleteSuccess: 'Deleted Successfully',
		successfully: 'Successfully'
	},
	cHotelAmenities: {
		title: 'Hotel Amenities',
		searchPl: 'Search hotel Amenities',
		moreAmenities: 'More Amenities',
		selectAll: 'Select All',
		showMore: 'Show More',
		noResult: 'No Amenities found',
		showLess: 'Show Less'
	},

	cSportsEntern: {

		title: 'Sports and Entertainment',
		searchPl: 'Search sports and Enternainment',
		moreSports: 'More Sports and Entertainment',
		selectAll: 'Select All',
		showMore: 'Show More',
		showLess: 'Show Less',
		noResult: 'No Sports and Entertainment found'

	},

	hotelMapping: {
		title: 'Hotel Mapping',
		selectCHManager: 'Select Channel Manager',
		confirm: 'Are you sure want to delete?',
		yes: 'Yes',
		no: 'No'
	},

	tariffSetup: {
		cutOffTooltip: 'Priority date to permit booking prior check-in. Booking prior this date is not allowed.<br />Eg. If the cut off day is 0, tariff will applicable to book any time of  booking date.<br />Eg. If the cut off days is 2, tariff will applicable to book before 2 days of the check in time of booking date .',
		title: 'Tariff Setup',
		desc: 'Setup and manage the excise tariff rate for all forms of occupancy. ',
		stayDate: 'Stay Date',
		stayFrom: 'Stay From',
		stayTo: 'Stay To',
		fares: 'Fares',
		singleOccupancyFare: 'Single Occupancy Fare',
		doubleOccupancyFare: 'Double Occupancy Fare',
		fullOccupancyFare: 'Full Occupancy Fare',
		amountPl: 'Enter Amount',
		applicableDays: 'Applicable Days',
		cutOffDays: 'Cut Off Days',
		cutOffDatePl: 'Enter No. Days',
		applicableDaysOfWork: 'Applicable Days of Week',
		numberOfDaysToStay: 'Number of days to stay',
		minStayPl: "Enter minimum days",
		maxStayPl: 'Enter maximum days',
		create: 'Create',
		update: 'Update',
		cancel: 'Cancel',
		"createTariffConfirmation": "Are you sure want to create tariff?",
		"updateTariffConfirmation": "Are you sure want to update tariff?",
		"tariffCreatedSuccDesc": "Tariff created successfully",
		"tariffUpdatedSuccDesc": "Tariff updated successfully",
		noBtn: 'No',
		yesBtn: 'Yes',
		okBtn: 'OK',

	},
	cRoomInfoBox: {
		ratePlan: 'Rate plan',
		ratePlanPl: 'Select rate plan',
		listEmpty: 'No match found',

	},
	tariffList: {
		title: 'Tariff List',
		desc: 'Create and manage the list of tariffs set.',
		stayDate: 'Stay Date',
		stayFrom: 'Stay From',
		stayTo: 'Stay To',
		singleOccupancyFare: 'Single Occupancy Fare',
		doubleOccupancyFare: 'Double Occupancy Fare',
		fullOccupancyFare: 'Full Occupancy Fare',
		applicableDays: 'Applicable Days',
		cutOffDays: 'Cut Off Days',
		back: 'Back',
		"deleteTariffConfirmation": "Are you sure want to delete tariff?",
		"deleteTariffSuccDesc": "Tariff has been successfully deleted",
		noBtn: 'No',
		yesBtn: 'Yes',
		okBtn: 'OK',
		add: 'Add Tariff',
		delete: 'Delete',
		edit: 'Edit',
		tariffEmpty: 'No tariffs added',
		allDays: 'ALL Days'

	},

	hotelUser: {
		suppliers: 'Supplier',
		action: 'Actions',
		title: 'Users',
		desc: 'Create and manage the profile of users who access the database.',
		userName: 'User Name',
		usersPl: 'Select User',
		role: 'Role',
		emailId: 'Email ID',
		status: 'Status',
		active: 'Active',
		inactive: 'Inactive',
		addUser: 'Add User',
		back: 'Back',
		"deleteUserConfirmation": "Are you sure want to delete user?",
		"deleteUserSuccDesc": "User has been successfully deleted",
		noBtn: 'No',
		yesBtn: 'Yes',
		okBtn: 'OK',
		delete: 'Delete',
		edit: 'Edit',
		listEmpty: 'There are no users',
		listEmptyByFilter: 'No Users Found for your given filter'
	},
	hotelUserNew: {
		"inactive": "Inactive",
		"active": "Active",
		title: 'Create User',
		updateUser: 'Manage User',
		updateDesc: 'Manage Users lists details of the users of the account along with options to reset password, manage their status in handling the account.',
		desc: "Add a new user and control user's access to establish access to the database.",
		userInfo: 'User Info',
		firstName: 'First Name',
		firstNamePl: 'Enter First Name',
		lastName: 'Last Name',
		lastNamePl: 'Enter Last Name',
		emailId: 'Email ID',
		emailIdPl: 'Enter Email ID',
		password: 'Password',
		passwordPl: 'Enter Password',
		userRole: 'User Role',
		userRolePl: 'Select',
		accessibleTo: 'Accessible To',
		suppliers: 'Suppliers',
		suppliersPl: 'Select Suppliers',
		hotel: 'Hotel',
		hotelPl: 'Select Hotels',
		create: 'Create',
		update: 'Update',
		back: 'Cancel',
		"createUserConfirmation": "Are you sure want to create user?",
		"updateUserConfirmation": "Are you sure want to update user?",
		"userCreatedSuccDesc": "User created successfully",
		"userUpdatedSuccDesc": "User updated successfully",
		noBtn: 'No',
		yesBtn: 'Yes',
		okBtn: 'OK',
		listEmpty: 'No match found',

	},
	markUp: {
		action: 'Actions',
		status: 'Status',
		active: 'Active',
		inactive: 'Inactive',
		all: 'All',
		title: 'Markup',
		desc: 'Setup and manage markup for every supplier.',
		addNew: 'Add New',
		supplier: 'Supplier Name',
		room: 'Room',
		stayDate: 'Stay Date',
		value: 'Value',
		applicableDay: 'Applicable Days',
		suppPlc: 'Select Supplier',
		hotel: 'Hotel',
		hotelPlc: 'Select Hotel',
		ratePlan: 'Rate Plan',
		ratePlanPlc: 'Select Rate Plan',
		deleteConfirm: "Are you sure want to delete Markup Setup?",
		deleteSuccess: "Markup Setup deleted successfully",
		markupEmpty: 'There are no markups for applied filter',
		noMarkup: 'No Markup found'
	},
	markUpNew: {
		noHotels: ' Please select hotels',
		title: 'Markup Setup',
		updateTitle: 'Update Markup Setup',
		desc: 'Create and manage Markup / commission for a hotel.',
		markup: 'Markup Setup',
		supplier: 'Suppliers',
		suppPlc: 'Select Supplier',
		hotel: 'Hotel',
		hotelPlc: 'All Hotels',
		room: 'Room',
		roomPlc: 'All Rooms',
		from: 'From',
		to: 'To',
		stayDate: 'Stay Date',
		applicable: 'Applicable Days & Value',
		fixed: 'Fixed',
		percentage: 'Percentage',
		value: 'Value',
		currency: 'Currency',
		enterAmt: 'Enter the amount',
		daysOfWeek: 'Applicable Days of Week',
		cancel: 'Cancel',
		add: 'Add',
		update: 'Update',
		addConfirm: "Are you sure want to create Markup Setup?",
		updateConfirm: "Are you sure want to update Markup Setup?",
		addSuccess: "Markup Setup created successfully",
		updateSuccess: "Markup Setup updated successfully",
		noBtn: 'No',
		yesBtn: 'Yes',
		okBtn: 'OK',
		listEmpty: 'No match found',
		noSupplier: 'Please select supplier group.',
		active: 'Active',
		inactive: 'Inactive',
		statusDesc: 'Are you sure you want to update the status?'
	},

	noMatchFound: {
		noMatchFound: 'No Match Found'
	},

	bookingManifest: {
		title: 'Booking Manifest',
		desc: 'Contract of Carriage. Transportation of Passengers and Baggage on flights operated by United Airlines Inc...',
		departure: 'Departure City',
		arrival: 'Arrival City',
		flight: "Flight",
		selFlight: 'Select Flight',
		depDate: 'Departure Date',
		fromDateLabel: 'From',
		toDateLabel: 'To',
		schedule: 'Flight Schedule',
		pnr: 'PNR',
		ticketNumber: 'Ticket Number',
		BookingDate: 'Booking Date',
		contract: 'Contract',
		selContract: 'Select Contract',
		submit: 'Submit',
		clearAll: 'Clear All',
		moreFilters: 'More Filters',
		bookingDateTime: 'Booking Date & Time',
		colPnr: 'PNR',
		colSector: 'Sector',
		flightNum: 'Flight Number',
		cabinClass: 'Cabin Class',
		bookingclass: 'Booking Class',
		depDatetime: 'Departure Date & Time',
		paxName: 'Pax Name',
		gender: 'Gender',
		paxType: 'Pax Type',
		dob: "Date of Birth",
		docType: 'Doc Type',
		docID: 'Doc ID',
		expryDate: 'Doc ID Expiry Date',
		contactNo: 'Contact No.',
		mail: 'Mail ID',
		ticketNum: 'Ticket Number',
		noList: 'No Bookings found',
		noListFilter: 'No Bookings found for your filter'
	},

	bookingsReport: {
		title: 'Bookings',
		desc: 'Check, manage and stay updated on the status and details of bookings done.',
		depCity: 'Departure City',
		arrCity: 'Arrival  City',
		selFlight: 'Select Flight',
		flight: 'Flight',
		depDate: 'Departure Date',
		fromDateLabel: 'From',
		toDateLabel: 'To',
		flightSch: 'Flight Schedule',
		bookingStatus: 'Booking Status',
		pnr: 'PNR',
		ticketNumber: 'Ticket Number',
		bookingDate: 'Booking Date',
		contract: 'Contract',
		selContract: 'Select Contract',
		submit: 'Submit',
		clearAll: 'Clear All',
		moreFilters: 'More Filters',
		bookingDateTime: 'Booking Date & Time',
		colPnr: 'PNR',
		colSector: 'Sector',
		flightNum: 'Flight Number',
		cabinClass: 'Cabin Class',
		bookingclass: 'Booking Class',
		depDatetime: 'Departure Date & Time',
		leadPax: 'Lead Passengers Name',
		noOfPax: 'No. of Pax',
		contactNo: 'Contact No.',
		ticketNum: 'Ticket Number',
		bookedBy: 'Booked by',
		currency: 'Currency',
		amt: 'Amount',
		status: 'Booking Status',
		noList: 'No Bookings found',
		noListFilter: 'No Bookings found for your filter'
	},
	loadFactorReport: {
		title: 'Load Factorized',
		desc: 'Contract of Carriage. Transportation of Passengers and Baggage on flights operated by United Airlines Inc...',
		depCity: 'Departure City',
		arrCity: 'Arrival  City',
		selFlight: 'Select Flight',
		fromDateLabel: 'From',
		toDateLabel: 'To',
		flightSch: 'Flight Schedule',
		depDate: 'Departure Date',
		contract: 'Contract',
		selContract: 'Select Contract',
		submit: 'Submit',
		initTxt: 'Apply filters to view your load factorized',
		noList: 'No bookings found to view load factorized for your given filter'
	},

	diy_all_post: {
        totalUsers: 'Total Users',
        active: 'Active',
        inactive: 'Inactive',
        addPost: "Add post",
        edit: "Edit",
        listEmpty: "No Users Found",
        desc: 'List of diy app posts',
        manage: "Manage",
        postImage: "Post Image",
        emailId: "Email ID",
        category:"Category",
        tag: "Tag",
        title:"Title",
        author: "Author",
        actions: "Actions",
        status: 'Status',
        publishDate: "Publish Date",
        agencyName: 'Agency Name',
        pageTitle: "List Of Post",
        clearFilter: 'Clear All Filter',
        admindesc: 'List of diy app posts'
    },
	addDiyPost: {
        title: "Title",
		group: "Group",
        category: "Category",
        description: "Description",
        requirement: "Requirement",
        readMore: "Read More",
        videoLink: "Video Link",
        imageLink: "Image Link",
        tag: "Tag",
        pwd: "Password",
        role: "Role",
        addPost: "Add Post",
        addTitle: "Create Post",
        addDesc: "This page allows to create a Post with organized profile details  provided with the option to set up roles to the Post.",
        update: "Update",
        updateTitle: "Edit Post",
        updateDesc: "Edit Post page is here to help you in editing details like Post's name, email ID or role.",
        cancel: "Cancel",
        invalid: "Invalid Post Details",
        addFailed: "Unable to add Post",
        ok: "OK",
        addSuccess: "{fname} {lname} was added successfully",
        updateSuccess: "{fname} {lname} was updated successfully",
        agent: 'Agent',
        system: 'System',
        yes: "Yes",
        no: "No",
        confirmUpdate: 'Are you sure want to save the changes for {fname} {lname}?'
    },
}