<template>
  <CDropdown
    v-if="!isMobile"
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
    :show.sync="isShowLoginInfo"
  >
    <template #toggler>
      <CHeaderNavLink>
        <!-- <div class="c-avatar round"> -->
        <avatar
          class="c-avatar-img square"
          :username="getUserName"
          :size="40"
          color="#fff"
        ></avatar>
      </CHeaderNavLink>
    </template>
    <div>
      <cUserInfo v-if="isShowLoginInfo"></cUserInfo>
      <CAlert v-if="alertValue" show color="danger">
        <div v-if="alertValue && alertValue.hasOwnProperty('valueList')">
          <p v-for="(value, index) in alertValue.valueList" :key="index">
            {{ value }}
          </p>
        </div>
        <div v-if="alertValue && alertValue.hasOwnProperty('codeList')">
          <span>{{ $t("cAlert.errorCode") + " : " }}</span
          ><span v-for="(code, index) in alertValue.codeList" :key="index">
            {{ code + " " }}
          </span>
        </div>
        <div v-if="alertValue && alertValue.constructor == String">
          <p>
            {{ alertValue }}
          </p>
        </div>
      </CAlert>
    </div>
  </CDropdown>
  <div v-else-if="isMobile" @click="navigateIfMbl()">
    <avatar
      v-if="currentRouteName != 'aProfile'"
      class="c-avatar-img square"
      :username="getUserName"
      :size="40"
      color="#fff"
    ></avatar>
  </div>
</template>
<script>
import cUserInfo from "./../components/userinfo/cUserInfo";
import { mapActions, mapGetters } from "vuex";

// import apiUserLogoDet from "./../services/apiUserLogoDet";
import Logger from "./../services/logger";
import SERVICE_URL, { FILE_NAME, LOG_TYPE } from "./../config/API_DATA";

import apiLogOut from "./../services/apiLogOut";

import { navigate } from "./../router/index";
import store from "../store";

import BaseConfiguration from "./../config/base";
//import Avatar from "vue-avatar-component";
import Avatar from "vue-avatar";
import { clearStore } from "./../store/clearStore";
import { removeB2BMFfromSession } from "./../applocalstorage/localStorage";
import { PATHNAME } from "../util/constants";
export default {
  name: "TheHeaderDropdownAccnt",
  components: {
    cUserInfo,
    //avatar
    Avatar,
  },
  data() {
    return {
      isMobile: BaseConfiguration.isMobile,
      language: [
        {
          langcode: null,
          en_name: null,
          native_name: null,
        },
      ],
      languageListURL: SERVICE_URL.LANGUAGE_LIST,
      itemsCount: 42,
      alertValue: null,
      isShowLoginInfo: false,
      userLogoDet: {
        logoDet: null,
      },
    };
  },
  methods: {
    ...mapActions("sSysLogin", {
      CLEAR_SYS_LOGIN: "CLEAR_SYS_LOGIN",
    }),

    navigateIfMbl() {
      if (BaseConfiguration.isMobile) {
        navigate(PATHNAME.APROFILE);
      }
    },

    viewBalance(val) {
      alert(JSON.stringify(val));
    },

    showAlert(val) {
      this.alertValue = val;
    },

    toggleShowLoginInfo() {
      try {
        if (BaseConfiguration.isDebug) console.log("toggleShowLoginInfo");
        //let retValue;
        this.isShowLoginInfo = !this.isShowLoginInfo;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.THE_HEADER_DROPDOWN_ACCNT,
          "error in toggleShowLoginInfo",
          err.toString(),
          "toggleShowLoginInfo",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      //return retValue;
    },
    onLogout() {
      try {
        removeB2BMFfromSession();

        clearStore();
        apiLogOut.getLogout();
        if (store.getters["sSysLogin/GET_ADMIN_SIGNIN_RES"]) {
          this.CLEAR_AGT_LOGIN();
          this.CLEAR_SYS_LOGIN();
          navigate(PATHNAME.SYSTEM_LOGIN);
        } else {
          this.CLEAR_AGT_LOGIN();
          this.CLEAR_SYS_LOGIN();
          navigate(PATHNAME.SYSTEM_LOGIN);
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.THE_HEADER_DROPDOWN_ACCNT,
          "error in onLogout",
          err.toString(),
          "onLogout",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
  },

  async created() {
    let logoDetail = null;
    if (this.userLogoDetail) logoDetail = this.userLogoDetail;
    if (logoDetail) this.userLogoDet.logoDet = logoDetail;
    if (BaseConfiguration.isDebug) console.log("created");
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    getUserName: function () {
      let retValue;
      try {
        if (
          this.userLogoDet.logoDet &&
          this.userLogoDet.logoDet.userName &&
          this.userLogoDet.logoDet.lastName
        ) {
          retValue =
            this.userLogoDet.logoDet.userName +
            " " +
            this.userLogoDet.logoDet.lastName;
        } else if (
          this.userLogoDet.logoDet &&
          this.userLogoDet.logoDet.lastName
        ) {
          retValue = this.userLogoDet.logoDet.userName;
        } else {
          retValue = "";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.THE_HEADER_DROPDOWN_ACCNT,
          "error in getting user name",
          err.toString(),
          "getUserName()",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    ...mapGetters("sSysLogin", {
      GET_ADMIN_SIGNIN_REQ: "GET_ADMIN_SIGNIN_REQ",
      GET_ADMIN_SIGNIN_RES: "GET_ADMIN_SIGNIN_RES",
    }),
    ...mapGetters("sDashBoard", {
      userLogoDetail: "GET_AGENT_LOGO_DET_RES",
    }),
  },
};
</script>

<style scoped>
.round {
  border-radius: 50%;
}

.c-icon {
  margin-right: 0.3rem;
}
</style>
