import {
  LOG_TYPE
} from "../../config/API_DATA";
import BaseConfiguration from "../../config/base";
import Logger from "../../services/logger.js";
export default class AppPageControl {
  static appSideBarMenu(pMenuLs) {
    let returnValue;
    try {
      if (pMenuLs) {
        pMenuLs = pMenuLs.filter(function (objFromA) {
          return !BaseConfiguration.not_allowedPages_mbl.find(function (objFromB) {
            return objFromA.routing === objFromB.route
          })
        })
        for (let i = 0; i < pMenuLs.length; i++) {
          if (pMenuLs[i].child && pMenuLs[i].child.length > 0) {
            pMenuLs[i].child = pMenuLs[i].child.filter(function (objFromA) {
              return !BaseConfiguration.not_allowedPages_mbl.find(function (objFromB) {
                return objFromA.routing === objFromB.route
              })
            })
          }
        }
      }
      returnValue = pMenuLs;
    } catch (err) {
      let body = Logger.buildRequest(
        "appSideBarMenu",
        "error in appSideBarMenu",
        err.toString(),
        "AppPageControl",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
    return returnValue;
  }

  static appRouteControl(to) {
    let isRestrictedRoute = false;
    try {
      if (BaseConfiguration.not_allowedPages_mbl) {
        for (let i = 0; i < BaseConfiguration.not_allowedPages_mbl.length; i++) {
          if (to.path == BaseConfiguration.not_allowedPages_mbl[i].route) {
            isRestrictedRoute = false;
            break;
          } else {
            isRestrictedRoute = true;
          }
        }
      } else {
        isRestrictedRoute = true;
      } // returnValue = isRestrictedRoute;
    } catch (err) {
      let body = Logger.buildRequest(
        "appRouteControl",
        "error in appRouteControl",
        err.toString(),
        "AppPageControl",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
    return isRestrictedRoute;
  }
}