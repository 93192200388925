<template>
  <div>
    
    <div class="body" v-if="userLogoDet && userLogoDet.b2bCompanyFinancess">
      <div
        v-for="(finance, index) in userLogoDet.b2bCompanyFinancess"
        :key="index"
        class="content"
      >
        <div class="icon" v-if="finance.symbol">{{ finance.symbol }}</div>

        <div class="value">
          <div
            class="title"
            v-if="
              finance.agenttype == agentType.prepaid && finance.prepaidbalance
            "
          >
            <div
              v-c-tooltip.hover="{
                content: $t('cLoginInfo.availBalance'),
                active: false,
              }"
            >
              <div>
                {{ finance.prepaidbalance | formatNumber(finance.currecycode) }}
              </div>
            </div>
          </div>
          <div
            class="title"
            v-if="
              finance.agenttype == agentType.postpaid &&
              finance.postpaid_runningamount
            "
          >
            <div
              v-c-tooltip.hover="{
                content: $t('cLoginInfo.availCreditLimit'),
                active: false,
              }"
            >
              <div>
                <span v-if="finance.currecycode"
                  >{{ finance.currecycode }}
                </span>
                <span
                  v-if="
                    getPostPaidAmt(
                      finance.postpaidlimit,
                      finance.postpaid_runningamount
                    ) != 0
                  "
                  >{{
                    getPostPaidAmt(
                      finance.postpaidlimit,
                      finance.postpaid_runningamount
                    ) | formatNumber(finance.currecycode)
                  }}</span
                >
                <span v-else>0</span>
              </div>
            </div>
          </div>
          <div class="price text-ellipsis" v-if="finance.pendingbookings">
            <span>{{
              finance.pendingbookings == "0" || finance.pendingbookings == 0
                ? ""
                : finance.pendingbookings
            }}</span>
            {{ formatPendingText(finance.pendingbookings) }}
          </div>
          <div class="progress-b" v-if="finance.agenttype == agentType.prepaid">
            <div class="progress" style="height: 0.4rem" variant="success">
              <div
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                :aria-valuenow="getProgressPercent(finance, true)"
                class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                :style="'width:' + getProgressPercent(finance, true) + '%;'"
              ></div>
            </div>
          </div>
          <div
            class="progress-b"
            v-if="finance.agenttype == agentType.postpaid"
          >
            <div class="progress" style="height: 0.4rem" variant="success">
              <div
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                :aria-valuenow="getProgressPercent(finance, false)"
                class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                :style="'width:' + getProgressPercent(finance, false) + '%;'"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="body" v-else>
      <div
        v-for="finance in emptyLoaderContentLen"
        :key="finance"
        class="content"
      >
        <div class="icon"></div>
        <div class="value">
          <div class="title">
            <div>
              <div>{{ "" }}</div>
            </div>
          </div>
          <div class="title">
            <div>
              <div>
                <span>{{ "" }}</span>
                <span></span>
              </div>
            </div>
          </div>
          <div class="price text-ellipsis">
            <span></span>
          </div>
          <div class="progress-b">
            <div class="progress" style="height: 0.4rem" variant="success">
              <div
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                :aria-valuenow="0"
                class="progress-bar bg-success progress-bar-striped progress-bar-animated"
                :style="'width:' + 0 + '%;'"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { parseNumber } from "./../util/util";
import { COMPONENT_NAME } from "../util/constants";
import Logger from "./../services/logger";
import { FILE_NAME, LOG_TYPE } from "./../config/API_DATA";

import BaseConfiguration from "./../config/base";

export default {
  name: COMPONENT_NAME.C_LOGIN_INFO,


  data() {
    return {
      userLogoDet: {
        b2bCompanyFinancess: null,
      },
      emptyLoaderContentLen: 1,
    };
  },

  methods: {
    ...mapActions("sSysLogin", {
      CLEAR_SYS_LOGIN: "CLEAR_SYS_LOGIN",
    }),
    ...mapActions("sDashBoard", {
      SET_AGENT_FINANCE_RESPONSE: "SET_AGENT_FINANCE_RESPONSE",
    }),

    formatPendingText(pPendingNum) {
      let retValue;

      try {
        if (BaseConfiguration.isDebug) console.log("formatPendingText");

        if (Number(pPendingNum) == 0) {
          retValue = this.$t("cLoginInfo.noPendingBooking");
        } else if (Number(pPendingNum) == 1) {
          retValue = this.$t("cLoginInfo.pendingBooking");
        } else {
          retValue = this.$t("cLoginInfo.pendingBookings");
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_LOGIN_INFO,
          "error in formatPendingText",
          err.toString(),
          "formatPendingText",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    getPostPaidAmt(pPostPaidLimit, pPostPaidRunAmt) {
      let retValue;
      try {
        if (BaseConfiguration.isDebug) console.log("getPostPaidAmt");

        retValue = parseNumber(pPostPaidLimit) - parseNumber(pPostPaidRunAmt);
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_LOGIN_INFO,
          "error in getPostPaidAmt",
          err.toString(),
          "getPostPaidAmt",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    getProgressPercent(finance, isPrepaid) {
      try {
        if (BaseConfiguration.isDebug) console.log("getProgressPercent");
        let retValue;
        if (isPrepaid) {
          retValue =
            (parseFloat(finance.prepaidbalance) /
              parseFloat(finance.prepaidbalance)) *
            100;
        } else {
          retValue =
            (parseFloat(finance.postpaid_runningamount) /
              parseFloat(finance.postpaidlimit)) *
            100;
        }
        // console.log(retValue)
        return retValue;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.THE_HEADER_DROPDOWN_ACCNT,
          "error in getProgressPercent",
          err.toString(),
          "getProgressPercent",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      //return retValue;
    },

    showAlert(val) {
      this.$emit("showAlert", val);
    },
  },

  computed: {
    ...mapGetters("sDashBoard", {
      userLogoDetail: "GET_AGENT_LOGO_DET_RES",
      GET_AGENT_FINANCE_RES: "GET_AGENT_FINANCE_RES",
    }),
  },

  async created() {
    if (BaseConfiguration.isDebug) console.log("created");
    if (this.GET_AGENT_FINANCE_RES) {
      this.emptyLoaderContentLen = this.GET_AGENT_FINANCE_RES.length;
    }

    this.userLogoDet.b2bCompanyFinancess = [];
  },
};
</script>
