import {
    HTTP_CODES
} from "../config/API_DATA";

export function VALIDATE_SUCCESS_RES(response) {
    let returnValue = false;
    // console.log(HTTP_CODES.SUCCESS_STR);
    if (
        response &&
        response.data &&
        response.data.status == HTTP_CODES.SUCCESS_STR
    ) {
        returnValue = true;
    } else {
        returnValue = false;
    }

    return returnValue;
}

export function VALIDATE_GL_SUCCESS_RES(response) {
    let returnValue = false;
    // console.log(HTTP_CODES.SUCCESS_STR);
    if (
        response &&
        !response.loading &&
        response.data
    ) {
        returnValue = true;
    } else {
        returnValue = false;
    }

    return returnValue;
}



export function getErrorMessage(response) {
    let returnValue = null;
    if (
        response &&
        response.data &&
        response.data.status == HTTP_CODES.ERROR_STR &&
        response.data.error &&
        response.data.error.length > 0
    ) {

        returnValue = response.data;
    } else {
        returnValue = null;
    }

    // console.log(JSON.stringify(returnValue.error[0]));

    return returnValue;
}