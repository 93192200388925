export class ALL_CLIENT_CODE {
    static AZ = "az";
}
export class PATHNAME {
    ///userRegistration
    static USERS = "/users";
    static MANAGE_USERS = "/users/manage/all";
    static ADD_USER = "/users/manage/new";
    static USER_STATUS = "/users/manage/status";
    static ROLES_PERMISSIONS = '/users/manage/permissions';
    static INITIAL_PATH = "/";
    ///Common
    static PAGES = "/pages";
    static PAGE_404 = "/pages/404";
    static PAGE_500 = "/pages/500"
    static DASHBOARD = "/dashboard";
    static NOTIFICATION = "/notification";
    static NOTIFICATION_All = "/notification/all";
    static NOTIFICATION_NEW = "/notification/new";
    static NOTIFICATION_EDIT = "/notification/edit";
    static DIY_POST = "/post";
    static DIY_ALL_POST = "/post/all";
    static ADD_DIY_POST = "/post/manage/new";
    static CHANGE_PASSWORD = "/change_password";
    //Tools
    static MANAGE_ORG = '/tools/organize/all';
    //Login
    static SYSTEM_LOGIN = "/system_login";
    static RESET_PASSWORD = "/reset_password";
    ///mobile
    static APROFILE = '/profile'
}
export class COMPONENT_NAME {
    static MANAGE_ORG = 'ManageOrg'
    static NOTIFICATION = 'Notification'
    static NOTIFICATION_NEW = 'NotificationNew'
    static C_DIY_THUMBNAIL = 'cDiyThumbnail'
    static C_SWAP_MODAL_PAGE = 'cSwapModalPage'
    static C_EDIT_FLIGHT = "cEditFlight"
    static C_EDIT_PASSN = "cEditPassn";
    static UPLOAD_EXCEL = 'uploadExcel';
    static C_AGENT_DASHBOARD = 'cAgentDashboard';
    static C_SYS_DASHBOARD = 'cSysDashboard';
    static C_SYS_MINI_AGT_DB = 'cSysMiniAgentDB';
    static C_SYS_USER_LOGIN_ACTIVITY = 'cSysUserLoginActivity';
    static C_SYS_WEEKLY_SALES = 'cSysWeeklySale';
    static C_STATUS_BUTTON = 'cStatusButton'
    static C_BOOKING_PAY_LIST = 'cBookingPayList'
    static C_AGENT_DEPOSIT = 'cAgentDeposit'
    static C_SYSTEM_DEPOSIT = 'cSystemDeposit'
    static C_PAYMENT_LIST = 'cPaymentList'
    static PAGE_404 = "Page404";
    static PAGE_500 = "Page500"
    static DASHBOARD = "DashBoard";
    static DIY_ALL_POST = "diy_all_post";
    static DIY_POST = "diy_post";
    static MANAGE_USERS = "ManageUsers";
    static ADD_USER = "AddUsers";
    static ADD_DIY_POST = "addPost";
    static ROLES_PERMISSIONS = 'RolesAndPermissions';
    static USER_STATUS = "UserStatus";
    static CHANGE_PASSWORD = "ChangePassword";
    static C_PAGINATION_LEN_OPTION = 'cPaginationLenOption';
    static USERS = "Users";
    static SYSTEM_LOGIN = "SystemLogin";
    static PAGES = "pages"
    static THE_HEADER_DROPDOWN_ACCNT = 'TheHeaderDropdownAccnt';
    static THE_SIDEBAR = 'TheSidebar';
    static C_ML_FLIGHTRESULTCARD = "cMLFlightResCard";
    static C_LOGIN_INFO = 'cLoginInfo'
    static C_RANGE_SLIDER = "cRangeSlider"
    static C_ALL_FILTER = "cAllFilter";
    static RESET_PASSWORD = 'ResetPassword';
    static C_FULLSCREEN = 'cFullScreen';
    static C_HELP_TIP = 'cHelpTip';
    static C_TABLE = "cTable";
    ///mobile
    static APROFILE = 'aProfile'
    static C_RICHTEXT_EDITOR = 'cRichTextEditor';
    static C_MONTH_DROPDOWN = "cMonthDropDown";
}
export class MANAGE_COLUMNS {
    static LOCK = 1;
    static VISIBLE = 2;
    static INVISIBLE = 0;

}
export class ORDER {
    static ASC = "asc";
    static DESC = "desc";
}

export class TOAST_TYPE {
    static INFO = "info";
    static SUCCESS = "success";
    static ERROR = "error";
    static CLEAR = "clear";
    static SHOW = "show";
}

export class DEFAULT_DROPDOWN_STATUS {
    static ALL = 'All';
    static ACTIVE = "Active";
    static INACTIVE = 'Inactive';

}

export class DEFAULT_DIY_TAG {
    static ALL = 'All';
    static PHY = "Physics";
    static CHE = 'Chemistry';
    static BIO = 'Biology';

}

export class POST_STATUS {
    static UNPUBLISHED = 0;
    static PUBLISHED = 1;
    static DRAFTED = 2;
    static PENDING = 3;
    static REJECT = 4;

}

export class ITEM_UNITS {
    static UNIT_LIST =[
        {name:'meter', symbol:'m'},
        {name:'centimeter', symbol:'cm'}, 
        {name:'gram', symbol:'g'} ,
        {name:'milligram', symbol:'mg'},
        {name:'kilogram', symbol:'kg'},
        {name:'liter', symbol:'l'},
        {name:'milliliter', symbol:'ml'},
    ]
}

export class ITEM_ECOM {
    static SOURCE_LIST =[
      {name:'Amazon',value:0},
     {name:'Flipkart',value:1}, 
     {name:'BigBasket',value:2 },
    ]
}

export class NOTIFICATION_STATUS {
    static ALL = 'All';
    static DRAFT = "draft";
    static PENDING = 'pending';
    static PUBLISHED = 'published';

}

export class NOTIFICATION_PLATFORMS {
    static ANDROID = 'Android';
    static IOS = "ios";
    static WEB = 'web';

}
export class NOTIFICATION_ACTION {
    static APP = 'app';
    static URL = "url";

}

export class NOTIFICATION_SCHEDULE {
    static NOW = 'now';
    static LATER = "later";

}

export class NOTIFICATION_TYPE {
    static ONCE = 'once';
    static REPEATED = "repeated";

}



export class ALL_APPS_NAME {
    static DIY = 'DIY'
    static PP = 'Project Planner'
}
export class ALL_APPS_ID {
    static DIY = '748da92c-4113-11eb-82d3-fb14a7cd7fde'
    static PP = 'cc3e9168-1f2b-11eb-82d3-cf638f6347ce'
}


export class DATE_TIME_FORMAT {

    static DEFAULT_DATE_FORMAT = 'DD-MMM-YYYY';
    static API_DATE_FORMAT = 'YYYY-MM-DD'
    static DEFAULT_DATE_TIME_FORMAT = 'DD-MMM-YYYY HH:mm:ss'
    static DEFAULT_TIME_FORMAT = 'HH:mm'
    static DATE_TIME_MINS = 'DD-MMM-YYYY HH:mm'
    static DAY_MONTH = 'ddd, DD MMM'
    static DATE_TIME_MERIDIAN = "DD-MMM-YYYY HH:mm A"
    static DATE_PICKER_FORMAT = 'dd-MMM-yyyy'
    static MONTH_DATE = 'MMM DD'
    static MONTH_DATE_YEAR = 'MMM DD, YYYY'
    static DAY_DATE_MONTH = 'ddd, DD MMM'
    static FULL_DATE_DAY = 'ddd, DD MMM YYYY'
    static REPORT_DATE_FORMAT = 'D-MMM-YYYY'
}
export class LOCAL_STORAGE_KEYS {
    static B2B_ADMIN = 'b2b_admin_'
    static LANG = 'language';
    static LOG_COUNT = "logCount"
    static agent_jwt = 'agent_jwt'
    static sys_jwt = 'sys_jwt'
    static MULTIPLE_ERROR = 'multiple_error'
    static FLIGHT_SEARCH_HISTORY = "searchHistory"
    static HOTEL_SEARCH_HISTORY = "hotelSearchHistory"
    static B2B_VERSION = 'b2b_version_'
    static B2B_USER_ID = " b2b_userid_"
    static REPORT_TYPE = "reportType"
    static IS_INTRO_VISITED = "isIntroVisited"
    static LIST_MANAGE_COLUMNS = "manageTable"
    static BASE_SETTINGS = 'base_settings'
    static FORCE_CLEAR_VERSION = "forceClearVersion"
}
export class ROUTER_PARAM {
    static BOOKING_ORDER_ID = 'orderId'
    static PAYMENT_HISTORY = 'ph'
    static PAYMENT_DEBUG_URL = "debugurl"
    static SESSION_ID = "s";
    static PARAM = "param";
    static PROVIDER_ID = "providerid";
    static ORDER_PAYMENT_ID = "orderpaymentid";
    static TOKEN = "token";
    static CODE = "c";
    static VALUE = "v";
}
export class ALERT_MODAL_STATUS {
    static SUCCESS = 1;
    static INFO = 2
    static FAIL = 0
}
export class PASSWORD_TYPE {
    static PASSWORD = "password";
    static TEXT = "text";
}
export class USERS_TYPE {
    static SYSTEM = 'sys'
}
export class LOGIN_PAGE {
    static SYSTEM = 'System'
}