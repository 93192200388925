import SERVICE_URL from "../config/API_DATA";
import BaseConfiguration from "../config/base.js";
import HttpPost from "../http/httppost.js";
import Logger from './logger.js';
import {
    FILE_NAME,
    LOG_TYPE
} from "../config/API_DATA";
export default class LogOut {


    static async getLogout() {
        if (BaseConfiguration.isDebug) console.log("getLogout");


        try {
            await HttpPost.makeHttpPostRequest(SERVICE_URL.LOG_OUT);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_LOG_OUT, "error while  logout  ", err.toString(), "getLogout", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }

    }
}