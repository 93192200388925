import _ from "lodash";
import {
    LOG_TYPE,
    FILE_NAME,
    HTTP_CODES,

} from "../config/API_DATA";
import Logger from "../services/logger.js";
import {
    PATHNAME,
    ROUTER_PARAM,
} from "../util/constants";
import store from "../store";
import router, {
    navigate,
    updateDataEdited
} from "../router/index";
import BaseConfiguration from "../config/base";
import {
    getSysLoginResFromLocal
} from './../applocalstorage/localStorage'
import Vue from "vue";
export const EventBus = new Vue();
import {
    i18n
} from "../plugins/i18n.js";

export function cloneData(pData) {
    let clonedData;
    try {
        clonedData = _.clone(pData);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in cloneData",
            err.toString(),
            "cloneData",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return clonedData;
}

///method to group list of objects based on passed property value
export function GroupByList(pData, pProperty, combinationValue) {
    let grpByList;
    // var _ = require('lodash');
    try {

        grpByList = _.chain(pData)
            .groupBy(pProperty)
            .toPairs()
            .map(function (currentItem) {
                return _.zipObject([pProperty, combinationValue], currentItem);
            })
            .value(); ///list of objects grouped by parameter property
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error while Group list of objects by property value",
            err.toString(),
            "GroupByList",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return grpByList;
}

///method to sort based on property value and order(asc/desc)
export function OrderByList(pData, pFunction, pOrder) {
    try {
        let orderByList = null;

        orderByList = _.orderBy(
            pData, [
                pFunction
            ], [pOrder]
        );
        return orderByList;
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error while sort list of objects by property value and order",
            err.toString(),
            "OrderByList",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

///method to sort based on property values and orders(asc/desc)
export function OrderByTwoProp(pData, pFunction1, pFunction2, pOrder1, pOrder2) {
    try {
        let orderByList = null;

        orderByList = _.orderBy(
            pData, [
                pFunction1, pFunction2
            ], [pOrder1, pOrder2]
        );
        return orderByList;
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error while sort list of objects by property values and orders",
            err.toString(),
            "OrderByTwoProp",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}
///method to sort based on property value and order(asc/desc)
export function OrderBy(pData, pPropToOrder, pOrder) {
    try {
        let orderByList = null;

        orderByList = _.orderBy(
            pData, pPropToOrder, [pOrder]
        );

        return orderByList;
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error while sort list of objects by property value ",
            err.toString(),
            "OrderBy",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function parseNumber(pNum) {
    let retValue = null;
    try {
        if (BaseConfiguration.isDebug) console.log("parseNumber");
        if (pNum) {
            retValue = parseFloat(pNum.toString().replace(",", ""))

        } else {
            retValue = pNum;
        }

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in parseNumber",
            err.toString(),
            "parseNumber",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}


export function isLoggedIn() {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("isLoggedIn");
        if (getSysLoginResFromLocal()) {
            retValue = true;
        } else {

            retValue = false;

        }

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in isLoggedIn",
            err.toString(),
            "isLoggedIn",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function checkIsSystemLogin() {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("checkIsSystemLogin");
        if (store.getters["sSysLogin/GET_ADMIN_SIGNIN_RES"]) {
            retValue = true;
        }

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in checkIsSystemLogin",
            err.toString(),
            "checkIsSystemLogin",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function navToLoginByDialog(pPageName, pParam) {
    try {
        if (router.currentRoute.path != PATHNAME.SYSTEM_LOGIN && router.currentRoute.path != PATHNAME.DASHBOARD && router.currentRoute.path != PATHNAME.INITIAL_PATH && (store.getters["sSysLogin/GET_ADMIN_SIGNIN_RES"])) {
            // console.error(EventBus.$dialog.$root)
            if (!EventBus.$dialog.$root.dialogsARR || (EventBus.$dialog.$root.dialogsARR <= 0)) { ///if dialog not opened//!EventBus.$dialog.mounted
                //  console.error(EventBus.$dialog.mounted,'EventBus.$dialog.mounted')
                //   if(EventBus.$dialog.mounted){
                //     EventBus.$dialog.$root.forceCloseAll();  
                //   }
                let options = {
                    html: true,
                    okText: i18n.tc('dialog.jwtSession.okBtn'),
                    animation: 'bounce',
                    loader: true


                };

                EventBus.$dialog.alert({
                        title: '<strong>' + i18n.tc('dialog.jwtSession.title') + '</strong>',
                        body: pParam.param[1] + '<br>' + "<p style='color: #a7a7a7; padding-top:10px'>" + pParam.param[0] + '</p>',
                    }, options)
                    .then(async function (dialog) {
                        navigate(pPageName, pParam);
                        dialog.close();
                        // EventBus.$dialog.mounted=false;


                    })
                    .catch(function () {

                    });
                // if(window.location.pathname==PATHNAME.INITIAL_PATH){///temp
                // addClassToBody(COMPONENT_NAME.DASHBOARD)

                // }
            }
        } else {
            navigate(pPageName, pParam);
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in navToLoginByDialog",
            err.toString(),
            "navToLoginByDialog",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

}
export function assigni18nLocale(pCallbackFn) {
    try {
        if (store.state.language.language && store.state.language.language !== i18n.locale) {
            i18n.locale = store.state.language.language;

            if (pCallbackFn) {
                pCallbackFn();
            }
        } else if (!store.state.language.language) {
            store.dispatch("language/setLanguage", BaseConfiguration.defaultLanguageCode).then(() => {
                i18n.locale = store.state.language.language;
                if (pCallbackFn) {
                    pCallbackFn();
                }
            });


        } else {
            if (pCallbackFn) {
                pCallbackFn();
            }

        }

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in assigni18nLocale",
            err.toString(),
            "assigni18nLocale",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function handleJWT(pError) {
    let retValue = false;
    //console.log("jwt error check");
    try {

        if (pError) {
            let param = {};
            let isJwt = false;
            if (pError.error) {
                for (let index = 0; index < pError.error.length; index++) {
                    const err = pError.error[index];
                    if (err.code == HTTP_CODES.ERROR_JWT_01 ||
                        err.code == HTTP_CODES.ERROR_JWT_02 ||
                        err.code == HTTP_CODES.ERROR_AUT2 ||
                        err.code == HTTP_CODES.ERROR_PER) {
                        //errCode = err.code;
                        param[ROUTER_PARAM.PARAM] = [err.code, err.value];
                        isJwt = true;
                        updateDataEdited(false);
                    }
                }
            }
            //    if( 
            // pError.errorcode &&
            // pError.errorcode == HTTP_CODES.ERROR_JWT &&
            // pError.error
            // ) {
            //    param[ROUTER_PARAM.PARAM] = [HTTP_CODES.ERROR_JWT, pError.error[0]];
            // }else if(                
            if (isJwt) {
                //      param[ROUTER_PARAM.PARAM] = [pError.error[0].code, pError.error[0].value];
                //    }
                retValue = true;
                if (param.param.length > 0) {
                    if (store && store.getters) {
                        if (store.getters["sSysLogin/GET_ADMIN_SIGNIN_RES"]) {
                            navToLoginByDialog(PATHNAME.SYSTEM_LOGIN, param)

                            // navigate(
                            //     PATHNAME.SYSTEM_LOGIN, param

                            // );
                        } else {
                            if (isSystemPage() == true) {
                                navToLoginByDialog(PATHNAME.SYSTEM_LOGIN, param)

                            } else {
                                navToLoginByDialog(
                                    PATHNAME.SYSTEM_LOGIN, param

                                );
                            }
                            // }
                        }
                    }

                } else {
                    //                 navigate(
                    //                     PATHNAME.AGENT_LOGIN, param

                    //                 );
                    //             }       
                    // }else {
                    retValue = false;
                }
            }
        }

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in handleJWT",
            err.toString(),
            "handleJWT",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function getErrorAlertValue(pError, pIsNeedCode, isAlertExist) {
    let errorAlert = {
        codeList: [],
        valueList: []
    }

    ;
    // Vue.toasted.show('hi');

    try {
        if (BaseConfiguration.isDebug) console.log('getErrorAlertValue')
        if (pError && pError.error && pError.error.length) {

            // eslint-disable-next-line no-constant-condition
            if (!(true)) { //pIsNeedCode
                for (let index = 0; index < pError.error.length; index++) {
                    if (pError.error[index].value) {
                        errorAlert.valueList.push(pError.error[index].value);
                    }
                }
                // eslint-disable-next-line no-constant-condition
            } else if (true) { //pIsNeedCode
                for (let index = 0; index < pError.error.length; index++) {
                    if (pError.error[index].code) {
                        errorAlert.codeList.push(pError.error[index].code);
                    }
                    if (pError.error[index].value) {
                        errorAlert.valueList.push(pError.error[index].value);
                    }
                }
            }

        } else {
            errorAlert = "Failed";
        }


        if (errorAlert && !(isAlertExist) && errorAlert.valueList) {
            let toastOption = {
                duration: 3000,
                position: "bottom-right",
                icon: {
                    name: "cil-reload",
                    after: true
                },
                action: null
            }

            Vue.toasted.clear();
            Vue.toasted.error(
                errorAlert.valueList.toString() + errorAlert.codeList.toString(), toastOption);

        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in getErrorAlertValue",
            err.toString(),
            "getErrorAlertValue",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    //  return errorAlert;
    return errorAlert;
}


export function showSuccessToast(pSuccessMsg) {
    try {
        if (BaseConfiguration.isDebug) console.log("showSuccessToast");
        let toastOption = {
            duration: 3000,
            position: "bottom-right",
            icon: {
                name: "cilStar",
                after: true
            },
            action: null
        }

        Vue.toasted.clear();
        Vue.toasted.success(
            pSuccessMsg ? pSuccessMsg : 'success', toastOption);

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in showSuccessToast",
            err.toString(),
            "showSuccessToast",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

}

















///to open a link
export function onOpenLink(pLink) {
    try {
        window.open(pLink);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error while opening link",
            err.toString(),
            "onOpenLink",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

///List of obj - to group by object property
export function groupBy(pList, pGroupBy) {
    let retValue;
    try {
        retValue = _(pList)
            .groupBy(x => x[pGroupBy])

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in groupBy",
            err.toString(),
            "groupBy",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function differenceBy(pList1, pList2, pProperty) {
    let retValue;
    try {
        retValue =
            _.differenceBy(pList1, pList2, pProperty);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in differenceBy",
            err.toString(),
            "differenceBy",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}


/// get Unique List by param

export function removeDuplicates(pData, pUniqueVal) {
    let retValue;

    try {

        retValue = _.uniqBy(pData, pUniqueVal);

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in removeDuplicates",
            err.toString(),
            "removeDuplicates",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}


export function forceScrollToTop() {

    try {
        /// to scroll the window to top
        var elmnt = document.getElementsByTagName("body");
        elmnt.scrollTop = 0;


    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in scrollToTop",
            err.toString(),
            "scrollToTop",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function scrollToTop() {

    try {
        /// to scroll the window to top
        window.scrollTo(0, 0);


    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in scrollToTop",
            err.toString(),
            "scrollToTop",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function scrollToBottom() {

    try {
        window.scrollTo(0, 0);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in scrollToTop",
            err.toString(),
            "scrollToTop",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}


export function addClassToContainer(isMinimized) {

    try {
        var element = document.getElementById("cwrapper");
        var mysidebar = document.getElementById("mysidebar");

        if (!isMinimized) {
            element.className = element.className.replace(/\bsb-normal\b/g, "");
            element.className = element.className.replace(/\bsb-minimized\b/g, "");
            element.classList.add("sb-minimized");
            setTimeout(function () {
                mysidebar.className = mysidebar.className.replace(/\bc-sidebar-minimized\b/g, "");
                mysidebar.className = mysidebar.className.replace(/\bc-sidebar-unfoldable\b/g, "");

                mysidebar.classList.add("c-sidebar-unfoldable");
            }, 500);


        } else {
            element.className = element.className.replace(/\bsb-normal\b/g, "");
            element.className = element.className.replace(/\bsb-minimized\b/g, "");
            mysidebar.className = mysidebar.className.replace(/\bc-sidebar-unfoldable\b/g, "");
            element.classList.add("sb-normal");
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in addClassToContainer",
            err.toString(),
            "addClassToContainer",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function addClassToBody(pPageName) {

    try {
        document.body.className = pPageName;
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in addClassToBody",
            err.toString(),
            "addClassToBody",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}
export function removeClassBody() {

    try {
        document.querySelector('body').classList.remove('no-javascript');

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in removeClassBody",
            err.toString(),
            "removeClassBody",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

///if  session expired
export function handleSessionExist(paramValue) {

    try {


        if (
            paramValue && (!EventBus.$dialog.$root.dialogsARR || (EventBus.$dialog.$root.dialogsARR <= 0)) //if dialog not opened//!EventBus.$dialog.mounted
        ) {
            // eslint-disable-next-line no-prototype-builtins
            if (paramValue.hasOwnProperty("error")) {
                if (paramValue.error.constructor == Array) {
                    for (let i = 0; i < paramValue.error.length; i++) {
                        if (paramValue.error[i].code == HTTP_CODES.ERROR_SESSION_NOT_EXIST) {
                            let param = {};
                            param[ROUTER_PARAM.CODE] = paramValue.error[i].code;
                            param[ROUTER_PARAM.VALUE] = paramValue.error[i].value;
                            updateDataEdited(false);
                            var images = require.context(
                                "../assets/az/img/svg/icons/",
                                false,
                                /\.svg$/
                            );
                            let searchImage = images("./" + 'refresh' + ".svg");
                            // class='h-50px'
                            let options = {
                                html: true,
                                loader: true,
                                okText: "<img src=" + searchImage + "  width='15px' >" + ' ' + i18n.tc('dialog.searchSession.okBtn') + '</img>',
                                animation: 'bounce',


                            };
                            EventBus.$dialog.alert({
                                    title: '<strong>' + i18n.tc('dialog.searchSession.title') + '</strong>',
                                    body: param.v + '<br>' + "<p style='color: #a7a7a7; padding-top:10px'>" + param.c + '</p>',
                                }, options)
                                .then(async function (dialog) {
                                    navigate(PATHNAME.FLIGHTSEARCH, param);
                                    dialog.close();


                                })
                                .catch(function () {

                                });

                            return true;

                        }

                    }
                }

            }
        } else {
            return false;
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in handleSessionExist",
            err.toString(),
            "handleSessionExist",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function navigateByHostUrl(pPathName, pQuery) {

    try {
        if (pPathName) {
            let origin = BaseConfiguration.isDebug ? "https://b2b.ascent24.io" : window.location.origin;
            //let origin= window.location.origin;

            if (pPathName == PATHNAME.PAYMENTGATEWAY) {
                let url;
                if (BaseConfiguration.paymentDebugUrl != null) {
                    url = origin + pPathName + "?" +
                        ROUTER_PARAM.PROVIDER_ID + '=' + pQuery[ROUTER_PARAM.PROVIDER_ID] + '&' +
                        ROUTER_PARAM.ORDER_PAYMENT_ID + '=' + pQuery[ROUTER_PARAM.ORDER_PAYMENT_ID] +
                        '&' + ROUTER_PARAM.TOKEN + '=' + pQuery[ROUTER_PARAM.TOKEN] + '&' +
                        ROUTER_PARAM.PAYMENT_DEBUG_URL + '=' + pQuery[ROUTER_PARAM.PAYMENT_DEBUG_URL];
                } else {
                    url = origin + pPathName + "?" +
                        ROUTER_PARAM.PROVIDER_ID + '=' + pQuery[ROUTER_PARAM.PROVIDER_ID] + '&' +
                        ROUTER_PARAM.ORDER_PAYMENT_ID + '=' + pQuery[ROUTER_PARAM.ORDER_PAYMENT_ID] +
                        '&' + ROUTER_PARAM.TOKEN + '=' + pQuery[ROUTER_PARAM.TOKEN];
                }


                window.location.assign(url);
            }


        } else {
            throw "invalid path name"
        }


    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in navigateByHostUrl",
            err.toString(),
            "navigateByHostUrl",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function isSystemPage() {

    try {

        let path = router.history.pending ? router.history.pending.path : (router.currentRoute && router.currentRoute.path) ? router.currentRoute.path : null;
        if (path) {
            if (
                path == PATHNAME.MANAGE_USERS ||
                path == PATHNAME.USERS ||
                path == PATHNAME.ADD_USER ||
                path == PATHNAME.USER_STATUS ||
                path == PATHNAME.ROLES_PERMISSIONS ||
                path == PATHNAME.CHANGE_PASSWORD ||
                path == PATHNAME.SYSTEM_LOGIN
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }



    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in isSystemPage",
            err.toString(),
            "isSystemPage",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}


export function cloneDeep(pValue) {
    let retValue;

    try {

        retValue = _.cloneDeep(pValue)
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in cloneDeep",
            err.toString(),
            "cloneDeep",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}



export function getMaxCharOfAmount(pAmount) {
    let retValue;

    try {
        if (pAmount) {
            if (pAmount.toString().includes('.')) {
                let indexOfDot = pAmount.toString().indexOf(".");
                if (indexOfDot >= 15 && pAmount.toString().slice(pAmount.length - 3) != ".00") {
                    retValue = 15;
                } else {
                    retValue = pAmount.length;
                }
            } else {
                retValue = 15;
            }
        }


    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in getTextFieldMaxChar",
            err.toString(),
            "getTextFieldMaxChar",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function removeCurrFormat(pAmount) {
    let retValue;

    try {


        if (pAmount && pAmount.toString().slice(pAmount.length - 3) == ".00") {
            retValue = pAmount.toString().slice(0, -3)
        } else {
            retValue = pAmount;
        }


    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in removeCurrFormat",
            err.toString(),
            "removeCurrFormat",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export async function getSettings() {
    let settings = {
        setting: null,
        alertValue: null
    }
    try {
        settings.setting = {
            "dateTime_format": "DD-MMM-YYYY HH:mm:ss",
            "date_format": "DD-MMM-YYYY",
            "isAddApiLoadingClass": false,
            "isEnableDataEditAlert": false,
            "isMobile": false,
            "not_allowedPages_mbl": [],
            "timeout": 120000,
            "qlUrl":"https://diy-appgql.ascent24.io/v1/graphql",
            "qlKey":"fHJFGDasd12314HJFGDasd123asd12314HJFGDadfjhjk"
        }
        // "qlUrl":"https://appgql.api.ascent24.io/v1/graphql",
        // let settingReq = GetSettings.buildRequest();
        // if (settingReq) {
        //     let settingRes = await GetSettings.getResponse(settingReq);
        //     settings.setting = settingRes.data.data;
        // } else {
        //     throw "invalid request";
        // }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in getSettings",
            err.toString(),
            "getSettings",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return settings;
}

export function getAdminSignInResponse() {
    let retValue = null;

    try {
        if (store && store.getters) {
            if (store.getters["sSysLogin/GET_ADMIN_SIGNIN_RES"]) {
                retValue = store.getters["sSysLogin/GET_ADMIN_SIGNIN_RES"]
            }
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in getAdminSignInResponse",
            err.toString(),
            "getAdminSignInResponse",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}



export function showPaymentHistory(orderDetails) {
    try {
        if (BaseConfiguration.isDebug) console.log("showPaymentHistory");

        if (orderDetails && orderDetails.orderId) {
            let lsOrderIds = orderDetails.orderId.toString().split('.')
            let orderId = lsOrderIds ? lsOrderIds[0] : null

            let param = {

                [ROUTER_PARAM.BOOKING_ORDER_ID]: orderId,
                [ROUTER_PARAM.PAYMENT_HISTORY]: true
            }

            if (orderId)
                navigate(PATHNAME.BOOKING_DETAILS, param)
        } else {
            throw 'not a valid order id'
        }

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in showPaymentHistory",
            err.toString(),
            "showPaymentHistory",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

}

export function isHmsFlow(toPath) {
    let retValue = false;
    try {
        if (BaseConfiguration.isDebug) console.log("isHmsFlow");

        if (toPath == PATHNAME.HOTEL_INFO || toPath == PATHNAME.TARIFF_ALL || toPath == PATHNAME.RATE_PLAN_ALL ||
            toPath == PATHNAME.CANCELLATION_ALL || toPath == PATHNAME.SURCHARGE_ALL || toPath == PATHNAME.TARIFF_NEW ||
            toPath == PATHNAME.RATE_PLAN_NEW || toPath == PATHNAME.CANCELLATION_NEW || toPath == PATHNAME.SURCHARGE_NEW ||
            toPath == PATHNAME.HOTEL_TAX_NEW || toPath == PATHNAME.HOTEL_ROOM_SETUP) {
            retValue = true;
        } else {
            retValue = false;
        }

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in isHmsFlow",
            err.toString(),
            "isHmsFlow",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}