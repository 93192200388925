const state = {
    selectedPostObj: null,
    createPostReq: null,
    createPostRes: null,
    updatePostRes: null,
    updatePostReq: null
};
//gettters
const getters = {
    GET_CREATE_POST_REQUEST: (state) => {
        return state.createPostReq;
    },
    GET_CREATE_POST_RESPONSE: (state) => {
        return state.createPostRes;
    },
    GET_UPDATE_POST_RESPONSE: (state) => {
        return state.updatePostRes;
    },
    GET_UPDATE_POST_REQUEST: (state) => {
        return state.updatePostReq;
    },
    GET_SELECTED_POST: (state) => {
        return state.selectedPostObj;
    }
};
//actions
const actions = {
    CLEAR_ADD_POST_STORE: ({ commit }) => {
        commit('clearAddPostStore');
    },
    SET_SELECTED_POST: ({ commit }, selectedPostObj) => {
        commit("saveSelectedPost", selectedPostObj);
    },

    SET_CREATE_POST_REQUEST: ({ commit }, req) => {
        commit("saveCreatePostReq", req);
    },

    SET_CREATE_POST_RESPONSE: ({ commit }, res) => {
        commit("saveCreatePostRes", res);
    },
    SET_UPDATE_POST_REQUEST: ({ commit }, req) => {
        commit("saveUpdatePostReq", req);
    },
    SET_UPDATE_POST_RESPONSE: ({ commit }, res) => {
        commit('saveUpdatePostRes', res);
    },


};
//mutations

const mutations = {
    clearAddPostStore(state) {

        state.selectedPostObj = null;
        state.createPostReq = null;
        state.createPostRes = null;
        state.updatePostRes = null;
        state.updatePostReq = null;
    },

    saveCreatePostReq(state, req) {
        state.createPostReq = req;
    },
    saveCreatePostRes(state, res) {
        state.createPostRes = res;
    },

    saveSelectedPost(state, selectedPostObj) {
        state.selectedPostObj = selectedPostObj;
    },
    saveUpdatePostReq(state, req) {
        state.saveUpdatePostReq = req;
    },
    saveUpdatePostRes(state, res) {
        state.saveUpdatePostRes = res;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};