import main from './../../main'

const actions = {

    ON_PROGRESS_START: () => {
        if (main) {
            main.$Progress.start();
        }
    },
    ON_PROGRESS_FINISH: () => {
        if (main) {
            main.$Progress.finish();
        }
    },
    ON_PROGRESS_FAIL: () => {
        if (main) {
            main.$Progress.fail();
        }
    },

    ON_PROGRESS_SET: (value) => {
        if (main) {
            main.$Progress.set(value);
        }
    }





};



export default {
    namespaced: true,
    actions
}