<template>
    <ValidationProvider tag="div" :rules="rules" :label="label" :vid="vid" v-slot="{ errors }">
        <div>
            <!-- <label>{{label}}</label>  -->
            <!-- deselect-label="Can't remove this value" placeholder="Select one" -->
            <multiselect v-model="selValueModel" :track-by=ctrlCode :label=ctrlName
                         :placeholder="$t('language_picker_helper.language_picker_helper')" :options="options" :searchable="isSearchable"
                         :allow-empty="false" :multiple="isMultiple" :taggable="false" :loading="isInValidOptions()"
                         @input="onLangChange(selValueModel)" deselectLabel="" deselectGroupLabel="" selectLabel=""
                         selectedLabel="">
                <template slot="singleLabel" slot-scope="{ option }"> {{ option[ctrlName] }}</template>
                <template slot="option" slot-scope="props">
                    <div><span>{{ props.option[ctrlName] }}</span>
                        <strong class="right" v-if="isShowCode">{{ props.option[ctrlCode] }}</strong>
                    </div>
                </template>
               <span slot="noResult"><strong>{{ $t("noMatchFound.noMatchFound") }}</strong></span>
            </multiselect>

            <div v-if="errors && errors[0]" class="invalid-feedback icon">
                <div v-show="!selValueModel">
                    <span>{{ errors[0] }}</span>
                </div>
            </div>
        </div>
        <div v-show="showAlert">

            <CAlert show color="danger">
                <div v-if="alertValue&&alertValue.hasOwnProperty('valueList')">
                    <p v-for="(value,index) in alertValue.valueList" :key="index">
                        {{value}}
                    </p>
                </div>
                <div v-if="alertValue&&alertValue.hasOwnProperty('codeList')">
                    <span>{{$t("cAlert.errorCode")+' : '}}</span><span v-for="(code,index) in alertValue.codeList" :key="index">
                        {{code +' '}}
                      </span>
                </div>
                <div v-if="alertValue&&alertValue.constructor==String">
                    <p>
                        {{alertValue}}
                    </p>
                </div>
            </CAlert>
            <!-- <CAlert show color="danger">{{alertValue}}</CAlert> -->
        </div>
    </ValidationProvider>
</template>

<script>
    import {
        ValidationProvider
    } from "vee-validate";
    import Multiselect from "vue-multiselect";
    import apiLanguageList from '../services/apiLanguageList';
    import {
        mapGetters,
        mapActions
    } from "vuex";
    import BaseConfiguration from "../config/base";
    import {
        LOG_TYPE,
        FILE_NAME,
    } from "../config/API_DATA";
    import Logger from "../services/logger";
    import {
        VALIDATE_SUCCESS_RES,
        getErrorMessage
    } from "../util/apiValidate";
    import {
        handleJWT,
        getErrorAlertValue
    } from "../util/util";

    export default {
        name: 'cLanguagePicker',
        components: {
            //cDropDown,
            ValidationProvider,
            Multiselect
        },

        model: {
            prop: "selValue"
        },
        props: {
            selValue: {
                // eslint-disable-next-line vue/require-prop-type-constructor
                type: Object | Array,
                default: []
            },
            isShowCode: {
                type: Boolean,
                default: false
            },

            isMultiple: {
                type: Boolean,
                default: false
            },
            isSearchable: {
                type: Boolean,
                default: false
            },
            ctrlName: {
                type: String,
                default: ""
            },
            ctrlCode: {
                type: String,
                default: ""
            },
            url: {
                type: String,
                default: null
            },
            rules: {
                type: [String, Object],
                default: ""
            },
            label: {
                type: String,
                default: ""
            },
            vid: {
                type: String,
                default: undefined
            },
            value: {
                type: Object,
                default: null
            },

        },
        data: () => ({

            options: [],
            selected: false,
            selValueModel: {
                langcode: null,
                en_name: null,
                native_name: null,
            },
            showAlert: false,
            alertValue: null,

        }),

        watch: {
            selValue(val) {
                if (val !== this.selValueModel) {
                    this.selValueModel = val;
                }

            }
        },

        // async created() {
        //     //  console.log(this.selValue);
        //     this.selValueModel = this.selValue;

        //     if (this.url) {
        //         switch (this.url) {
        //             case SERVICE_URL.LANGUAGE_LIST: {
        //                 if (this.langRes == null) {
        //                     this.options = await this.getLanguageListRes();
        //                     if (!(this.selValueModel && this.selValueModel.langcode))
        //                         this.selValueModel = this.getDefaultLanguage(this.options);
        //                     this.emitVal(this.selValueModel);
        //                     // this.emitVal([this.selValueModel]);
        //                     // console.log(this.selValueModel);
        //                     // this.$emit('input', this.selValueModel);
        //                 } else {
        //                     this.options = this.langRes;

        //                     if (!(this.selValueModel && this.selValueModel.langcode))
        //                         this.selValueModel = this.getDefaultLanguage(this.options);
        //                     this.emitVal(this.selValueModel);
        //                     // this.emitVal([this.selValueModel]);
        //                     if (BaseConfiguration.isDebug) console.log("options from store!");

        //                 }
        //                 // console.log(this.options);
        //             }
        //             break;

        //         default:
        //             throw "invalid ";
        //             break;
        //         }
        //     } else {
        //         throw "invalid service url";
        //     }
        // },

        computed: {
            ...mapGetters("sCLanguagePicker", {
                langRes: "GET_ALL_LANGUAGE_RES",
            }),
            ...mapGetters('language', {
                GET_LANGUAGE: 'GET_LANGUAGE'
            })

        },

        methods: {

            ...mapActions("sCLanguagePicker", ["SET_ALL_LANGUAGE_REQ"]),
            ...mapActions("sCLanguagePicker", ["SET_ALL_LANGUAGE_RES"]),
            ...mapActions("sCLanguagePicker", ["CLEAR_C_LANGUAGEPICKER_STORE"]),

            onLangChange(pLang) {

                try {
                    if (pLang && pLang.langcode) {
                        this.emitVal(pLang);

                    } else {

                        throw ('invalid language list')
                    }
                } catch (err) {
                    let body = Logger.buildRequest(
                        FILE_NAME.C_LANGUAGE_PICKER,
                        "error in    on Language Change",
                        err.toString(),
                        "onLangChange()",
                        LOG_TYPE.ERROR
                    );
                    Logger.getResponse(body);
                }
            },

            emitVal(value) {
                if (value && value.langcode) {
                    if (this.$i18n)
                        this.$i18n.locale = value.langcode;
                    this.$store.dispatch('language/setLanguage', value.langcode);
                    this.$emit('input', value);
                }
            },

            getSelectedLanguage(pOptionsList) {
                let retValue;
                try {

                    if (pOptionsList != null && pOptionsList.length > 0) {

                        if (this.GET_LANGUAGE) {
                            let langCode = this.GET_LANGUAGE;
                            retValue = pOptionsList.find(function (lang) {

                                return lang.langcode == langCode
                            });
                        } else {
                            retValue = pOptionsList.find(function (lang) {

                                return lang.langcode == BaseConfiguration.defaultLanguageCode
                            });
                        }
                    } else {
                        throw "invalid options list"
                    }
                } catch (err) {
                    let body = Logger.buildRequest(FILE_NAME.C_LANGUAGE_PICKER, "error in LanguagePicker options", err.toString(), "getSelectedLanguage", LOG_TYPE.ERROR);
                    Logger.getResponse(body);
                }

                return retValue;
            },

            isInValidOptions() {
                let retValue = false;
                if (this.options && this.options.length > 0)
                    //check options length
                    retValue = false;
                else retValue = true;
                return retValue;
            },

            async getLanguageListRes() {
                if (BaseConfiguration.isDebug) console.log("getLanguageListRes");
                let retValue;

                try {
                    let langRequest = apiLanguageList.buildRequest();

                    if (langRequest != null) {
                        this.SET_ALL_LANGUAGE_REQ(langRequest);

                        let langResponse = await apiLanguageList.getResponse(
                            langRequest
                        );
                        if (VALIDATE_SUCCESS_RES(langResponse)) {
                            if (langResponse.data.data && langResponse.data.data.languages) {
                                retValue = langResponse.data.data.languages;
                                /*retValue.push({ en_name: "English (CA)",
                                                langcode: "ca",
                                                native_name: "english"})*/
                                this.alertValue = null;
                                this.SET_ALL_LANGUAGE_RES(retValue);

                            }
                        } else {
                            this.showAlert = true;

                            let error = getErrorMessage(langResponse);

                            let isJwt = handleJWT(error);
                            if (!isJwt) {
                                this.alertValue = getErrorAlertValue(error)
                            }
                        }
                    } else {
                        throw "invalid request";
                    }
                } catch (err) {
                    let body = Logger.buildRequest(
                        FILE_NAME.API_GET_LANGUAGE_LIST,
                        "error in  getting Language Response",
                        err.toString(),
                        "getLanguages()",
                        LOG_TYPE.ERROR
                    );
                    Logger.getResponse(body);
                }
                return retValue;
            },

        },

        async created() {
            try {

                this.selValueModel = this.selValue;

                if (this.langRes == null) {
                    this.options = await this.getLanguageListRes();

                } else {
                    this.options = this.langRes;
                    if (BaseConfiguration.isDebug) console.log("options from store!");

                }

                this.selValueModel = this.getSelectedLanguage(this.options);

                if (this.selValueModel && this.selValueModel.langcode) {
                    this.emitVal(this.selValueModel);
                }
            } catch (err) {
                let body = Logger.buildRequest(
                    FILE_NAME.C_LANGUAGE_PICKER,
                    "error in Created()",
                    err.toString(),
                    "Created()",
                    LOG_TYPE.ERROR
                );
                Logger.getResponse(body);
            }

        },

    };
</script>

<style>
    .dropdown-menu-vue {
        position: absolute;
        top: 100%;
        z-index: 1000;
        display: block;
        float: left;
        min-width: 10rem;
        padding: 0.5rem 0;
        margin: 0.125rem 0 0;
        font-size: 0.875rem;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
        border: 1px solid;
        border-radius: 0.25rem;
        color: #4f5d73;
        background-color: #fff;
        border-color: #d8dbe0;
    }
</style>
