import {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackOverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex,
    cilPenAlt,
    cilContact,
    

    

} from '@coreui/icons'
import {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
} from '@coreui/icons'
import {
    cilWarning,
    cilArrowRight, cilChevronLeft,cilChevronRight,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    // cilCircleCheck,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilLoopCircular,
    cilReload,
    cilCreditCard,
    cilFindInPage,
    cilDescription, 
    cilChartLine,
    cilFax,
    cilAirplay,
    cilAudio,
    cilTransfer,
    cilMoney,
    cilFingerprint,
    cilFunctions,
    cilThumbUp,
    cilAirplaneMode,
    cilFlightTakeoff,
    cilInfo,
    cilTrash,
    cilClone,
    cilShortText,
    cilAddressBook,
    cilNotes,
   
    cilEqualizer,
    cilYen,
    cilTags,
    cilMonitor,
    cilSpreadsheet,
    cilMap,
    cilMediaPlay,
    cilExternalLink,
    cilVector,
    cilBank,
    cilHospital,
    cilBusAlt,
    cilLocomotive,
    cilBuilding,
    
    



} from '@coreui/icons'

export const iconsSet = Object.assign({}, {
    cilWarning,
    cilArrowRight, cilChevronLeft,cilChevronRight,
    cilMediaPlay,
    cilExternalLink,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    // cilCircleCheck,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilLoopCircular,
    cilReload,
    cilCreditCard,
    cilFindInPage,
    cilDescription,
    cilChartLine,
    cilFax,
    cilAirplay,
    cilAudio,
    cilTransfer,
    cilMoney,
    cilPenAlt,
    cilFingerprint,
    cilFunctions,
    cilThumbUp,
    cilAirplaneMode,
    cilFlightTakeoff,
    cilInfo,
    cilTrash,
    cilClone,
    cilShortText,
    cilAddressBook,
    cilNotes,
    cilContact,
    cilEqualizer,
    cilYen,
    cilTags,
    
    cilMonitor,
    cilSpreadsheet,
    cilMap,
    cilVector,
    cilBank,
    cilHospital,
    cilBusAlt,
    cilLocomotive,
    cilBuilding

}, {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
}, {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackOverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex,
    cilReload,
    cilUserFollow,
    cilCreditCard,
    cilFindInPage,
    
})