const state = {
    currencyOptions : []
}

// getters
const getters = {
    GET_CURRENCY_OPTIONS: (state) => {
        return state.currencyOptions;
    },
}

// actions
const actions = {
    CLEAR_C_ORG_CURRENCY_STORE: ({ commit }) => {
        commit('clearCOrgCurrencyDpStore');
    },
    SET_CURRENCY_OPTIONS({ commit }, currency) {
        commit('getCurrencyOptions', currency);
    },
}

// mutations
const mutations = {
    clearCOrgCurrencyDpStore(state) {
        state.currencyOptions = []
    },
    getCurrencyOptions(state, currency) {
        state.currencyOptions = currency
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

