const state = {
    settings: null
};

const getters = {
    GET_SETTINGS: (state) => {
        return state.settings;
    }
};

const actions = {
    SET_SETTINGS: ({ commit }, settingRes) => {
        commit("saveSettings", settingRes);
    },
    CLEAR_SETTINGS_STORE: ({ commit }) => {
        commit('clearSettingsStore');
    },

};

const mutations = {
    clearSettingsStore: (state) => {    
        state.settings = null;
    },
    saveSettings: (state, settingRes) => {
        state.settings = settingRes;
    }
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};