<template>
<div v-if="!isMobile">
    <GlobalEvents
    @keyup.enter="handleFormClick"
    
    ></GlobalEvents>
<AppTemplate></AppTemplate>  
</div>
<ion-app v-else-if="isMobile">
<AppTemplate></AppTemplate>  
</ion-app>
</template>

<script>

import BaseConfiguration from "./config/base";
import AppTemplate from "./AppTemplate"
import Logger from './services/logger';
import { LOG_TYPE } from './config/API_DATA';
export default {
    name: 'App',

data() {
    return {
        isMobile: BaseConfiguration.isMobile
    }
},
components:{
    AppTemplate
},

methods:{
     handleFormClick() {
     if (document.activeElement instanceof HTMLElement){
       
      document.activeElement.blur();}
    },

handleBackSpace(e) {
       //   let retValue;
    try {
      if(BaseConfiguration.isDebug) console.log("handleBackSpace");
   
    // eslint-disable-next-line no-undef
    if (e.which === 8 && !$(e.target).is("input, textarea")) {

        e.preventDefault();
    }

    } catch (err) {
            let body = Logger.buildRequest(
             "App.vue",
              "error in handleBackSpace",
              err.toString(),
              "handleBackSpace",
              LOG_TYPE.ERROR
            );
            Logger.getResponse(body);
          }
   //   return retValue;
    }, 

}

}



</script>


<style  src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">

// Import Main styles for this application
//@import 'assets/az/scss/style';
//@import 'assets/mf/scss/style';
@import 'assets/packagecss/material-vue-date-picker.min.css';
.page-enter-active,
.page-leave-active {
    transition: opacity 0.2s, transform 0.2s;
}

.page-enter,
.page-leave-to {
    opacity: 0;
    transform: translateX(-30%);
}
</style>
<style src="vue-datetime/dist/vue-datetime.min.css"></style>

<!--<style id="clientstyle" lang="scss" src="./assets/mf/scss/style.scss"></style>-->