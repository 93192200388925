/* eslint-disable use-isnan */
import Vue from 'vue'
import numeral from "numeral";
import Logger from "./../services/logger";
import store from './../store/index.js'
import BaseConfiguration from "./../config/base.js";
import {
    formatDate,
    convertToHrMin,
    timeAgoFormat,
    timeLeftFormat,
    convertUTCDateToLocalDate
} from "./../util/date/dateFunctions.js"
import {
    LOG_TYPE,
    FILE_NAME,

} from "./../config/API_DATA";


///amount format based on api res for labels
Vue.filter("formatNumber", function (value, pCurrency) {
    if (value) {
        //  let currencyFormat = store.getters["sCurrencyLs/GET_ORG_CURRENCY_FORMAT_RES"];
        let orgCurrencies = store.getters["sCurrencyLs/GET_ORG_CURRENCIES"];
        let displayNumFormat;


        if (orgCurrencies && pCurrency && orgCurrencies[pCurrency]) {
            displayNumFormat = orgCurrencies[pCurrency].display_numberformat
        }
        let formattedValue;
        // let formattedValue=numeral(value).format('0,0.00[0]');
        if (displayNumFormat) {
            formattedValue = numeral(value).format(displayNumFormat);

            if (formattedValue && formattedValue != NaN && formattedValue != "NaN") {

                return formattedValue;
            } else {
                return null;
            }
        } else {
            formattedValue = numeral(value).format(BaseConfiguration.defaultCurrencyObj.display_numberformat); //default currency format
            return formattedValue;
            // throw "invalid display number format";
        }


    }
});



///amount format as per Response but only show zeros except comma for text boxes
Vue.filter("currencyFormat", function (pValue, pCurrency) {
    if (pValue && pValue.toString().match(/^[0-9.,]*$/) !== null) {
        let value = pValue;
        let indexOfDot = value.toString().indexOf(".");

        if (indexOfDot >= 15 && value.toString().slice(value.toString().length - 3) != ".00") {
            value = value.toString().slice(0, -3);

        }
        // if(value < Number.MAX_SAFE_INTEGER ){
        //  console.error("YEs")
        // }else{
        //     console.error("No")

        // }
        //   let currencyFormat = store.getters["sCurrencyLs/GET_ORG_CURRENCY_FORMAT_RES"];
        let orgCurrencies = store.getters["sCurrencyLs/GET_ORG_CURRENCIES"];
        let displayNumFormat;


        if (orgCurrencies && pCurrency && orgCurrencies[pCurrency]) {
            displayNumFormat = orgCurrencies[pCurrency].display_numberformat
        }
        let formattedValue;
        if (!displayNumFormat) {

            displayNumFormat = BaseConfiguration.defaultCurrencyObj.display_numberformat; //default currency format
        }

        let indexUntilRem = displayNumFormat.indexOf(",");
        displayNumFormat = displayNumFormat.slice(indexUntilRem + 1, displayNumFormat.length);
        formattedValue = numeral(value).format(displayNumFormat);


        if (formattedValue && formattedValue != NaN && formattedValue != "NaN") {
            // console.error(formattedValue);
            if (formattedValue.toString().includes('.')) {
                let indexOfDot = formattedValue.toString().indexOf(".");
                if (indexOfDot == 15) {
                    formattedValue = formattedValue.toString().substring(0, indexOfDot);

                }
            }
            if (formattedValue && formattedValue.includes(',')) {
                formattedValue = formattedValue.replace(/,/g, '');
            }

            return formattedValue;
        } else {
            return null;
        }


    } else {
        return pValue;
    }
});

/// formatting price for price filter in flight result page
Vue.filter("formatPrice", function (value, params) {
    let retValue;

    if (Number.isInteger(value)) {
        retValue = value;

    } else {
        if (params == 'min') {
            retValue = Math.trunc(value);
        } else if (params == 'max') {
            retValue = Math.trunc(value) + 1;
        }

    }
    return retValue;
});


Vue.filter("formatDateTime", function (value, params) {
    let dateTime;
    let defaultDateTimeFormat;
    let settingsRes = store.getters["sSettings/GET_SETTINGS"];

    if (settingsRes && settingsRes.dateTime_format) {
        defaultDateTimeFormat = settingsRes.dateTime_format.toString(); //.toUpperCase()
    } else {
        defaultDateTimeFormat = BaseConfiguration.defaultDateTimeFormat
    }
    if (value) {


        if (value instanceof Date) {
            params ? dateTime = formatDate(value, params) : dateTime = formatDate(value, defaultDateTimeFormat);

        } else {
            value = new Date(value);
            params ? dateTime = formatDate(value, params) : dateTime = formatDate(value, defaultDateTimeFormat);

        }
    } else {
        dateTime = '';
    }
    return dateTime;
});




Vue.filter("formatDateTimeTZ", function (value, params) {
    let dateTime;
    let defaultDateTimeFormat;
    let tz;
    let settingsRes = store.getters["sSettings/GET_SETTINGS"];


    if (settingsRes && settingsRes.dateTime_format) {
        defaultDateTimeFormat = settingsRes.dateTime_format.toString(); //.toUpperCase()
    } else {
        defaultDateTimeFormat = BaseConfiguration.defaultDateTimeFormat
    }
    if (value) {


        if (value instanceof Date) {
            params ? dateTime = formatDate(value, params) : dateTime = formatDate(value, defaultDateTimeFormat);

        } else {
            value = new Date(value);

            params ? dateTime = formatDate(value, params) : dateTime = formatDate(value, defaultDateTimeFormat);

        }
        tz = String(String(new Date(value).toString().split("(")[1]).split(")")[0]);
    } else {
        dateTime = '';
    }
    return dateTime + ((dateTime && tz) ? " (" + tz + ")" : '');
});

Vue.filter("formatDate", function (value, params) {
    let date;
    let defaultDateFormat;
    let settingsRes = store.getters["sSettings/GET_SETTINGS"];



    if (settingsRes && settingsRes.date_format) {
        defaultDateFormat = settingsRes.date_format.toString(); //.toUpperCase()
    } else {
        defaultDateFormat = BaseConfiguration.defaultDateFormat
    }
    if (value) {

        if (value instanceof Date) {
            params ? date = formatDate(value, params) : date = formatDate(value, defaultDateFormat);

        } else {
            value = new Date(value);
            params ? date = formatDate(value, params) : date = formatDate(value, defaultDateFormat);

        }
    } else {
        date = '';
    }
    return date;
});

Vue.filter("timeAgoFormat", function (value) {
    let date;
    if (value) {
        if (value instanceof Date) {
            date = timeAgoFormat(value);
        } else {
            value = new Date(value);
            date = timeAgoFormat(value);
        }
    } else {
        date = '';
    }

    return date;
});
Vue.filter("timeLeftFormat", function (value) {
    let date;
    if (value) {
        if (value instanceof Date) {
            date = timeLeftFormat(value);
        } else {
            value = new Date(value);
            date = timeLeftFormat(value);
        }
    } else {
        date = '';
    }
    return date;
});

Vue.filter("convertUTCDateToLocalDate", function (value) {
    let date;
    if (value instanceof Date) {
        date = convertUTCDateToLocalDate(value);
    } else {
        value = new Date(value);
        date = convertUTCDateToLocalDate(value);
    }
    return date;
});

/// filter to convert mins to hour and min etc...
Vue.filter("convertToHrMin", function (value, params, format) {
    let time = null;
    if (value) {
        time = convertToHrMin(value, params, format);
    }
    return time;
});

/// vue filter to get airline name based on opAirline code

Vue.filter("airlineName", function (value) {
    let retValue = null;

    let airlines = store.getters["sResult/GET_AIRLINES"];
    if (airlines == null) {
        airlines = store.getters["sMLResult/GET_AIRLINES"];
    }
    try {
        if (BaseConfiguration.isDebug) console.log("airlineNameVueFilter");
        if (airlines != null) {
            for (let index = 0; index < Object.keys(airlines).length; index++) {
                if (value) {
                    if (Object.keys(airlines)[index] == value) {
                        retValue = Object.values(airlines)[index].airline_name;
                        break;
                    }
                }
            }
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.MAIN_FILE,
            "error in airlineNameVueFilter",
            err.toString(),
            "airlineNameVueFilter",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
});

/// vue filter to get airline name based on opAirline code

Vue.filter("airportName", function (value) {
    let retValue = null;

    let sRoutesObj = store.getters["sCSearch/GET_ROUTES_OBJ"];
    try {
        if (BaseConfiguration.isDebug) console.log("airportNameVueFilter");

        if (value && sRoutesObj && sRoutesObj.airports && sRoutesObj.airports[value] && sRoutesObj.airports[value].airportname) {
            retValue = sRoutesObj.airports[value].airportname;
        }
        if (retValue == null) {
            let sAirportObj = null;
            sAirportObj = store.getters["sResult/GET_AIRPORTS"];
            if (sAirportObj == null) {
                sAirportObj = store.getters["sMLResult/GET_AIRPORTS"];
            }
            if (value && sAirportObj && sAirportObj[value] && sAirportObj[value].airportname) {
                retValue = sAirportObj[value].airportname;
            }
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.MAIN_FILE,
            "error in airportNameVueFilter",
            err.toString(),
            "airportNameVueFilter",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
});



Vue.filter("cityName", function (value) {
    let retValue = null;

    let sRoutesObj = store.getters["sCSearch/GET_ROUTES_OBJ"];
    let airportObj = null;
    try {
        if (BaseConfiguration.isDebug) console.log("cityNameVueFilter");

        if (value && sRoutesObj && sRoutesObj.airports && sRoutesObj.airports[value] && sRoutesObj.airports[value].city) {
            retValue = sRoutesObj.airports[value].city;
        }
        if (retValue == null) {
            airportObj = store.getters["sResult/GET_AIRPORTS"];
            if (!airportObj) {
                airportObj = store.getters["sMLResult/GET_ML_AIRPORTS"];
            }
            if (value && airportObj && airportObj[value] && airportObj[value].city) {
                retValue = airportObj[value].city;
            }
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.MAIN_FILE,
            "error in cityNameVueFilter",
            err.toString(),
            "cityNameVueFilter",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
});

///Amount filter
Vue.filter("amountformat", function (value, currencyvalue) {
    let retValue = value,
        currObj = currencyvalue;
    if (!currObj) { //get curr obj from result store
        currObj = store.getters["sResult/GET_CURRENCY"];
        if (!currObj) {
            currObj = store.getters["sMLResult/GET_CURRENCY"];
        }
    } else { //get  curr obj from org currency store
        let orgCurrencies = store.getters["sCurrencyLs/GET_ORG_CURRENCIES"];
        let displayNumFormat;


        if (orgCurrencies && currObj && orgCurrencies[currObj]) {
            displayNumFormat = orgCurrencies[currObj].display_numberformat

            currObj = {
                format: displayNumFormat
            }
        }

    }

    if (value != null && value != undefined && currObj && currObj.format)
        retValue = numeral(value).format(currObj.format); // displaying other groupings/separators is possible, look at the docs
    else
        throw "invalid value or currObj"

    return retValue;
});


///Airline Image filter
Vue.filter("ALimage", function (value) {
    let defaultImg = BaseConfiguration.defaultALimgurl;
    let srcExtension = ".svg";
    let imagesrc = defaultImg + value.toLowerCase() + srcExtension;
    return imagesrc.toString();

});

///err msg filter to remove the index attached to error message in pax page (only for paxPage)
Vue.filter("errmsg", function (value, pPaxIndex) {
    let retValue = value;
    if (value != null) {
        if (value.charAt(value.length - 1) == pPaxIndex)
            retValue = value.replace(value.charAt(value.length - 1), "");
        else
            retValue = value;
    }
    return retValue;
});

Vue.filter("hmsCountryName", function (value) {
    let retValue = null;
    let sRoutesObj = store.getters["sHotelSearch/GET_HOTEL_ROUTES_OBJ"];
    try {
        if (BaseConfiguration.isDebug) console.log("hmsCountryNameVueFilter");
        if (value && sRoutesObj && sRoutesObj.Country && sRoutesObj.Country[value]) {
            retValue = sRoutesObj.Country[value].value;
        }

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.MAIN_FILE,
            "error in hmsCountryNameVueFilter",
            err.toString(),
            "hmsCountryNameVueFilter",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
});

Vue.filter("hmsCityName", function (value) {
    let retValue = "";
    let sCitiesObj = store.getters["sHotelSearch/GET_ALL_CITIES"];
    try {
        if (BaseConfiguration.isDebug) console.log("hmsCityNameVueFilter");
        if (value && sCitiesObj && sCitiesObj[value] && sCitiesObj[value][0]) {
            retValue = sCitiesObj[value][0].cityname
        } else {
            retValue = ""
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.MAIN_FILE,
            "error in hmsCityNameVueFilter",
            err.toString(),
            "hmsCityNameVueFilter",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
});