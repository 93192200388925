const state = {
    allLangDet: null,
    allLangReq: null,
    allLangRes: null,
}

// getters
const getters = {
    GET_ALL_LANGUAGE_DET: (state) => {
        return state.allLangDet;
    },
    GET_ALL_LANGUAGE_REQ: (state) => {
        return state.allLangReq;
    },
    GET_ALL_LANGUAGE_RES: (state) => {
        return state.allLangRes;
    },
}

// actions
const actions = {
    CLEAR_C_LANGUAGEPICKER_STORE: ({ commit }) => {
        commit('clearCLanguagePickerStore');
    },
    SET_ALL_LANGUAGE_DET({ commit }, lang) {
        commit('getAllLanguageDet', lang);
    },
    SET_ALL_LANGUAGE_REQ({ commit }, langReq) {
        commit('getAllLanguageReq', langReq);
    },
    SET_ALL_LANGUAGE_RES({ commit }, langRes) {
        commit('getAllLanguageRes', langRes);
    },
}

// mutations
const mutations = {
    clearCLanguagePickerStore(state) {
        state.allLangDet = null;
        state.allLangReq = null;
        state.allLangRes = null;
    },
    getAllLanguageDet(state, lang) {
        state.allLangDet = lang
    },
    getAllLanguageReq(state, langReq) {
        state.allLangReq = langReq
    },
    getAllLanguageRes(state, langRes) {
        state.allLangRes = langRes
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

