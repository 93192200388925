export default {
    langTest: 'Velkommen til din App',
    language_picker_helper: 'Velg et språk',
    languages: {
        english: 'Engelsk',
        norwegian: 'Norsk',
    },
    agentRegPage: {
        coDetails: "aaaa",
        agencyName: "bbbb",
        busRegNo: "cccc",
        iataNo: "dddd",
        agencyPlace: "eeee",
        optPlace: "ffff"
    },

};