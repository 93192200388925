<template>
  <div>
    <CDropdownHeader tag="div" class="text-center" style="padding: 0px">
      <div class="profile-drop-wrap">
        <div class="head" v-if="userLogoDet.logoDet">
          <div class="profile">
            <avatar
              class="c-avatar-img square"
              :username="getUserName"
              :size="80"
              color="#fff"
            ></avatar>
            <!-- <img
            src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/gettyimages-168889366-1572948662.jpg?crop=0.668xw:1.00xh;0.105xw,0&resize=480:*"
            alt
            />-->
          </div>
          <div class="details">
            <div class="name">{{ getUserName }}</div>
            <div
              class="profile-t-tag"
              v-if="
                userLogoDet.logoDet.roles &&
                userLogoDet.logoDet.roles.length > 0
              "
            >
              <div
                class="role"
                v-for="(role, index) in userLogoDet.logoDet.roles"
                :key="'r' + index"
              >
                <div>{{ role }}</div>
              </div>
            </div>
            <!--              <div class="ag-name">-->
            <!--                H24 Agency UK-->
            <!--              </div>-->
            <div class="email" v-if="userLogoDet.logoDet.agencyname">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                role="img"
                class="c-icon c-icon-custom-size"
                height="42"
              >
                <path
                  d="M19.294 16.109l-3.414-2.219 1.287-2.359c0.288-0.519 0.457-1.137 0.458-1.796v-3.735c0-2.9-2.351-5.25-5.25-5.25s-5.25 2.351-5.25 5.25v0 3.735c0.001 0.658 0.17 1.277 0.468 1.815l-0.010-0.019 1.287 2.359-3.414 2.219c-1.033 0.676-1.706 1.828-1.706 3.137 0 0.002 0 0.005 0 0.007v-0 3.997h17.25v-3.997c0-0.002 0-0.005 0-0.007 0-1.309-0.673-2.461-1.692-3.128l-0.014-0.009zM19.5 21.75h-14.25v-2.497c0-0.001 0-0.003 0-0.004 0-0.785 0.404-1.477 1.015-1.877l0.009-0.005 4.578-2.976-1.952-3.578c-0.173-0.311-0.274-0.682-0.275-1.077v-3.735c0-2.071 1.679-3.75 3.75-3.75s3.75 1.679 3.75 3.75v0 3.735c-0 0.395-0.102 0.766-0.281 1.089l0.006-0.012-1.952 3.579 4.578 2.976c0.62 0.406 1.024 1.097 1.024 1.882 0 0.001 0 0.003 0 0.004v-0z"
                />
              </svg>
              <div v-if="userLogoDet.logoDet.agencyname">
                {{ userLogoDet.logoDet.agencyname }}
              </div>
            </div>
            <div class="address" v-if="userLogoDet.logoDet.emailId">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                role="img"
                class="c-icon c-icon-custom-size"
                height="42"
              >
                <path
                  d="M12.872 1.201c-0.242-0.175-0.545-0.279-0.872-0.279s-0.63 0.105-0.876 0.282l0.004-0.003-10.378 7.413v14.636h22.5v-14.636zM21.75 18.876l-5.814-3.737 5.814-4.522zM12 2.422l9.301 6.644-6.703 5.213-2.598-1.67-2.598 1.67-6.703-5.213zM2.25 10.617l5.814 4.522-5.814 3.737zM21.75 21.75h-19.5v-1.091l9.75-6.268 9.75 6.268z"
                />
              </svg>
              <div v-if="userLogoDet.logoDet.emailId">
                {{ userLogoDet.logoDet.emailId }}
              </div>
            </div>
          </div>
        </div>

        <div class="body">
          <cLoginInfo @showAlert="showAlert"></cLoginInfo>
        </div>
        <div class="foot">
          <div class="d-flex w-100">
            <!-- <div class="lan-drop">
              <cLanguagePicker
                v-model="language"
                ctrlName="en_name"
                :isShowCode="true"
                :isMultiple="false"
                :isSearchable="true"
                :url="languageListURL"
                rules="_required"
                :selValue="language"
              />
            </div> -->
            <div class="logout" @click="onLogout">
              <div class="d-flex">
                <img
                  src="@imgPath/img/svg/icons/logout.svg"
                  alt
                  height="18px"
                  class="mr-10px"
                />

                <!-- @imgPath/img/svg/icons/logout.svg -->
                <!--                <svg-->
                <!--                  xmlns="http://www.w3.org/2000/svg"-->
                <!--                  viewBox="0 0 24 24"-->
                <!--                  role="img"-->
                <!--                  class="c-icon c-icon-custom-size"-->
                <!--                  height="42"-->
                <!--                >-->
                <!--                  <path-->
                <!--                    d="M18 9.375v-2.625c0-3.314-2.686-6-6-6s-6 2.686-6 6v0 2.625h-1.875v6c0 4.342 3.533 7.875 7.875 7.875s7.875-3.533 7.875-7.875v-6zM7.5 6.75c0-2.485 2.015-4.5 4.5-4.5s4.5 2.015 4.5 4.5v0 2.625h-9zM18.375 15.375c0 3.515-2.86 6.375-6.375 6.375s-6.375-2.86-6.375-6.375v-4.5h12.75z"-->
                <!--                  />-->
                <!--                </svg>-->
                <div>{{ $t("userInfo.Logout") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CDropdownHeader>
  </div>
</template>
<script>
import base from "../../config/base";
import { mapActions, mapGetters } from "vuex";
import { PATHNAME, COMPONENT_NAME } from "../../util/constants";
import Logger from "../../services/logger";
import SERVICE_URL, { LOG_TYPE } from "../../config/API_DATA";
import { navigate } from "../../router/index";
import cLanguagePicker from "../../components/cLanguagePicker";
import apiLogOut from "../../services/apiLogOut";
import store from "../../store";
import BaseConfiguration from "../../config/base";
import cLoginInfo from "../../components/cLoginInfo";
import Avatar from "vue-avatar";
import { clearStore } from "../../store/clearStore";
import { removeB2BMFfromSession } from "../../applocalstorage/localStorage";

export default {
  name: COMPONENT_NAME.C_USER_INFO,
  components: {
    cLanguagePicker,
    cLoginInfo,
    //avatar
    Avatar,
  },
  data() {
    return {
      version: "v" + base.version + " " + (base.isDebug ? "dev" : ""),
      minimize: false,
      nav: [],
      show: "responsive",
      isShowAlert: false,
      alertValue: null,

      //start
      showUserInfo: false,
      language: [
        {
          langcode: null,
          en_name: null,
          native_name: null,
        },
      ],
      languageListURL: SERVICE_URL.LANGUAGE_LIST,
      itemsCount: 42,
      balance: {
        1: "PrePaid",
        2: "PostPaid",
      },
      isShowLoginInfo: false,
      userLogoDet: {
        logoDet: null,
        // b2bCompanyFinancess: []
      },
      //end
    };
  },
  computed: {
    ...mapGetters("sMenus", {
      GET_ALL_MENUS_RES: "GET_ALL_MENUS_RES",
    }),
    ...mapGetters("sCurrencyLs", {
      GET_ORG_CURRENCY_FORMAT_RES: "GET_ORG_CURRENCY_FORMAT_RES",
    }),
    getUserName: function () {
      let retValue;
      try {
        if (
          this.userLogoDet.logoDet &&
          this.userLogoDet.logoDet.userName &&
          this.userLogoDet.logoDet.lastName
        ) {
          retValue =
            this.userLogoDet.logoDet.userName +
            " " +
            this.userLogoDet.logoDet.lastName;
        } else if (
          this.userLogoDet.logoDet &&
          this.userLogoDet.logoDet.userName
        ) {
          retValue = this.userLogoDet.logoDet.userName;
        } else {
          retValue = "";
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_USER_INFO,
          "error in getting user name",
          err.toString(),
          "getUserName()",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    ...mapGetters("sSysLogin", {
      GET_ADMIN_SIGNIN_REQ: "GET_ADMIN_SIGNIN_REQ",
      GET_ADMIN_SIGNIN_RES: "GET_ADMIN_SIGNIN_RES",
    }),
    ...mapGetters("sDashBoard", {
      userLogoDetail: "GET_AGENT_LOGO_DET_RES",
    }),
  },

  methods: {
    ...mapActions("sMenus", {
      CLEAR_ALL_MENUS_STORE: "CLEAR_ALL_MENUS_STORE",
      SET_ALL_MENUS_REQ: "SET_ALL_MENUS_REQ",
      SET_ALL_MENUS_RES: "SET_ALL_MENUS_RES",
      SET_MENU_COUNT_RES: "SET_MENU_COUNT_RES",
    }),
    ...mapActions("sCurrencyLs", {
      SET_ORG_CURRENCY_FORMAT_RES: "SET_ORG_CURRENCY_FORMAT_RES",
      SET_ORG_CURRENCIES: "SET_ORG_CURRENCIES",
      SET_ORG_CURRENCY_RES: "SET_ORG_CURRENCY_RES",
    }),
    //start
    ...mapActions("sSysLogin", {
      CLEAR_SYS_LOGIN: "CLEAR_SYS_LOGIN",
    }),

    // ...mapActions('sDashBoard',{
    //     SET_AGENT_LOGO_DET_REQUEST:'SET_AGENT_LOGO_DET_REQUEST',
    //     SET_AGENT_LOGO_DET_RESPONSE:'SET_AGENT_LOGO_DET_RESPONSE',
    //    // SET_AGENT_FINANCE_RESPONSE:'SET_AGENT_FINANCE_RESPONSE'
    //     }),

    viewBalance(val) {
      alert(JSON.stringify(val));
    },

    showAlert(val) {
      this.alertValue = val;
    },

    onLogout() {
      try {
        let pathName = null;
        if (store.getters["sSysLogin/GET_ADMIN_SIGNIN_RES"]) {
          pathName = PATHNAME.SYSTEM_LOGIN;
        } else {
          pathName = PATHNAME.SYSTEM_LOGIN;
        }
        removeB2BMFfromSession();

        clearStore();
        apiLogOut.getLogout();
        if (pathName) {
          this.CLEAR_SYS_LOGIN();
          navigate(pathName);
        } else {
          navigate(PATHNAME.SYSTEM_LOGIN);
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_USER_INFO,
          "error in onLogout",
          err.toString(),
          "onLogout",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    //end
  },

  async created() {
    try {
      if (BaseConfiguration.isDebug) console.log("created");
      let logoDetail = null;
      if (this.userLogoDetail) {
        logoDetail = this.userLogoDetail;
      }

      if (logoDetail) {
        this.userLogoDet.logoDet = logoDetail;
      }
    } catch (err) {
      let body = Logger.buildRequest(
        COMPONENT_NAME.C_USER_INFO,
        "error in Created",
        err.toString(),
        "Created()",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
  },
};
</script>