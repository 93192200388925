import Vue from 'vue';
import Vuex from 'vuex';

//Modules
import language from './modules/language';
//pages
//formStore
import sAddUser from './modules/form/sAddUser';
import sManageUsers from './modules/pages/users/sManageUsers';
import sRolesPermissions from './modules/pages/users/sRolesPermissions';
import sSysLogin from './modules/form/login/sSysLogin';
import sCaptcha from './modules/form/captcha/sCaptcha';
import sUserRoles from './modules/form/sUserRoles'
import sChangePassword from './modules/form/sChangePassword';
import sMain from './modules/sMain';
import sMenus from './modules/menus/sMenus'
import sAddDiyPost from './modules/form/sAddDiyPost'
import sManageDiyPost from './modules/pages/diy/sManageDiyPost'
//componentStore
import sCLanguagePicker from './modules/component/sCLanguagePicker';
import sCOrgCurrencyDp from './modules/component/sCOrgCurrencyDp'
import sDashBoard from './modules/pages/dashboard/sDashBoard'
import createPersistedState from "vuex-persistedstate";
import sSettings from "./modules/settings/sSettings"

import sDiyNotification from './modules/pages/diy/sDiyNotification'

Vue.use(Vuex);
import {getClientCode} from './../config/env-config'
import {LOCAL_STORAGE_KEYS} from './../../src/util/constants'
const clientCode = getClientCode()
let adminKey =LOCAL_STORAGE_KEYS.B2B_ADMIN.toString()+clientCode.toString()

const store = new Vuex.Store({
    //plugins: [createPersistedState()],
    plugins: [createPersistedState({
        key: adminKey,
        storage: window.sessionStorage
        // storage: window.localStorage
    })],
    modules: {
        language,
        sAddUser,
        sMain,
        sManageUsers,
        sRolesPermissions,
        sUserRoles,
        sChangePassword,
        sCaptcha,
        sSysLogin,
        sMenus,
        sCLanguagePicker,
        sCOrgCurrencyDp,
        sDashBoard,
        sSettings,
        sAddDiyPost,
        sManageDiyPost,
        sDiyNotification
    }

});

export default store;