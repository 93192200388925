import { FILE_NAME, LOG_TYPE } from './../config/API_DATA'
import BaseConfiguration from "../config/base";
import Logger from "./../services/logger";
import store from './index'
export function clearStore() {
    try {
        if (BaseConfiguration.isDebug) console.log("clearStore");
        store.dispatch("sAddUser/CLEAR_ADD_USER_STORE");
        store.dispatch("sChangePassword/CLEAR_CHANGE_PWD_STORE");
        store.dispatch("sUserRoles/CLEAR_USER_ROLES_STORE");
        store.dispatch("sManageUsers/CLEAR_MANAGE_USERS_STORE");
        store.dispatch("sRolesPermissions/CLEAR_ROLES_PERMISSIONS_STORE");
        store.dispatch("sCLanguagePicker/CLEAR_C_LANGUAGEPICKER_STORE");
        store.dispatch("sSysLogin/CLEAR_SYS_LOGIN");
        store.dispatch("sMenus/CLEAR_ALL_MENUS_STORE");
        store.dispatch('sCOrgCurrencyDp/CLEAR_C_ORG_CURRENCY_STORE')
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.CLEAR_STORE,
            "error in clearStore",
            err.toString(),
            "clearStore",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

}