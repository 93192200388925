<template>
<div>
    <vue-progress-bar v-if="!isMobile">
    </vue-progress-bar>
    
    <ion-progress-bar v-else-if="isMobile && toShowIonPrgrsBar" type="indeterminate"></ion-progress-bar>
     <transition name="page" mode="out-in">
         <router-view>
        </router-view>
  </transition> 
</div>
</template>

<script>
import BaseConfiguration from "./config/base";
import {Event} from "./main.js"

export default {
    name: 'AppTemplate',
    data() {
    return {
        isMobile: BaseConfiguration.isMobile,
        toShowIonPrgrsBar: false
    }
},
 mounted() {
            Event.$on("progrsBarBool",(ptoShowIonProgress) => {
                this.toShowIonPrgrsBar = ptoShowIonProgress;
            });
        },
}
</script>
