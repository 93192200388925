 
var clientCode="az"

var ca =  require("./"+clientCode+"/locales/ca").default;
var en =  require("./"+clientCode+"/locales/en").default;
var nb =  require("./"+clientCode+"/locales/nb").default;



export default {
  en,
  nb,
  ca
};
