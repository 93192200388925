const state = {
    allRolesPermissionsReq: null,
    allRolesPermissionsRes: null,
    updatePermissionsReq: null,
    updatePermissionsRes: null,

};

const getters = {
    GET_ROLES_PERMISSIONS_REQ: (state) => {
        return state.allRolesPermissionsReq;
    },

    GET_ROLES_AND_PERMISSIONS_RES: (state) => {
        return state.allRolesPermissionsRes;
    },
    GET_UPDATE_PERMISSIONS_RES: (state) => {
        return state.updatePermissionsRes;
    },
    GET_UPDATE_PERMISSIONS_REQ: (state) => {
        return state.updatePermissionsReq;
    },



};
const actions = {

    CLEAR_ROLES_PERMISSIONS_STORE: ({ commit }) => {
        commit('clearStore');
    },

    SET_ROLES_PERMISSIONS_REQ: ({ commit }, req) => {
        commit('saveRpReq', req);
    },

    SET_ROLES_PERMISSIONS_RES: ({ commit }, res) => {
        commit('saveRpRes', res);
    },
    SET_UPDATE_PERMISSIONS_REQ: ({ commit }, req) => {
        commit('saveUpdateReq', req);
    },

    SET_UPDATE_PERMISSIONS_RES: ({ commit }, res) => {
        commit('saveUpdateRes', res);
    }
};

const mutations = {
    clearStore: (state) => {
        state.allRolesPermissionsReq = null;
        state.allRolesPermissionsRes = null;
        state.updatePermissionsReq = null;
        state.updatePermissionsRes = null;
    },
    saveRpReq: (state, req) => {
        state.allRolesPermissionsReq = req;
    },

    saveRpRes: (state, res) => {
        state.allRolesPermissionsRes = res;
    },

    saveUpdateReq: (state, req) => {
        state.updatePermissionsReq = req;

    },
    saveUpdateRes: (state, res) => {
        state.updatePermissionsRes = res;

    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};