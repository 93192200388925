const state = {
    changePasswordReq: null,
    changePasswordRes: null,
};

const getters = {
    GET_CHANGE_PWD_REQ: (state) => {

        return state.changePasswordReq;
    },
    GET_CHANGE_PWD_RES: (state) => {
        return state.changePasswordRes;
    }
};


const actions = {

    CLEAR_CHANGE_PWD_STORE: ({ commit }) => {
        commit('clearStore');
    },
    SET_CHANGE_PWD_REQ: ({ commit }, req) => {
        commit('saveReq', req);
    },
    SET_CHANGE_PWD_RES: ({ commit }, res) => {
        commit('saveRes', res);
    }



};

const mutations = {

    clearStore(state) {
        state.changePasswordReq = null;
        state.changePasswordRes = null;
    },

    saveReq(state, req) {
        state.changePasswordReq = req;

    },

    saveRes(state, res) {
        state.changePasswordRes = res;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};