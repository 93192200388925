export default {
    homePage: {
        welcome: "Welcome",
        name: "home"
    },
    resultPage: {
        title: "Results",
        name: "show results"
    },
    addUser: {
        fName: "First Name",
        lName: "Last Name",
        emailId: "Email ID",
        pwd: "Password",
        role: "Role",
        addUser: "Add User",
        addTitle: "Create User",
        addDesc: "This page allows to create a user with organized profile details  provided with the option to set up roles to the user.",
        update: "Update",
        updateTitle: "Edit User ",
        updateDesc: "Edit User page is here to help you in editing details like user's name, email ID or role.",
        cancel: "Cancel",
        invalid: "Invalid User Details",
        addFailed: "Unable to add User",
        ok: "OK",
        addSuccess: "{fname} {lname} was added successfully",
        updateSuccess: "{fname} {lname} was updated successfully",
        agent: 'Agent',
        system: 'System',
        yes: "Yes",
        no: "No",
        confirmUpdate: 'Are you sure want to save the changes for {fname} {lname}?'
    },

    addDiyPost: {
        title: "Title",
        group: "Group",
        category: "Category",
        description: "Description",
        requirement: "Requirement",
        readMore: "Read More",
        videoLink: "Video Link",
        imageLink: "Image Link",
        tag: "Tag",
        pwd: "Password",
        role: "Role",
        addPost: "Add Post",
        addTitle: "Create Post",
        addDesc: "This page allows to create a Post with organized profile details  provided with the option to set up roles to the Post.",
        update: "Update",
        updateTitle: "Edit Post",
        updateDesc: "Edit Post page is here to help you in editing details like Post's name, email ID or role.",
        cancel: "Cancel",
        invalid: "Invalid Post Details",
        addFailed: "Unable to add Post",
        ok: "OK",
        addSuccess: "{fname} {lname} was added successfully",
        updateSuccess: "{fname} {lname} was updated successfully",
        agent: 'Agent',
        system: 'System',
        yes: "Yes",
        no: "No",
        confirmUpdate: 'Are you sure want to save the changes for {fname} {lname}?'
    },

    addNotification: {
        title: "Title",
        category: "Category",
        pushMessage: "Push Message",
        action: "Action",
        readMore: "Read More",
        videoLink: "Video Link",
        imageLink: "Image Link",
        tag: "Tag",
        pwd: "Password",
        role: "Role",
        addPost: "Add Post",
        addTitle: "Create Post",
        addDesc: "This page allows to create a Post with organized profile details  provided with the option to set up roles to the Post.",
        update: "Update",
        updateTitle: "Edit Post",
        updateDesc: "Edit Post page is here to help you in editing details like Post's name, email ID or role.",
        cancel: "Cancel",
        invalid: "Invalid Post Details",
        addFailed: "Unable to add Post",
        ok: "OK",
        addSuccess: "{fname} {lname} was added successfully",
        updateSuccess: "{fname} {lname} was updated successfully",
        agent: 'Agent',
        system: 'System',
        yes: "Yes",
        no: "No",
        confirmUpdate: 'Are you sure want to save the changes for {fname} {lname}?'
    },

    userStatus: {
        agencyName: 'Agency Name',
        firstName: "First Name",
        lastName: "Last Name",
        backBtn: "Go Back",
        title: "Manage Users ",
        desc: ' Manage Users lists details of the users of the account along with options to reset password, manage their status in handling the account.',
        name: "Name",
        emailId: "Email ID",
        pwd: "Password",
        role: "Role",
        update: 'Update',
        status: 'Status',
        currentStatus: "Current Status",
        inactive: 'Inactive',
        active: "Active",
        notActive: "Not Active",
        activate: "Activated",
        deactivate: "Deactivated",
        reset: "Reset Password",
        cancel: "Cancel",
        ok: "OK",
        activateSuccess: "{fname} {lname} activated successfully",
        deactivateSuccess: "{fname} {lname} deactivated successfully",
        yes: "Yes",
        no: 'No',
        confirmUpdate: 'Are you sure want to update the changes for {fname} {lname}?',
        mailSuccess: "Password Reset link has been sent to {email}. Please check your inbox.",
        pwResetConfirmation: "Are you sure you want to reset password for {email}?"
    },

    manageUser: {
        totalUsers: 'Total Users',
        active: 'Active',
        inactive: 'Inactive',
        newUser: "New User",
        edit: "Edit",
        listEmpty: "No Users Found",
        desc: 'Users page helps you to build and manage the profile of the users who use the agent login account.',
        manage: "Manage",
        name: "Name",
        emailId: "Email ID",
        actions: "Actions",
        status: 'Status',
        role: "Role",
        agencyName: 'Agency Name',
        title: "Users",
        clearFilter: 'Clear All Filter',
        admindesc: 'Users page helps you to build and manage the profile of the users who use the Agent Login and System Login accounts.'
    },

    notification: {
        addNotification: "Add Notification",
        active: 'Active',
        inactive: 'Inactive',
        edit: "Edit",
        listEmpty: "No Users Found",
        desc: 'List of diy app Notifications',
        manage: "Manage",
        postImage: "Post Image",
        emailId: "Email ID",
        category:"Category",
        tag: "Tag",
        title:"Title",
        author: "Author",
        actions: "Actions",
        status: 'Status',
        publishDate: "Publish Date",
        agencyName: 'Agency Name',
        pageTitle: "List Of Notification",
        clearFilter: 'Clear All Filter',
        admindesc: 'List of diy app Notification'
    },

    diy_all_post: {
        totalUsers: 'Total Users',
        active: 'Active',
        inactive: 'Inactive',
        addPost: "Add post",
        edit: "Edit",
        listEmpty: "No Users Found",
        desc: 'List of diy app posts',
        manage: "Manage",
        postImage: "Post Image",
        emailId: "Email ID",
        category:"Category",
        tag: "Tag",
        title:"Title",
        author: "Author",
        actions: "Actions",
        status: 'Status',
        publishDate: "Publish Date",
        agencyName: 'Agency Name',
        pageTitle: "List Of Post",
        clearFilter: 'Clear All Filter',
        admindesc: 'List of diy app posts'
    },

    rolesPermissions: {
        title: 'Roles & Permissions',
        // desc: ' It helps to, you can create role and assign the permissions to users',
        listEmpty: 'Please select role to continue',
        ok: "OK",

        select: "Select Role",
        func: 'Function',
        save: 'Save',
        R: "Read",
        W: 'Write',
        D: 'Delete',
        A: 'Approve',
        Y: 'Yes',
        roleName: "Role Name",
        addRolePlaceholder: "Please enter role name",
        isAgentRole: "Is Agent Role?",
        yes: "Yes",
        no: "No",
        confirmUpdate: "Are you sure want to update the changes for {roleName} role?",
        success: "Permissions updated successfully",
        desc: 'Roles & Permissions page allows to manage the assigned roles for every single user who have access to the account.'

    },






    langTest: 'Welcome to Your Multi Language App',
    language_picker_helper: {
        language_picker_helper: 'Choose a language',
    },
    languages: {
        english: 'English',
        norwegian: 'Norwegian',
    },
    loginPg: {
        loginTitle: "Agent Login",
        fillIn: 'Fill in your information and create an account.',
        emailPl: "Enter email id",
        pwdPl: "Enter password",
        frgtPwd: "Forgot Password ?",
        login: "Login",
        email: 'Email ID',
        pwd: 'Password',
        noAcct: "Don't have an account?",
        reg: "Register Here",
        invalid: "Invalid data",
        cptPl: "Add the captcha code you see below",
        jwtExpiredValue: 'Your session has expired. Please enter your email/password again to continue.'
    },
    sysLogin: {
        loginTitle: "System Login",
        desc: 'Contact your System Admin for further assistance.',
        emailPl: "Enter email id",
        email: 'Email ID',
        pwd: 'Password',
        pwdPl: "Enter password",
        frgtPwd: "Forgot Password ?",
        login: "Login",
        noAcct: "Don't have an account?",
        reg: "Register Here",
        invalid: "Invalid data"
    },
    forgtPwd: {
        frgtPwdTxt: "Forgot your Password ?",
        enterMail: "Enter your Email ID below, we will send password reset link to your mail",
        fgMailPl: "Enter your email id",
        pwdResetTxt: "Link to change your password have sent to ",
        successfully: "successfully!",
        resetPwd: "Reset Password",
        resetTxt: "Check your mail. If you not receive password reset link , please retry again.",
        mailSuccess: "Email was sent successfully! Please check your inbox.",
        backTo: "Back to ",
        login: "Login"
    },
    chngPwd: {
        title: "Change Password",
        desc: "Change your account's password here, periodically, to ensure both data and account's safety. Make sure your password is strong to ensure strong security.",
        currPwd: "Current Password",
        currPwdPl: "Enter your current password",
        newPwd: "New Password",
        newPwdPl: "Enter new password",
        cnfrmPwd: "Confirm Password",
        cnfrmPl: "Enter confirm password",
        // chngPwd: "Change Password",
        chngPwd: "Confirm",
        cancel: "Cancel",
        ok: "OK",
        alert: "Password changed successfully",
        toolTip: "Minimum 8, Maximum 20 characters, Atleast 1 special character, Atleast 1 capital letter, Atleast 1 digit"
    },

    notFoundErr: {
        go: "Go Home",
        title: '404',
        subtitle: "Oops! You're lost",
        desc: 'The page you are looking for was not found.',
    },
    tempErr: {
        go: "Go Home",
        title: '500',
        subtitle: "Houston, we have a problem!",
        desc: 'The page you are looking for is temporarily unavailable.',
    },

    jsonExcelModal: {
        cancel: 'Cancel',
        download: 'Download',
        desc: 'Do you want to download {name} as Excel File'
    },



    cSortDropDown: {
        select: "Select",
    },
    cFilter: {
        filter: "Filter",
        clearAll: "Clear All",
        clear: "Clear",
        time: "Time",
        price: "Price",
        stop: "Stops",
        allStops: 'All Stops',
        nonStop: 'Non-Stop',
        duration: 'Duration',
        departureTimes: 'Departure Times',
        airlines: 'Airlines',
        more: 'More',
        less: 'Less',
        priceFilter: "Price Filter",
        durFilter: "Duration Filter",
        stopFilter: "Stop Filter",
        alFilter: "Airline Filter",
        timeFilter: "Departure Time Filter"

    },



    header: {
        dashboard: 'Dashboard',
        mybooking: 'My Bookings',
        ticktQue: 'Ticketing Queue',
        search: 'Search'
    },


    cTC: {
        newTab: 'New Tab'
    },


    reserPassword: {
        desc: 'Contact your System Admin for further assistance.',
        title: 'Reset Password',
        "newPwd": "New Password",
        "confrmPwd": "Confirm Password",
        "newPwdPl": "Enter new password",
        "confrmPwdPl": "Confirm password",
        submit: 'Submit',
        resetSuccess: 'Password Reseted Successfully',
        misMatch: 'Password mismatched',
        ok: 'OK'
    },

    dashBoard: {
        title: 'Dashboard',
        desc: 'Dashboard  is a platform designed to help you in monitoring the status, progress and performance of your account / webpage, at a glance.',

    },

    documentTitle: {
        system: "System Portal - B2B Flight Engine",
        agent: "Agent Portal - B2B Flight Engine"
    },
    cLoginInfo: {
        pendingBooking: "Pending Booking",
        noPendingBooking: "No Pending Booking",
        pendingBookings: "Pending Bookings",
        availBalance: "Available Limit",
        availCreditLimit: "Available Limit"
    },

    cDataTable: {
        itemsPerPage: 'Items per page',
        cPaginationLenOption: {
            to: 'to',
            of: 'of'
        }
    },

    index: {
        leave: 'Leave',
        stay: 'Stay',
        leaveCnfrmTxt: '<stong>Are you sure you want to leave this page? </stong><div> You have unsaved changes. </div>'
    },

    userInfo: {
        Logout: 'Logout'
    },
    excel: {
        dropExcelFiles: 'Drop excel file here or',
        browse: 'Browse',
        continue: 'Continue',
        okBtn: 'OK',
        close: 'Close',
        supportOneFile: 'Only support uploading one file!',
        onlySupportFormats: 'Only supports upload .xlsx, .xls, .csv suffix files',

    },











    helpTip: {
        ticketingQue_resId: 'Click to view in latest / oldest on top',
        approveAgtCrdtlimit: 'Change credit limit',
        approveAgtSchedule: 'Schedule frequency of payment day(s)',
        approveAgtExtensionTime: 'Number of times to extend an unpaid statement',
        addUserSys: 'Select to add new user to System Login',
        addUserAgt: 'Select to add new user to Agency Login',
        userStatusResetpwd: 'A link will be sent to the registered Email, to approve password reset.',
        cAgtDtlAssTier: 'Click to add/deactivate a tier',
        manageExTime: 'Number of times to extend a statement',
        manageCrdtLmt: "This is the Maximum Limit of amount to credit. If the limit's reached, clear the pending dues to proceed with booking",
        rolesPermissionsRead: 'Read-only option for the role',
        rolesPermissionsApprove: 'Permission to approve listed role',
        rolesPermissionsWrite: 'Add & Edit option for the role',
        rolesPermissionsDelete: 'Permission to delete listed role',
        setupPricingClone: 'Make a copy of the tier',
        cBkngDtlTcktQue: 'Number of tickets in queue for the agency',
        cBkngDtlIssuedBook: 'Number of issued bookings for the current calendar month',
        chngPswd: 'MUST : Characters : 8-20, 1 special character, 1 digit, 1 Capital Letter',
        cSearchPassngr: 'Atleast 1 passenger must be above 16 years of age. Atleast 1 adult should accompany  an infant.',
        crsFlightScheduleNewType: 'If the days & dates are left unselected in Weekly & Monthly, all the days & dates will be selected'

    },
    dialog: {

        jwtSession: {
            title: 'Session Expired',
            okBtn: 'Ok'
        }
    },

    noMatchFound: {
        noMatchFound: 'No Match Found'
    },

}