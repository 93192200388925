<template>
  <ion-menu
    menu-id="custom"
    type="overlay"
    id="mysidebar"
    fixed
    :minimize="minimize"
    :show.sync="show"
    side="start"
    content-id="main-content"
  >
    <ion-content v-if="nav && nav.length != 0">
      <div class="c-sidebar-brand">
        <a rel="noopener">
          <span class="side-bar-brand-logo-full"></span>
          <span class="side-bar-brand-logo-minimized"></span>
        </a>
      </div>
      <span class="brand-logo-mob c-header-brand mx-auto d-lg-none"></span>
      <div v-for="(items, index) in nav[0]._children" :key="index + 'sideBar'">
        <ion-menu-toggle v-if="items._name == 'CSidebarNavItem'">
          <ion-item>
            <span @click="goTo(items.to)">{{ items.name }}</span>
          </ion-item>
        </ion-menu-toggle>

        <ion-item v-else @click="items.isOpen = !items.isOpen">
          <ion-icon
            slot="start"
            name="arrow-forward"
            v-if="!items.isOpen"
          ></ion-icon>
          <ion-icon
            slot="start"
            name="arrow-down"
            v-if="items.isOpen"
          ></ion-icon>
          <span>{{ items.name }}</span>
        </ion-item>

        <ion-list v-if="items.items && items.items.length > 0 && items.isOpen">
          <div
            v-for="(children, cindex) in items.items"
            :key="cindex + 'submenu'"
          >
            <ion-menu-toggle>
              <ion-item @click="goTo(children.to)">
                {{ children.name }}
              </ion-item>
            </ion-menu-toggle>
          </div>
        </ion-list>
      </div>
    </ion-content>
  </ion-menu>
</template>
<script>
import { navigate } from "../../router/index";
export default {
  name: "aSideMenu",
  props: {
    nav: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object | Array,
      default: null,
    },
    minimize: {
      type: Boolean,
      default: false,
    },
    isFixedHeader: {
      type: Boolean,
      default: false,
    },
    show: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Boolean,
      default: "responsive",
    },
  },
  components: {},
  methods: {
    goTo(url) {
      navigate(url);
    },
  },
};
</script>