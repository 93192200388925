const state = {

    allMenusReq: null,
    allMenusRes: null,
    menuCountRes: null

};


const getters = {
    GET_ALL_MENUS_REQ: (state) => {
        return state.allMenusReq;
    },
    GET_ALL_MENUS_RES: (state) => {
        return state.allMenusRes;
    },
    GET_MENU_COUNT_RES: (state) => {
        return state.menuCountRes;
    }
};


const actions = {
    CLEAR_ALL_MENUS_STORE: ({ commit }) => {
        commit('clearStore')
    },
    SET_ALL_MENUS_REQ: ({ commit }, req) => {
        commit('saveReq', req);
    },
    SET_ALL_MENUS_RES: ({ commit }, res) => {
        commit('saveRes', res);
    },
    SET_MENU_COUNT_RES: ({ commit }, res) => {
        commit('saveMenuCountRes', res);
    }
};


const mutations = {

    clearStore: (state) => {
        state.allMenusReq = null
        state.allMenusRes = null
        state.menuCountRes = null
    },
    saveReq: (state, req) => {
        state.allMenusReq = req;
    },

    saveRes: (state, res) => {
        state.allMenusRes = res;
    },

    saveMenuCountRes: (state, res) => {
        state.menuCountRes = res;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}