export class UI_CONTROL {
    static PROGRESS = {
        func: [
            { call: 'color', modifier: 'temp', argument: '#ffb300' },
            { call: 'fail', modifier: 'temp', argument: '#6e0000' },
            { call: 'location', modifier: 'temp', argument: 'top' },
            { call: 'transition', modifier: 'temp', argument: { speed: '0.3s', opacity: '0.6s', termination: 400 } }
        ]
    };

    static PROGRESS_OPTIONS = {
        color: '#41B883',
        failedColor: 'red',
        thickness: '20px',
        transition: {
            speed: '1s',
            opacity: '0.6s',
            termination: 300
        },
        autoRevert: true,
        location: 'top',
        inverse: false
    }


}