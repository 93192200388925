import Logger from "../services/logger.js";
import SERVICE_URL, {
    FILE_NAME,
    LOG_TYPE,
    HTTP_CODES,
    API_VALUES
} from "../config/API_DATA";
import Router, {
    navigate
} from "../router/index";
import store from "../store";
import base from "./../config/base";
import {
    PATHNAME
} from "../util/constants.js";
import {
    getLogCountFromLocal,
    setLogCountToLocal,
    getMultipleErrFromLocal,
    setMultipleErrToLocal,
    clearLogCountFromLocal,
    clearMulErrFromLocal,
    getSysLoginResFromLocal
} from './../applocalstorage/localStorage'
import BaseConfiguration from "./../config/base";
import {
    httpClient
} from "./httpClient.js"
import HttpUI from "./httpUI.js"
export default class HttpPost {
    static logCount = 0;
    static preventMultipleLog(pError) {
        let returnValue;
        try {
            if (base.isDebug) console.log("preventMultipleLog")
            let localLogCount = getLogCountFromLocal()
            if (localLogCount != null) {
                this.logCount = parseInt(
                    localLogCount
                );
                this.logCount++;
            } else {
                this.logCount = 0;
            }
            setLogCountToLocal(this.logCount);
            if (this.logCount >= 3) {
                returnValue = true;
                let multipleError = Logger.buildRequest(
                    FILE_NAME.HTTPOST,
                    "Error Occurred Multiple Times",
                    pError.toString(),
                    "preventMultipleLog",
                    LOG_TYPE.ERROR
                );
                setMultipleErrToLocal(JSON.stringify(multipleError))
                if (Router.currentRoute.path != PATHNAME.PAGE_500) {
                    navigate(PATHNAME.PAGE_500)
                }
            }
        } catch (err) {
            if (Router.currentRoute.path != PATHNAME.PAGE_500) {
                navigate(PATHNAME.PAGE_500)
            }
        }

        return returnValue;
    }

    static async makeHttpPostRequest(pUrl, pRequestBody) {
        let returnValue = null;

        try {
            let pHeader;

            let langCode = store.getters["language/GET_LANGUAGE"];
            let orgId = store.getters["sSysLogin/GET_ORGID"];
            if (
                pUrl == SERVICE_URL.GET_CAPTCHA ||
                pUrl == SERVICE_URL.GET_ADMIN_SIGNIN_STATUS
            )
                pHeader = {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Accept-Language": langCode,
                    "orgid": orgId
                };
            //services without session
            else {
                let jwtToken = null;
                let sysLogToken = getSysLoginResFromLocal();
                // let token = agtLogToken ? agtLogToken : sysLogToken ? sysLogToken : null;
                let token = (sysLogToken ? sysLogToken : null);
                if (token) {
                    jwtToken = JSON.parse(token);
                    jwtToken =
                        (jwtToken && jwtToken.authorization) ? jwtToken.authorization : null;
                }

                pHeader = {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: jwtToken,
                    "Accept-Language": langCode,
                    "orgid": orgId
                }; //services with session
            }
            httpClient.defaults.headers = pHeader;
            // let httpClient = axios.create({
            //    

            //     timeout: base.timeout,
            //     headers: pHeader
            // });
            if (BaseConfiguration.isMobile) {
                var AppHttpUI = require("../mobile/http/aHttpUI").default;
                AppHttpUI.UIprocess();
            } else {
                HttpUI.UIprocess();
            }

            //to check  network connection
            if (navigator.onLine) {
                returnValue = await httpClient
                    .post(pUrl, pRequestBody)
                    .then(response => {
                        return response;
                    })
                    .catch(error => {
                        if (this.preventMultipleLog(error)) return null;
                        else if (pUrl != SERVICE_URL.LOGGER_URL) {
                            // Router.push("pError");
                            let err = {
                                service: pUrl,
                                error: error.toString()
                            }
                            let body = Logger.buildRequest(
                                FILE_NAME.HTTPOST,
                                "error in making post request",
                                JSON.stringify(err),
                                "makeHttpPostRequest",
                                LOG_TYPE.ERROR
                            );
                            Logger.getResponse(body);
                        }
                    });
            } else {
                throw HTTP_CODES.ERROR_NET;
            }
        } catch (err) {
            // Router.push("pError");
            if (err == HTTP_CODES.ERROR_NET || !navigator.onLine) {
                if (Router.currentRoute.path != PATHNAME.PAGE_404) {


                    navigate(PATHNAME.PAGE_404)
                }
            } else {
                if (this.preventMultipleLog(err)) {
                    return null;
                } else {

                    let error = {
                        service: pUrl,
                        error: err.toString()
                    }
                    let body = Logger.buildRequest(
                        FILE_NAME.HTTPOST,
                        "httppost failed",
                        JSON.stringify(error),
                        "makeHttpPostRequest",
                        LOG_TYPE.ERROR
                    );
                    Logger.getResponse(body);
                }
            }
        } finally {
            if (returnValue && returnValue.status == HTTP_CODES.SUCCESS) {
                this.logCount = 0;
                clearLogCountFromLocal();
                let multipleError = getMultipleErrFromLocal()
                if (multipleError) {
                    Logger.getResponse(JSON.parse(multipleError));

                    clearMulErrFromLocal()

                }
            }
        }
        return returnValue;
    }

}