import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '../translations';

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'nb',
  fallbackLocale: 'nb',

  messages,
  silentTranslationWarn: true,missing:(locale, key, vm, values) => {
    // handle translation missing

    if (i18n.te(key, i18n.fallbackLocale)) {
      return i18n.t(key, i18n.fallbackLocale).toString();
      }
      
      return ''; 
    
              

  
     
  }

  
},
// {
//   locale: 'ca',
//   fallbackLocale: 'ca',
//   messages,
// },
);
