import Logger from "./../services/logger";
import {
    FILE_NAME,
    LOG_TYPE
} from "../config/API_DATA";

import {
    LOCAL_STORAGE_KEYS
} from "./../util/constants";
import BaseConfiguration from "./../config/base";
import {
    getClientCode
} from './../config/env-config'
import store from "../store";

export function forceClearLocalStorage() {
    try {
        if (BaseConfiguration.isDebug) console.log("forceClearLocalStorage");
        let forceClearVersion = localStorage.getItem(LOCAL_STORAGE_KEYS.FORCE_CLEAR_VERSION);

        if (BaseConfiguration.isForceClearLocalStorage && forceClearVersion != BaseConfiguration.version) {
            localStorage.clear();
            localStorage.setItem(LOCAL_STORAGE_KEYS.FORCE_CLEAR_VERSION, BaseConfiguration.version);
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in forceClearLocalStorage",
            err.toString(),
            "forceClearLocalStorage",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}


export function setLangToLocal(pLang) {
    try {
        if (BaseConfiguration.isDebug) console.log("setLangToLocal");

        localStorage.setItem(LOCAL_STORAGE_KEYS.LANG, pLang);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in setLangToLocal",
            err.toString(),
            "setLangToLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function getLangFromLocal() {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("getLangFromLocal");

        retValue = localStorage.getItem(LOCAL_STORAGE_KEYS.LANG);
        if (!retValue) {
            var myLang = window.location.href.split('lang=')[1];
            if (myLang == "ca" || myLang == "en") { //check valid lang code. - need to validate with lang res. will do it later
                retValue = myLang;
                BaseConfiguration.defaultLanguageCode = myLang;
            }
        }

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in getLangFromLocal",
            err.toString(),
            "getLangFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return retValue;
}

export function clearLangFromLocal() {
    try {
        if (BaseConfiguration.isDebug) console.log("clearLangFromLocal");

        localStorage.removeItem(LOCAL_STORAGE_KEYS.LANG);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in clearLangFromLocal",
            err.toString(),
            "clearAgtLoginResFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function setAgtLoginResToLocal(pRes) {
    try {
        if (BaseConfiguration.isDebug) console.log("setAgtLoginResToLocal");
        let storageKey = LOCAL_STORAGE_KEYS.agent_jwt + getUserId();
        localStorage.setItem(storageKey, pRes);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in setAgtLoginResToLocal",
            err.toString(),
            "setAgtLoginResToLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function clearAgtLoginResFromLocal() {
    try {
        if (BaseConfiguration.isDebug) console.log("clearAgtLoginResFromLocal");
        // localStorage.clear();
        sessionStorage.clear();
        let storageKey = LOCAL_STORAGE_KEYS.agent_jwt + getUserId();
        localStorage.removeItem(storageKey);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in clearAgtLoginResFromLocal",
            err.toString(),
            "clearLangFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function clearSysLoginResFromLocal() {
    try {
        if (BaseConfiguration.isDebug) console.log("clearSysLoginResFromLocal");
        // localStorage.clear();
        sessionStorage.clear();
        let storageKey = LOCAL_STORAGE_KEYS.sys_jwt + getUserId();
        localStorage.removeItem(storageKey);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in clearSysLoginResFromLocal",
            err.toString(),
            "clearSysLoginResFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function setSysLoginResToLocal(pRes) {
    try {
        if (BaseConfiguration.isDebug) console.log("setSysLoginResToLocal");
        let storageKey = LOCAL_STORAGE_KEYS.sys_jwt + getUserId();
        localStorage.setItem(storageKey, pRes);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in setSysLoginResToLocal",
            err.toString(),
            "setSysLoginResToLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function getSysLoginResFromLocal() {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("getSysLoginResFromLocal");
        let storageKey = LOCAL_STORAGE_KEYS.sys_jwt + getUserId();
        retValue = localStorage.getItem(storageKey);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in getSysLoginResFromLocal",
            err.toString(),
            "getSysLoginResFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return retValue;
}

export function setFlightSearchHisToLocal(pSearchHis, pUserId) {
    try {
        if (BaseConfiguration.isDebug) console.log("setFlightSearchHisToLocal");
        let storageKey = LOCAL_STORAGE_KEYS.FLIGHT_SEARCH_HISTORY + pUserId;
        localStorage.setItem(storageKey, pSearchHis);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAL_STORAGE,
            "error in setFlightSearchHisToLocal",
            err.toString(),
            "setFlightSearchHisToLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}
export function setManageTableColumns(pLsManageColumns, pUserId) {
    try {
        if (BaseConfiguration.isDebug) console.log("setManageTableColumns");
        let storageKey = LOCAL_STORAGE_KEYS.LIST_MANAGE_COLUMNS + pUserId;
        localStorage.setItem(storageKey, pLsManageColumns);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAL_STORAGE,
            "error in setManageTableColumns",
            err.toString(),
            "setManageTableColumns",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function getFlightSearchHisFromLocal(pUserId) {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("getFlightSearchHisFromLocal");
        let storageKey = LOCAL_STORAGE_KEYS.FLIGHT_SEARCH_HISTORY + pUserId;
        retValue = localStorage.getItem(storageKey);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAL_STORAGE,
            "error in getFlightSearchHisFromLocal",
            err.toString(),
            "getFlightSearchHisFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}
export function getManageColumnsListFromLocal(pUserId) {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("getManageColumnsListFromLocal");
        let storageKey = LOCAL_STORAGE_KEYS.LIST_MANAGE_COLUMNS + pUserId;
        retValue = localStorage.getItem(storageKey);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAL_STORAGE,
            "error in getManageColumnsListFromLocal",
            err.toString(),
            "getManageColumnsListFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function setMultipleErrToLocal(pError) {
    try {
        if (BaseConfiguration.isDebug) console.log("setMultipleErrToLocal");
        localStorage.setItem(LOCAL_STORAGE_KEYS.MULTIPLE_ERROR, pError);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in setMultipleErrToLocal",
            err.toString(),
            "setMultipleErrToLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function getMultipleErrFromLocal() {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("getMultipleErrFromLocal");
        retValue = localStorage.getItem(LOCAL_STORAGE_KEYS.MULTIPLE_ERROR);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in getMultipleErrFromLocal",
            err.toString(),
            "getMultipleErrFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function clearMulErrFromLocal() {
    try {
        if (BaseConfiguration.isDebug) console.log("clearMulErrFromLocal");
        localStorage.removeItem(LOCAL_STORAGE_KEYS.MULTIPLE_ERROR);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in clearMulErrFromLocal",
            err.toString(),
            "clearMulErrFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function setLogCountToLocal(pCount) {
    try {
        if (BaseConfiguration.isDebug) console.log("setLogCountToLocal");
        localStorage.setItem(LOCAL_STORAGE_KEYS.LOG_COUNT, pCount);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in setLogCountToLocal",
            err.toString(),
            "setLogCountToLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function getLogCountFromLocal() {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("getLogCountFromLocal");
        retValue = localStorage.getItem(LOCAL_STORAGE_KEYS.LOG_COUNT);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in getLogCountFromLocal",
            err.toString(),
            "getLogCountFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function clearLogCountFromLocal() {
    try {
        if (BaseConfiguration.isDebug) console.log("clearLogCountFromLocal");
        localStorage.removeItem(LOCAL_STORAGE_KEYS.LOG_COUNT);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in clearLogCountFromLocal",
            err.toString(),
            "clearLogCountFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

/* export function getB2bMFVersionFromLocal() {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("getB2bMFVersionFromLocal");
        retValue = localStorage.getItem(LOCAL_STORAGE_KEYS.B2B_MF_VERSION);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in getB2bMFVersionFromLocal",
            err.toString(),
            "getB2bMFVersionFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function setB2bMFVersionToLocal(pVersion) {
    try {
        if (BaseConfiguration.isDebug) console.log("setB2bMFVersionToLocal");
        localStorage.setItem(LOCAL_STORAGE_KEYS.B2B_MF_VERSION, pVersion);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in setB2bMFVersionToLocal",
            err.toString(),
            "setB2bMFVersionToLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
} */







export function getB2BVersionFromLocal() {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("getB2BVersionFromLocal");


        let clientCode = getClientCode()
        if (clientCode) {
            let key = LOCAL_STORAGE_KEYS.B2B_VERSION.toString() + clientCode.toString();
            retValue = localStorage.getItem(key, );
        } else {
            throw 'invalid client code'
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in getB2BVersionFromLocal",
            err.toString(),
            "getB2BVersionFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function setB2bVersionToLocal(pVersion) {
    try {
        if (BaseConfiguration.isDebug) console.log("setB2bVersionToLocal");
        let clientCode = getClientCode()
        if (clientCode) {
            let key = LOCAL_STORAGE_KEYS.B2B_VERSION.toString() + clientCode.toString();
            localStorage.setItem(key, pVersion);
        } else {
            throw 'invalid client code'
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in setB2bVersionToLocal",
            err.toString(),
            "setB2bVersionToLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function getUserId() {
    let userId = "";
    try {
        if (BaseConfiguration.isDebug) console.log("getUserId");

        let clientCode = getClientCode();
        let locUserId;

        let key = LOCAL_STORAGE_KEYS.B2B_USER_ID.toString() + clientCode.toString();
        locUserId = localStorage.getItem(key);


        if (!locUserId)
            locUserId = store.getters["sDashBoard/GET_USER_ID"];
        if (locUserId) {
            userId = locUserId;
            setUserId(clientCode, userId)
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in getUserId",
            err.toString(),
            "getUserId",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return userId;
}


export function setUserId(pClientCode, pUserId) {
    try {
        if (BaseConfiguration.isDebug) console.log("setUserId");
        let key = LOCAL_STORAGE_KEYS.B2B_USER_ID.toString() + pClientCode.toString();




        localStorage.setItem(key, pUserId);






    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in setUserId",
            err.toString(),
            "setUserId",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

}



export function removeB2BMFfromSession() {
    try {
        if (BaseConfiguration.isDebug) console.log("removeB2BMFfromSession");

        let clientCode = getClientCode()

        let adminKey = LOCAL_STORAGE_KEYS.B2B_ADMIN.toString() + clientCode.toString()
        sessionStorage.removeItem(adminKey);




    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in removeB2BMFfromSession",
            err.toString(),
            "removeB2BMFfromSession",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

}

export function setSelRptTypeFromLocal(pReportType) {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("setSelRptTypeFromLocal");
        retValue = localStorage.setItem(LOCAL_STORAGE_KEYS.REPORT_TYPE, pReportType);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in setSelRptTypeFromLocal",
            err.toString(),
            "setSelRptTypeFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function getSelRptTypeFromLocal() {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("getSelRptTypeFromLocal");
        retValue = localStorage.getItem(LOCAL_STORAGE_KEYS.REPORT_TYPE);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in getSelRptTypeFromLocal",
            err.toString(),
            "getSelRptTypeFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function setIsIntroVisited(pisIntroVisited) {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("setIsIntroVisited");
        retValue = localStorage.setItem(LOCAL_STORAGE_KEYS.IS_INTRO_VISITED, pisIntroVisited);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in setIsIntroVisited",
            err.toString(),
            "setIsIntroVisited",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function getIsIntroVisited() {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("getIsIntroVisited");
        retValue = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_INTRO_VISITED);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in getIsIntroVisited",
            err.toString(),
            "getIsIntroVisited",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function setHotelSearchHisToLocal(pSearchHis, pUserId) {
    try {
        if (BaseConfiguration.isDebug) console.log("setHotelSearchHisToLocal");
        let storageKey = LOCAL_STORAGE_KEYS.HOTEL_SEARCH_HISTORY + pUserId;
        localStorage.setItem(storageKey, pSearchHis);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAL_STORAGE,
            "error in setHotelSearchHisToLocal",
            err.toString(),
            "setHotelSearchHisToLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

export function getHotelSearchHisFromLocal(pUserId) {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("getHotelSearchHisFromLocal");
        let storageKey = LOCAL_STORAGE_KEYS.HOTEL_SEARCH_HISTORY + pUserId;
        retValue = localStorage.getItem(storageKey);
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAL_STORAGE,
            "error in getHotelSearchHisFromLocal",
            err.toString(),
            "getHotelSearchHisFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}



export function getBaseSettFromLocal() {
    let retValue;
    try {
        if (BaseConfiguration.isDebug) console.log("getBaseSettFromLocal");
        retValue = localStorage.getItem(LOCAL_STORAGE_KEYS.BASE_SETTINGS)

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in getBaseSettFromLocal",
            err.toString(),
            "getBaseSettFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return JSON.parse(retValue);
}


export function setBaseSettToLocal(pVal) {
    try {
        if (BaseConfiguration.isDebug) console.log("setBaseSettToLocal");
        localStorage.setItem(LOCAL_STORAGE_KEYS.BASE_SETTINGS, JSON.stringify(pVal))
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in setBaseSettToLocal",
            err.toString(),
            "setBaseSettToLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

}

export function clearBaseSettFromLocal() {
    try {
        if (BaseConfiguration.isDebug) console.log("clearBaseSettFromLocal");

        localStorage.removeItem(LOCAL_STORAGE_KEYS.BASE_SETTINGS)
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.LOCAl_STORAGE,
            "error in clearBaseSettFromLocal",
            err.toString(),
            "clearBaseSettFromLocal",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

}