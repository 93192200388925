<script>
export const EventBus = new Vue();
import Vue from "vue";

import Logger from "../services/logger";
import BaseConfiguration from "../config/base.js";
import { TOAST_TYPE } from "../util/constants";
import { LOG_TYPE, FILE_NAME } from "../config/API_DATA";
export default {
  methods: {
    async showToast(msg, type, duration, action) {
      try {
        if (BaseConfiguration.isDebug) console.log("showToast");
        if (!BaseConfiguration.isMobile) {
          let toastOption = {
            duration: duration,
            position: "bottom-right",
            icon: {
              name: "cil-reload",
              after: true,
            },
            action: action,
          };

          switch (type) {
            case TOAST_TYPE.INFO:
              this.$toasted.info(msg, toastOption);
              break;
            case TOAST_TYPE.SUCCESS:
              this.$toasted.success(msg, toastOption);
              break;
            case TOAST_TYPE.ERROR:
              this.$toasted.error(msg, toastOption);
              break;
            case TOAST_TYPE.SHOW:
              this.$toasted.show(msg, toastOption);
              break;
            case TOAST_TYPE.CLEAR:
              this.$toasted.clear();
              break;
            default:
              break;
          }
        } else {
          if (type != TOAST_TYPE.CLEAR) {
            let toast = await this.$ionic.toastController.create({
              message: msg,
              showCloseButton: false,
              duration: duration,
              position: "bottom",
            });

            await toast.present();
            await setTimeout(() => {
              toast.dismiss();
            }, duration);
          }
        }
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.APP_TOAST,
          "error in showToast",
          err.toString(),
          "showToast",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
</style>
