/* eslint-disable no-unused-vars */
/* eslint-disable no-inner-declarations */
import Vue from "vue";
// import { i18n } from './language/lang'
import("./../assets/" + process.env.VUE_APP_CLIENTCODE + "/scss/style.scss");
import VueRouter from "vue-router";
import {
    i18n
} from "../plugins/i18n.js";
import store from "../store";
import {
    UI_CONTROL
} from "../util/uiControl.js";
import {
    PATHNAME,
    COMPONENT_NAME,

} from "../util/constants";
import apiRenewJWT from "./../services/apiRenewJWT";
import {
    handleJWT,
    assigni18nLocale,
    isHmsFlow
} from "./../util/util";
import {
    removeB2BMFfromSession,
    getSysLoginResFromLocal,
    clearLogCountFromLocal,
    clearAgtLoginResFromLocal,
    clearSysLoginResFromLocal,
    clearBaseSettFromLocal
} from './../applocalstorage/localStorage'
import Logger from "./../services/logger";
import {
    FILE_NAME,
    LOG_TYPE
} from "./../config/API_DATA";

import {
    VALIDATE_SUCCESS_RES,
    getErrorMessage
} from "./../util/apiValidate";
import {
    clearStore
} from './../store/clearStore'

import BaseConfiguration from "../config/base";
import {
    Event
} from "./../main.js";

export const EventBus = new Vue();

// Containers
const TheContainer = () =>
    import("@/containers/TheContainer");



// Views - Pages
const Page404 = () =>
    import("@/pages/error/Page404");
const Page500 = () =>
    import("@/pages/error/Page500");

// Pages
//Booking - Flight
const Dashboard = () =>
    import("@/pages/dashboard/Dashboard");

const Notification = () =>
    import("@/pages/notification/Notification");
const AddNotification = () =>
    import("@/pages/notification/AddNotification.vue");

const DiyAllPost = () =>
    import("../pages/DIY/allPost.vue");

const SystemLogin = () =>
    import("@/pages/login/SystemLogin");
const ChangePassword = () =>
    import("@/pages/users/ChangePassword");
const ManageUsers = () =>
    import("@/pages/users/ManageUsers");
const RolesPermissions = () =>
    import("@/pages/users/RolesPermissions");
const AddUser = () =>
    import("@/pages/users/AddUser.vue");

const AddDiyPost = () =>
    import("@/pages/DIY/addPost.vue");

const UserStatus = () =>
    import("@/pages/users/UserStatus");

const ResetPassword = () =>
    import('@/pages/login/ResetPassword.vue')





// const Test= () => import('@/pages/hms/manage/hotelinfo/availability/test.vue')


Vue.use(VueRouter);

if (BaseConfiguration.isMobile) {

    getHttpUIMbl();
}
//sessionStorage.clear();

var AppPageControl;
var aProfile;
export function getHttpUIMbl() {
    try {
        if (BaseConfiguration.isDebug) console.log("getHttpUIMbl");
        AppPageControl = require('../mobile/containers/aPageControl').default;
        aProfile = require('@/mobile/pages/aProfile').default;
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.HTTP_POST,
            "error in getHttpUIMbl",
            err.toString(),
            "getHttpUIMbl",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

}

const Router = new VueRouter({
    mode: "hash", // https://router.vuejs.org/api/#mode
    /*history: true,
                hashbang: false,
                saveScrollPosition: true,*/

    linkActiveClass: "active",
    scrollBehavior: () => ({
        y: 0
    }),
    routes: configRoutes()
});
export function updateDataEdited(isEdited) {
    Vue.prototype.$isDataEdited = isEdited;
}

export async function renewJWT() {
    let returnValue;
    if (BaseConfiguration.isDebug) console.log("renewJWT");

    try {
        let renewJWT_req = apiRenewJWT.buildRequest();
        if (renewJWT_req != null) {
            if (!(
                    (store.state.sSysLogin &&
                        store.state.sSysLogin.AdminSignInRes &&
                        store.state.sSysLogin.AdminSignInRes.authorization)
                )) {

                let localSysSignInRes = getSysLoginResFromLocal();
                if (localSysSignInRes) {
                    store.dispatch(
                        "sSysLogin/SET_ADMIN_SIGNIN_RES",
                        JSON.parse(localSysSignInRes)
                    );

                    store.dispatch('sDashBoard/SET_AGENT_LOGO_DET_RESPONSE', JSON.parse(localSysSignInRes).logoDetails)
                }
            }

            let renewJWT_res = await apiRenewJWT.getResponse(renewJWT_req);
            // console.error(renewJWT_res)

            if (VALIDATE_SUCCESS_RES(renewJWT_res)) {
                returnValue = renewJWT_res.data.data;
            } else {
                let error = getErrorMessage(renewJWT_res);

                let isJWt = handleJWT(error);
                if (!isJWt) {
                    returnValue = null;
                }
            }
        } else {
            throw "invalid request";
        }
    } catch (err) {
        if (navigator.onLine) {
            let body = Logger.buildRequest(
                FILE_NAME.ROUTER,
                "error in renewJWt",
                err.toString(),
                "renewJWt()",
                LOG_TYPE.ERROR
            );

            Logger.getResponse(body);
        }
    }
    return returnValue;
}

function configRoutes() {
    try {
        if (BaseConfiguration.isDebug) console.log("configRoutes");
        return [{
                path: "*",
                redirect: PATHNAME.SYSTEM_LOGIN,
                name: COMPONENT_NAME.SYSTEM_LOGIN,
                component: TheContainer,
                children: [{
                        path: PATHNAME.DASHBOARD,
                        name: COMPONENT_NAME.DASHBOARD,
                        component: Dashboard
                    },
                    {
                        path: PATHNAME.DIY_POST,
                        name: COMPONENT_NAME.DIY_POST,
                        component: {
                            render(c) {
                                return c("router-view");
                            }
                        },
                        children: [{
                                path: PATHNAME.DIY_ALL_POST,
                                name: COMPONENT_NAME.DIY_ALL_POST,
                                component: DiyAllPost,
                                meta: {
                                    progress: UI_CONTROL.PROGRESS
                                }
                            },
                            {
                                path: PATHNAME.ADD_DIY_POST,
                                name: COMPONENT_NAME.ADD_DIY_POST,
                                component: AddDiyPost,
                                meta: {
                                    progress: UI_CONTROL.PROGRESS
                                }
                            },
                        ]
                    },
                    {
                        path: PATHNAME.NOTIFICATION,
                        name: COMPONENT_NAME.NOTIFICATION,
                        component: {
                            render(c) {
                                return c("router-view");
                            }
                        },
                        children: [{
                                path: PATHNAME.NOTIFICATION_All,
                                name: COMPONENT_NAME.NOTIFICATION_All,
                                component: Notification,
                                meta: {
                                    progress: UI_CONTROL.PROGRESS
                                }
                            },
                            {
                                path: PATHNAME.NOTIFICATION_NEW,
                                name: COMPONENT_NAME.NOTIFICATION_NEW,
                                component: AddNotification,
                                meta: {
                                    progress: UI_CONTROL.PROGRESS
                                }
                            },
                        ]
                    },
                    {
                        path: PATHNAME.USERS,
                        redirect: PATHNAME.MANAGE_USERS,
                        name: COMPONENT_NAME.USERS,

                        component: {
                            render(c) {
                                return c("router-view");
                            }
                        },
                        children: [{
                                path: PATHNAME.MANAGE_USERS,
                                name: COMPONENT_NAME.MANAGE_USERS,
                                component: ManageUsers,

                                meta: {
                                    progress: UI_CONTROL.PROGRESS
                                }
                            },
                            {
                                path: PATHNAME.ROLES_PERMISSIONS,
                                name: COMPONENT_NAME.ROLES_PERMISSIONS,
                                component: RolesPermissions,

                                meta: {
                                    progress: UI_CONTROL.PROGRESS
                                }
                            },
                            {
                                path: PATHNAME.ADD_USER,
                                name: COMPONENT_NAME.ADD_USER,
                                component: AddUser,

                                meta: {
                                    progress: UI_CONTROL.PROGRESS
                                }
                            },

                            {
                                path: PATHNAME.USER_STATUS,
                                name: COMPONENT_NAME.USER_STATUS,
                                component: UserStatus,

                                meta: {
                                    progress: UI_CONTROL.PROGRESS
                                }
                            },
                            {
                                path: PATHNAME.CHANGE_PASSWORD,
                                name: COMPONENT_NAME.CHANGE_PASSWORD,
                                component: ChangePassword,

                                meta: {
                                    progress: UI_CONTROL.PROGRESS
                                }
                            }
                        ]
                    },


                    {
                        path: PATHNAME.APROFILE,
                        name: COMPONENT_NAME.APROFILE,
                        component: aProfile
                    },

                ]
            },
            {
                path: PATHNAME.PAGES,
                redirect: PATHNAME.PAGE_404,
                name: COMPONENT_NAME.PAGES,
                component: {
                    render(c) {
                        return c("router-view");
                    }
                },
                children: [{
                        path: PATHNAME.PAGE_404,
                        name: COMPONENT_NAME.PAGE_404,
                        component: Page404
                    },
                    {
                        path: PATHNAME.PAGE_500,
                        name: COMPONENT_NAME.PAGE_500,
                        component: Page500
                    }

                ]
            },
            {
                path: PATHNAME.SYSTEM_LOGIN,
                name: COMPONENT_NAME.SYSTEM_LOGIN,
                component: SystemLogin,

                meta: {
                    progress: UI_CONTROL.PROGRESS
                }
            },
            {
                path: PATHNAME.RESET_PASSWORD,
                name: COMPONENT_NAME.RESET_PASSWORD,
                component: ResetPassword,

                meta: {
                    progress: UI_CONTROL.PROGRESS
                }
            },

        ];
    } catch (err) {
        if (navigator.onLine) {
            let body = Logger.buildRequest(
                FILE_NAME.ROUTER,
                "error in config Routes",
                err.toString(),
                "configRoutes()",
                LOG_TYPE.ERROR
            );

            Logger.getResponse(body);
        }
    }
}

function clearLocalStorage() {
    try {
        if (BaseConfiguration.isDebug) console.log("clearLocalStorage");
        clearLogCountFromLocal()
        clearAgtLoginResFromLocal()
        clearSysLoginResFromLocal()
        clearBaseSettFromLocal()


    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.ROUTER,
            "error in Clear Local Storage",
            err.toString(),
            "clearLocalStorage()",
            LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
    }
}



export async function updateCurrRouteQuery(pQuery) {
    try {
        if (BaseConfiguration.isDebug) console.log("updateCurrRouteQuery");
        if (Router.currentRoute) {
            await Router.replace({
                ...Router.currentRoute,
                query: pQuery
            }).catch(err => {
                throw err;
            });
        } else {
            throw 'invalid current route'
        }


    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.ROUTER,
            "error in update Curr Route Query",
            err.toString(),
            "updateCurrRouteQuery()",
            LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
    }
}

export function goBack(param) {
    try {
        if (BaseConfiguration.isDebug) console.log("goBack");
        if (param) {
            Router.go(param);
        } else {
            Router.go(-1);
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.ROUTER,
            "error in go Back",
            err.toString(),
            "goBack()",
            LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
    }
}

export function navigateReplace(pRouteName, pQuery) {
    try {
        if (BaseConfiguration.isDebug) console.log("navigateReplace");
        let route = {
            path: pRouteName,

        };
        if (pQuery) {
            route.query = pQuery;

        }

        if (pRouteName) {

            Router.replace(route).catch(err => {
                throw err;
            })
        } else {

            throw "invalid Route Name";
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.ROUTER,
            "error in navigateReplace To",
            err.toString(),
            "navigateReplace()",
            LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
    }
}



export function navigate(pRouteName, pQuery) {
    try {
        if (BaseConfiguration.isDebug) console.log("navigate");

        let route = {
            path: pRouteName,

        };
        if (pQuery) {
            route.query = pQuery;

        }

        if (pRouteName) {

            Router.push(route).catch(err => {
                // throw err;
            })
        } else {

            throw "invalid Route Name";
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.ROUTER,
            "error in navigate To",
            err.toString(),
            "navigateTo()",
            LOG_TYPE.ERROR
        );

        Logger.getResponse(body);
    }
}

export function reloadCurrentPage() {
    try {
        if (BaseConfiguration.isDebug) console.log("reloadCurrentPage");
        Router.go();

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.UTIL,
            "error in reloadCurrentPage",
            err.toString(),
            "reloadCurrentPage",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
}

Router.afterEach((to, from, next) => {
        try {
            //  finish the progress bar

            // if (main) {
            //     main.$Progress.finish();
            //     // console.error(main)
            // }

            /*   if ((from.path == PATHNAME.PENDINGPAGE || from.path == PATHNAME.HOLDPAGE || from.path == PATHNAME.SUCCESSPAGE || from.path == PATHNAME.FAILEDPAGE) && to.path != PATHNAME.FLIGHTSEARCH) {
                  next(PATHNAME.FLIGHTSEARCH)
              }

              if ((from.path == PATHNAME.FLIGHTSEARCH) && (to.path == PATHNAME.PENDINGPAGE || to.path == PATHNAME.HOLDPAGE || to.path == PATHNAME.SUCCESSPAGE || to.path == PATHNAME.FAILEDPAGE || to.path == PATHNAME.PAYMENTPAGE)) {
                  next(PATHNAME.DASHBOARD)
              } */


        } catch (err) {
            if (navigator.onLine) {
                let body = Logger.buildRequest(
                    FILE_NAME.ROUTER,
                    "error in Router.afterEach",
                    err.toString(),
                    "Router.afterEach()",
                    LOG_TYPE.ERROR
                );

                Logger.getResponse(body);
            }
        }
    }),

    Router.beforeEach(async (to, from, next) => {
        try {
            // next(vm => vm.resetFocus())
            document.title = "System Portal - B2B Flight Engine"
            const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

            async function getRenew() {
                let renewJWT_status;

                if (!(

                        to.path == PATHNAME.SYSTEM_LOGIN ||
                        from.path == PATHNAME.SYSTEM_LOGIN ||
                        to.path == PATHNAME.PAGE_404 ||
                        to.path == PATHNAME.PAGE_500
                    )) {
                    renewJWT_status = await renewJWT();
                }



                if (renewJWT_status && renewJWT_status.authorization) {
                    if (store.getters["sSysLogin/GET_ADMIN_SIGNIN_RES"]) {
                        let renewJWT = store.getters["sSysLogin/GET_ADMIN_SIGNIN_RES"];
                        renewJWT.authorization = renewJWT_status.authorization;
                        store.dispatch("sSysLogin/SET_ADMIN_SIGNIN_RES", renewJWT);
                    }
                }

                ///to Start Progress Bar
                // if (to && to.meta && to.meta.progress) {
                //     let meta = to.meta.progress;
                //     if (main) {
                //         main.$Progress.parseMeta(meta);
                //         main.$Progress.start();
                //     }
                // }

                //to Clear All Request , Response and Data in Local store before Login
                if (
                    to.path == PATHNAME.SYSTEM_LOGIN
                ) {
                    removeB2BMFfromSession()
                    clearLocalStorage();
                    Vue.toasted.clear();
                    clearStore()
                }
            }
            assigni18nLocale(checkAndGoNext)

            function checkAndGoNext() {
                try {
                    // if the page requires authentication and the user is not logged in , then redirect to login page
                    if (requiresAuth) {
                        next({
                            name: "AgentLogin"
                        });
                    } else {

                        if (Vue.prototype.$isDataEdited === true) {
                            if (!BaseConfiguration.isEnableDataEditAlert || to.path == PATHNAME.PAGE_404 || to.path == PATHNAME.PAGE_500 || to.path == PATHNAME.SYSTEM_LOGIN) {
                                updateDataEdited(false);
                                next();
                            } else {
                                getConfirmAndGoNext();

                            }
                        } else {
                            if (BaseConfiguration.isMobile) {
                                // var AppPageControl = require('@/mobile/containers/aPageControl');

                                let isRestrictedRoute = true;
                                if (AppPageControl) {
                                    isRestrictedRoute = AppPageControl.appRouteControl(to);
                                }
                                if (isRestrictedRoute == false) {
                                    next(false);
                                } else {
                                    next()
                                }
                            } else {
                                next();
                            }

                        }

                        // }
                    }
                } catch (err) {
                    if (navigator.onLine) {
                        let body = Logger.buildRequest(
                            FILE_NAME.ROUTER,
                            "error in checkAndGoNext",
                            err.toString(),
                            "checkAndGoNext()",
                            LOG_TYPE.ERROR
                        );

                        Logger.getResponse(body);
                    }
                }
            }
            if (!Vue.prototype.$isDataEdited) {
                if (isCallRenew()) {
                    await getRenew();
                }
                if (isHmsFlow(to.path)) {
                    Event.$emit("isHmsFlow", true);
                } else {
                    Event.$emit("isHmsFlow", false);
                }
            }

            function isCallRenew() {
                if (BaseConfiguration.isDebug) console.log('isCallRenew')
                if (from.path == to.path) { ///&&!store.getters["sCMultiCitySearch/GET_IS_MODIFY_SEARCH"]
                    return false;
                } else {

                    return true;
                }
            }

            function getConfirmAndGoNext() {
                // EventBus.$dialog.confirm('<span>Confirmation </span><div> You have unsaved changes. Are you sure you want to leave this page?</div>')
                // if(!EventBus.$dialog.mounted){
                EventBus.$dialog.confirm(i18n.tc('index.leaveCnfrmTxt'))
                    .then(function () {
                        updateDataEdited(false);
                        getRenew();
                        next(); //confirmed
                    })
                    .catch(function () {
                        next(false); //cancelled
                    });
                // }
            }




        } catch (err) {
            if (navigator.onLine) {
                let body = Logger.buildRequest(
                    FILE_NAME.ROUTER,
                    "error in Router.beforeEach",
                    err.toString(),
                    "Router.beforeEach()",
                    LOG_TYPE.ERROR
                );

                Logger.getResponse(body);
            }
        }
    });

export default Router;