const state = {
    userRolesReq: null,
    userRolesRes: null
};
//getters

const getters = {
    GET_USER_ROLES_REQ: (state) => {
        return state.userRolesReq;
    },

    GET_USER_ROLES_RES: (state) => {
        return state.userRolesRes;
    }
};

//actions
const actions = {
    CLEAR_USER_ROLES_STORE: ({ commit }) => {
        commit("clearUserRolesStore");
    },

    SET_USER_ROLES_REQUEST: ({ commit }, req) => {
        commit("saveUserRolesReq", req);
    },

    SET_USER_ROLES_RESPONSE: ({ commit }, res) => {
        commit("saveUserRolesRes", res);
    }
};

//mutations
const mutations = {
    clearUserRolesStore(state) {
        state.userRolesReq = null;
        state.userRolesRes = null;
    },
    saveUserRolesReq(state, req) {
        state.userRolesReq = req;
    },
    saveUserRolesRes(state, res) {
        state.userRolesRes = res;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};