const state = {

    allUserRequest: null,
    allUserResponse: [],
    allUserCount:null,
    tagsList:[]

};

const getters = {
    GET_ALL_USER_RESPONSE: (state) => {
        return state.allUserResponse;
    },
    GET_USER_COUNT: (state) => {
        return state.allUserCount;
    },
    GET_TAGS_LIST: (state) => {
        return state.tagsList;
    }
};


const actions = {

    CLEAR_MANAGE_USERS_STORE: ({ commit }) => {
        commit('clearManageUsersStore');
    },
    SET_ALL_USER_RESPONSE: ({ commit }, allUserRes) => {
        commit('saveAllUserResponse', allUserRes);
    },
    SET_USER_COUNT: ({ commit }, count) => {
        commit('saveUserCount', count);
    },
    SET_TAGS_LIST: ({ commit }, tagsList) => {
        commit('saveTagsList', tagsList);
    },
};


const mutations = {
    clearManageUsersStore: (state) => {
        state.allUserResponse = null;
        state.allUserRequest = [];
    },
    saveAllUserResponse: (state, allUserRes) => {

        state.allUserResponse = [];
        state.allUserResponse = allUserRes;

    },
    saveUserCount: (state, count) => {

        state.allUserCount = count;
       

    },
    saveTagsList: (state, tagsList) => {

        state.tagsList = tagsList;
       

    },




};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}