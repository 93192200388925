const state = {
  
    allDiyNotification: [],
    selectedNotification: null
   
};

const getters = {
    GET_ALL_DIY_NOTIFICATION: (state) => {
        return state.allDiyNotification;
    },
    GET_SELECTED_NOTIFICATION: (state) => {
        return state.selectedNotification;
    },
   
};


const actions = {

    CLEAR_DIY_NOTIFICATION: ({ commit }) => {
        commit('clearDiyNotification');
    },
    SET_ALL_DIY_NOTIFICATION: ({ commit }, allPostRes) => {
        commit('saveAllDiyNotification', allPostRes);
    },
    SET_SELECTED_NOTIFICATION: ({ commit }, selectedRes) => {
        commit('saveSelectedDiyNotification', selectedRes);
    },
    
};


const mutations = {
    clearDiyNotification: (state) => {
        state.allDiyNotification = null;
        state.selectedNotification = null;
    },
    saveAllDiyNotification: (state, allDiyNotification) => {
        state.allDiyNotification = allDiyNotification;
    },
    saveSelectedDiyNotification: (state, selectedNotification) => {
        state.selectedNotification = selectedNotification;
    },
    
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}