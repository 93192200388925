import Vue from "vue";
import CoreuiVue from "@coreui/vue";
Vue.use(CoreuiVue);
import App from "./App";
import router from "./router";
import formRules from "./rules/formRules";
import {
  iconsSet as icons
} from "@imgPath/icons/icons.js";
import {
  i18n
} from "./plugins/i18n.js";
import * as rules from "vee-validate/dist/rules";
import Logger from "./services/logger";
import store from "./store/index.js";
import BaseConfiguration from "./config/base.js";
import {
  isLoggedIn,
  cloneDeep
} from './util/util'
import {
  LOG_TYPE,
  FILE_NAME,
} from "./config/API_DATA";
import {
  extend
} from "vee-validate";
import "./vueFilters/filters";
import {
  UI_CONTROL
} from "./util/uiControl";
import VueProgressBar from "vue-progressbar";
import VueToasted from "vue-toasted";
import appToast from "./mixin/appToast.vue";
import {
  ALL_CLIENT_CODE,
} from "./util/constants";
import {
  assigni18nLocale
} from "./util/util";
import {
  getB2BVersionFromLocal,
  setB2bVersionToLocal,
  getBaseSettFromLocal,

  forceClearLocalStorage
} from "./applocalstorage/localStorage";
import fullscreen from "vue-fullscreen";
import VuejsDialog from "vuejs-dialog";
import GlobalEvents from "vue-global-events";
import "vuejs-dialog/dist/vuejs-dialog.min.css"; // include the default style
import {
  localize
} from "vee-validate";
import {
  getClientCode,
  isDebugMode
} from './config/env-config'

Vue.use(fullscreen);
Vue.component("GlobalEvents", GlobalEvents)

initBaseSett()
initClientBasedLang()
initClientBasedSett();
appBasedSett();

///to assign default i18n locale
assigni18nLocale();
///
Vue.use(VuejsDialog, {
  html: true,
  //loader: true,
  okText: "Leave", //i18n.tc('index.leave'),
  cancelText: "Stay", //i18n.tc('index.stay'),
  //animation: 'bounce'
});
Vue.prototype.$isDataEdited = false; // to check the page data is edited or not. kind of global variable

//Vue.prototype.$http = axios;
extendValidationRules()
// Vue.use(ToggleButton);




export const Event = new Vue();
Vue.config.performance = true;

Vue.use(VueProgressBar, UI_CONTROL.PROGRESS_OPTIONS);
Vue.use(VueToasted, {
  //iconPack : 'material' // set your iconPack, defaults to material. material|fontawesome|custom-class

  iconPack: "custom-class",
  after: true, // this will append the icon to the end of content
  // you can pass a multiple actions as an array of actions
});
Vue.mixin(appToast);



Vue.config.errorHandler = function (err, vm, info) {

  if (BaseConfiguration.isDebug) console.log("errorHandler");
  //if (BaseConfiguration.isDebug) console.error(err);
  if (BaseConfiguration.isDebug) console.error(vm);
  //if (BaseConfiguration.isDebug) console.error(info);

  let error = {
    info: info,
    err: err.toString()
  }

  let body = Logger.buildRequest(
    FILE_NAME.MAIN_FILE,
    "error in vue error handler ",
    error,
    "errorHandler",
    LOG_TYPE.ERROR
  );
  Logger.getResponse(body);

};
Vue.config.warnHandler = function (msg, vm, info) {
  if (BaseConfiguration.isDebug) console.log("warnHandler");
  if (BaseConfiguration.isDebug) console.error(vm);

  let error = {
    info: info,
    msg: msg.toString()
  }

  let body = Logger.buildRequest(
    FILE_NAME.MAIN_FILE,
    "error in vue warning Handler",
    error,
    "warnHandler",
    LOG_TYPE.WARNING
  );
  Logger.getResponse(body);
};
window.onerror = function (msg, src, linenum, colnum, err) {
  if (BaseConfiguration.isDebug) console.log("window.onerror");
  let error = {

    msg: msg.toString(),
    err: err,
    url: src,
    line: linenum,
    columnNo: colnum
  }

  let body = Logger.buildRequest(
    FILE_NAME.MAIN_FILE,
    "error in js error Handler",
    error,
    "window.onerror",
    LOG_TYPE.ERROR
  );
  Logger.getResponse(body);
};

///back button click detect(This listener will execute before router.beforeEach)
// eslint-disable-next-line no-unused-vars
window.onpopstate = function (event) {
  window.popStateDetected = true; ///to handle backflow nav(popStateDetected used in router.beforeEach  )
};

function extendValidationRules() {
  try {
    if (BaseConfiguration.isDebug) console.log("extendValidationRules");
    Object.keys(rules).forEach((rule) => {
      extend(rule, rules[rule]);
    });

  } catch (err) {
    let body = Logger.buildRequest(
      FILE_NAME.MAIN_FILE,
      "error in extendValidationRules",
      err.toString(),
      "extendValidationRules",
      LOG_TYPE.ERROR
    );
    Logger.getResponse(body);
  }

}

function initBaseSett() {
  try {
    if (BaseConfiguration.isDebug) console.log("initBaseSett");
    BaseConfiguration.isDebug = isDebugMode() //debug mode
    BaseConfiguration.clientCode = getClientCode(); //clientCode
    //API_VALUES.ORG_ID = getOrgId() /// assign_ORGID;
    BaseConfiguration.defaultLanguageCode = getDefaultLang() //language code


  } catch (err) {
    let body = Logger.buildRequest(
      FILE_NAME.MAIN_FILE,
      "error in initBaseSett",
      err.toString(),
      "initBaseSett",
      LOG_TYPE.ERROR
    );
    Logger.getResponse(body);
  }

}


function getDefaultLang() {
  let retValue;
  try {
    if (BaseConfiguration.isDebug) console.log("getDefaultLang");
    switch (BaseConfiguration.clientCode) {
      case ALL_CLIENT_CODE.AZ:
        retValue = "ca";
        break;
      default:
        break;
    }
  } catch (err) {
    let body = Logger.buildRequest(
      FILE_NAME.MAIN_FILE,
      "error in getDefaultLang",
      err.toString(),
      "getDefaultLang",
      LOG_TYPE.ERROR
    );
    Logger.getResponse(body);
  }
  return retValue;
}







export function appBasedSett() {
  try {
    if (BaseConfiguration.isDebug) console.log("appBasedSett");
    if (BaseConfiguration.isMobile) {
      var Ionic = require("@ionic/vue").default;
      var aSideMenu = require('./mobile/components/aSideMenu').default;
      var aHeader = require('./mobile/components/aHeader').default;
      var aIntroSlides = require('./mobile/components/aIntroSlides').default;
      var AppPageControl = require('./mobile/containers/aPageControl').default;
      var jsfile = {
        install() {
          Vue.prototype.$AppPageControl = AppPageControl
        }
      }

      Vue.use(jsfile)

      // Vue.use(AppPageControl);
      Vue.component('aIntroSlides', aIntroSlides);
      Vue.component('aHeader', aHeader)
      Vue.component('aSideMenu', aSideMenu)
      Vue.use(Ionic);
    }
  } catch (err) {
    let body = Logger.buildRequest(
      FILE_NAME.MAIN_FILE,
      "error in appBasedSett",
      err.toString(),
      "appBasedSett",
      LOG_TYPE.ERROR
    );
    Logger.getResponse(body);
  }
}



function initClientBasedLang() {
  try {
    if (BaseConfiguration.isDebug) console.log("initClientBasedLang");
    //client Based Settings
    //import("./assets/" + BaseConfiguration.clientCode + "/scss/style.scss"); //load client based style 


    var nb = require("./translations/" +
      BaseConfiguration.clientCode +
      "/validation/nb.json");
    var en = require("./translations/" +
      BaseConfiguration.clientCode +
      "/validation/en.json");
    var ca = require("./translations/" +
      BaseConfiguration.clientCode +
      "/validation/ca.json");

    localize({
      en: {
        messages: en.messages,
        fields: {
          credit: en.messages.CreditNotePage,
          exchangeRate: en.messages.ExchangeRatePage,
          changePricing: en.messages.ChangePricing,
          createPricing: en.messages.CreatePricing,
          paxPage: en.messages.PaxPage,
          agentRegistration: en.messages.AgentRegistration,
          routes: en.messages.Routes,
          hotelRoom: en.messages.hotelRoom
        },
      },
      nb: {
        messages: nb.messages,
        fields: {
          credit: nb.messages.CreditNotePage,
          exchangeRate: nb.messages.ExchangeRatePage,
          changePricing: nb.messages.ChangePricing,
          createPricing: nb.messages.CreatePricing,
          paxPage: nb.messages.PaxPage,
          agentRegistration: nb.messages.AgentRegistration,
          routes: nb.messages.Routes,
          hotelRoom: nb.messages.hotelRoom
        },
      },
      ca: {
        messages: ca.messages,
        fields: {
          credit: ca.messages.CreditNotePage,
          exchangeRate: ca.messages.ExchangeRatePage,
          changePricing: ca.messages.ChangePricing,
          createPricing: ca.messages.CreatePricing,
          paxPage: ca.messages.PaxPage,
          agentRegistration: ca.messages.AgentRegistration,
          routes: ca.messages.Routes,
          hotelRoom: ca.messages.hotelRoom
        },
      },
    });

  } catch (err) {
    let body = Logger.buildRequest(
      FILE_NAME.MAIN_FILE,
      "error in initClientBasedLang",
      err.toString(),
      "initClientBasedLang",
      LOG_TYPE.ERROR
    );
    Logger.getResponse(body);
  }

}


export function initClientBasedSett() {
  try {
    if (BaseConfiguration.isDebug) console.log("initClientBasedSett");

    let settings = getBaseSettFromLocal()
    if (isLoggedIn()) {
      if (settings && settings) {
        let clientSettings = cloneDeep(settings);
        // API_VALUES.ORG_ID =clientSettings.ORGID ;
        Object.keys(clientSettings).forEach((key) => {
          BaseConfiguration[key] = clientSettings[key];
        });
        forceClearLocalStorage();

      } else {
        throw "invalid settings from local";
      }
    }





  } catch (err) {
    let body = Logger.buildRequest(
      FILE_NAME.MAIN_FILE,
      "error in initClientBasedSett",
      err.toString(),
      "initClientBasedSett",
      LOG_TYPE.ERROR
    );
    Logger.getResponse(body);
  }
}

export default new Vue({
  // export the Vue instance
  el: "#app",
  router,
  icons,
  formRules,
  // apolloProvider,
  store,
  i18n,
  template: "<App/>",
  components: {
    App,
  },
  methods: {
    checkVersion(pVersion) {
      try {
        let retValue;
        if (BaseConfiguration.isDebug) console.log("checkVersion");
        let version = this.getVersion();
        if (version != null) {
          if (version == pVersion) {
            retValue = false;
          } else {
            retValue = true;
            this.updateVersion(pVersion);
          }
        } else {
          this.updateVersion(pVersion);
          retValue = false;
        }
        return retValue;
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MAIN_FILE,
          "error in checkVersion",
          err.toString(),
          "checkVersion",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    getVersion() {
      let version;
      try {
        if (BaseConfiguration.isDebug) console.log("getVersion");



        version = getB2BVersionFromLocal()

      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MAIN_FILE,
          "error in getVersion",
          err.toString(),
          "getVersion",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return version;
    },

    updateVersion(pVersion) {
      try {
        if (BaseConfiguration.isDebug) console.log("updateVersion");
        if (pVersion)

          setB2bVersionToLocal(pVersion)
      } catch (err) {
        let body = Logger.buildRequest(
          FILE_NAME.MAIN_FILE,
          "error in updateVersion",
          err.toString(),
          "updateVersion",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
  },

  created: function () {
    //removed temporarily on 23-09-2020 
    if (BaseConfiguration.isDebug) console.log("main-created");
    if (this.checkVersion(BaseConfiguration.version)) {
      location.reload(true);
    }
    //alert(BaseConfiguration.paymentDebugUrl);
  },
});