import axios from "axios";
import base from "./../config/base";
import {
    getBaseURL
} from './../config/env-config'



//debug mode
let baseURL = getBaseURL()


export const httpClient = axios.create({
    baseURL: baseURL,
    timeout: base.timeout,
});