/* eslint-disable no-unused-vars */
import { extend } from "vee-validate";
import { required, regex } from "vee-validate/dist/rules";



extend("_required", {
    ...required
});


extend('isValidRange',{

    validate(values,params)
    {

        params = JSON.parse(params)
       let newPolicy = params?params.target:null ;
       let policyBreakupList= params?params.list:[];
       let toRemoveIndex = params?params.index:null ;

       let validBreakupPolicy=true

       if( newPolicy &&policyBreakupList && policyBreakupList.length && toRemoveIndex!=null )
       {

        policyBreakupList =policyBreakupList.filter((_,index)=>index!=toRemoveIndex)
      ///  console.error(policyBreakupList)
         for (let index = 0; index < policyBreakupList.length; index++) {
            const policy = policyBreakupList[index];

            let isLesserRange =
              parseInt(newPolicy.noOfDaysBeforeCheckinFrom) <
                parseInt(policy.noOfDaysBeforeCheckinFrom) &&
              parseInt(newPolicy.beforeCheckIn) <=
                parseInt(policy.noOfDaysBeforeCheckinFrom);

            let isGreaterRange =
              parseInt(newPolicy.noOfDaysBeforeCheckinFrom) >=
                parseInt(policy.beforeCheckIn) &&
              parseInt(newPolicy.beforeCheckIn) >
                parseInt(policy.beforeCheckIn);

            if (isLesserRange || isGreaterRange) {
                validBreakupPolicy = true;
            } else {
                validBreakupPolicy = false;
              break;
            }
             
         }
       }
       else
       {
           validBreakupPolicy=false
       }
        return validBreakupPolicy;
    }
})  




extend("checkOne", {
    validate(values) {
        let lsPayments = values.B2B.concat(values.B2C);
        let isActive = lsPayments.find(value => value.isActive);
        if (isActive) return true;
        else return false;
    }
});
extend("required", {
    validate(value) {
        return {
            required: true,
            valid: ["", null, undefined].indexOf(value) === -1 && value.length != 0
        };
    },
    computesRequired: true
});
// extend("required", {
//     ...required
//     //   message: "This field is required"
// });

extend("Uptomax", {
    validate(value, args) {
        return value.length <= args.length;
    },
    params: ["length"]
});

extend("Atmax", {
    validate(value, args) {
        return value.length <= args.length;
    },
    params: ["length"]
});

extend('max', {
    validate(value, { length }) {
      return value.length <= length;
    },
    params: ['length'],
    
  });


extend('Pswdmax', {
    validate(value, args) {
        return value.length <= args.length;
    },
    params: ['length']
});

extend('Maximum', {
    validate(value, args) {
        return value.length <= args.length;
    },
    params: ['length']
});

extend("oneSplChar", {
    validate: value => {
        if (typeof value == "string") return value.match(/[^\s][^A-Za-z0-9]/g) !== null;
        else if (typeof value == "number")
            return value.toString().match(/[^\s][^A-Za-z0-9]/g) !== null;
    }
});

extend("noInitialSpace", {
    validate: value => {
        if (typeof value == "string") return value.match(/(^\S.*)/g) !== null;
        else if (typeof value == "number")
            return value.toString().match(/(^\S.*)/g) !== null;
    }
});


extend("noSpaceAllowed", {
    validate: value => {
        return value.match(/(^\S*)$/g)!=null;
    }
});


// extend('minimum', value => {
//     return value.length >= 8;
// });

extend("minimum", {
    validate(value, { min }) {
        return value.length >= parseInt(min);
    },
    params: ["min"]
});

extend("validPwd", {
    validate: value => {
        return (
            value.match(/^((?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{8,20}).*$/g) !==
            null
        );
    }
});

extend("validPstlCode", {
    validate: value => {
        return value.match(/^[A-Z0-9]{3,10}$/g) !== null;
    }
});

extend("noSpace", {
    validate: value => {
        if (typeof value == "string") return value.match(/^\S*$/g) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^\S*$/g) !== null;
    }
});
extend("contact_number", {
    validate: value => {
        if (typeof value == "string") return value.match(/^[\d\s,+]+$/) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^[\d\s,+]+$/) !== null;
    }
});

extend("onlyNumber", {
    validate: value => {
        if (typeof value == "string") return value.match(/^[0-9]*$/) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^[0-9]*$/) !== null;
    }
});
extend("onlynumber", {
    validate: value => {
        if (typeof value == "string") return value.match(/^[0-9.,]*$/) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^[0-9.,]*$/) !== null;
    }
});
extend("notValidNum", {
    validate: value => {
        if (typeof value == "string")
            return (
                value.match(/^[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*$/) !== null
            );
        else if (typeof value == "number")
            return (
                value
                .toString()
                .match(/^[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*$/) !== null
            );
    }
});

extend("valueGreaterZero", {
    validate: value => {
        if (typeof value == "string")
            return (

                // (/^[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*$/)    (/^(0*([1-9][0-9])*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/)
                value.match(/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/) !== null
            );
        else if (typeof value == "number")
            return (
                value
                .toString()
                .match(/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/) == null
            );
    }
});
extend("onlyAlphabets", {
    validate: value => {
        if (typeof value == "string") return value.match(/^[A-Za-z_ ]+$/g) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^[A-Za-z_ ]+$/g) !== null;
    }
});

// extend("email", email);
extend("upperCase", {
    validate: value => {
        if (typeof value == "string") return value.match(/^[A-Z_ ]+$/g) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^[A-Z_ ]+$/g) !== null;
    }
});

extend("number", {
    validate: value => {
        if (typeof value == "string") return value.match(/[0-9]/g) !== null;
        else if (typeof value == "number")
            return value.toString().match(/[0-9]/g) !== null;
    }
});

extend("letter", {
    validate: value => {
        if (typeof value == "string") return value.match(/[A-Z]/g) !== null;
        else if (typeof value == "number")
            return value.toString().match(/[A-Z]/g) !== null;
    }
});

extend("minmax", {
    validate(value, { min, max }) {
        return value.length >= min && value.length <= max;
    },
    params: ["min", "max"]
});

extend("no_space", {
    validate: value => {
            if (typeof value == "string") return value.match(/^\S*$/g) !== null;
            else if (typeof value == "number")
                return value.toString().match(/^\S*$/g) !== null;
        }
        
        // validate: value => value.match(/^\S*$/g) !== null,
});

extend("nospace", {
    validate: value => {
            if (typeof value == "string") return value.match(/^\S*$/g) !== null;
            else if (typeof value == "number")
                return value.toString().match(/^\S*$/g) !== null;
        }
        
        // validate: value => value.match(/^\S*$/g) !== null,
});


extend("alphanum", {
    validate: value => {
        if (typeof value == "string") return value.match(/^[A-Za-z0-9]+$/g) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^[A-Za-z0-9]+$/g) !== null;
    }
});

extend("only_numbers", {
    validate: value => {
        if (typeof value == "string") return value.match(/^[0-9.,]+$/) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^[0-9.,]+$/) !== null;
    }
});

extend("negative_numbers", {
    validate: value => {
        if (typeof value == "string") return value.match(/^-?[0-9]+$/) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^-?[0-9]+$/) !== null;
    }
});

extend("no_special_char", {
    validate: value => {
        if (typeof value == "string") return value.match(/^[.,]+$/) == null;
        else if (typeof value == "number")
            return value.toString().match(/^[.,]+$/) == null;
    }
});

extend("no_spcl_char", {
    validate: value => {
        if (typeof value == "string") return value.match(/^([A-Z]|[0-9]|[a-z]|(([A-Z]|[a-z]|[0-9])[-]))+$/g) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^([A-Z]|[0-9]|[a-z]|(([A-Z]|[a-z]|[0-9])[-]))+$/g) !== null;
    }
});
extend("noSplCharExceptHyphen", {
    validate: value => {
        if (typeof value == "string") return value.match(/^([A-Z]|[0-9]|[a-z]|(([A-Z]|[a-z]|[0-9])[-]))+$/g) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^([A-Z]|[0-9]|[a-z]|(([A-Z]|[a-z]|[0-9])[-]))+$/g) !== null;
    }
});

extend("oneDecimal", {
    validate: value => {
       return value.match(/^[0-9,]+(\.)?\d+$/) != null;

      }
});
extend('maxDecimal', {
    validate: value => {
        value = value.toString();       
        value = value.split("."); 
        if(value[1]){
           return (value[0].length <= 10 && value[1].length<=10);
        }else{
           return value[0].length <= 10;
        }       
    }
});

extend('noUpperCase', {
    validate: value => {
        if (typeof(value) == 'string')
            return value.match(/^[a-z_ ]+$/g) !== null
        else if (typeof(value) == 'number')
            return value.toString().match(/^[a-z_ ]+$/g) !== null
    }
})

extend("max_val", {
    validate(value, { max }) {
        value = value.toString().replace(",", "");
        return parseFloat(value) <= parseFloat(max);
    },
    params: ["max"]
});

extend("maximum_value", {
    validate(value, { max, curr }) {
        value = value.toString().replace(",", "");
        return parseFloat(value) <= parseFloat(max);
    },
    params: ["max","curr"]
});

extend("maxLength", {
    validate(value, { max }) {
        value = value.toString().replace(",", "");
        return parseFloat(value).length > parseFloat(max);
    },
    params: ["max"]
});

extend("maximum_val", {
    validate(value, { max }) {
        value = value.toString().replace(",", "");
        return parseFloat(value) < parseFloat(max);
    },
    params: ["max"]
});
extend("maxmin_val", {
    validate(value, { min, max  }) {
        value = value.toString().replace(",", "");
        return Number(value) <= Number(max)&&Number(value) >= Number(min);
    },
    params: ["min","max"]
});

extend("min_val", {
    validate(value, { min }) {
        return parseInt(value) >= parseInt(min);
    },
    params: ["min"]
});

extend("payUseCashSale", {
    validate(value, {  max  }) {
        value = value.toString().replace(",", "");
        return Number(value) < Number(max);
    },
    params: ["max"]
});


extend("validDecimal", {
    validate: value => {
        return value.toString().match(/(^[^.]*\.?[^.]*$)/g) !== null;
    }
});

extend("no_zero", {
    validate(value) {
        return parseFloat(value) != parseFloat(0);
    },
});

extend("paxregex", {
    ...regex
});

extend("noNumbers", {
    validate: value => {
        if (typeof value == "string") return value.match(/^[A-Za-z_ ]+$/g) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^[A-Za-z_ ]+$/g) !== null;
    }
});
extend("noSpaceinInitial", {
    validate: value => {
        return value.match(/(^\S.*)/g) !== null;
    }
});

extend("noSpaceAndSpl", {
    validate: value => {
            if (typeof value == "string") return value.match(/^[A-Za-z0-9]*$/g) !== null;
            else if (typeof value == "number")
                return value.toString().match(/^[A-Za-z0-9]*$/g) !== null;
        }
        
        // validate: value => value.match(/^\S*$/g) !== null,
});

extend("int_phone_number", {
    validate: value => {
        if (typeof value == "string") return value.match(/^\+?[\d\s]+$/) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^\+?[\d\s]+$/) !== null;
    }
});

extend('maxTwoDecimal', {
    validate: value => {
        value = value.toString();       
        value = value.split("."); 
        if(value[1]){
           return (value[1].length <= 2);
        }else{
            return true;
        }     
    }
});

extend("lat_long", {
    validate: value => {
        if (typeof value == "string") return value.match(/^([+-](?=\.?\d))?(\d+)?(\.\d+)?$/) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^([+-](?=\.?\d))?(\d+)?(\.\d+)?$/) !== null;
    }
});

extend("agt_pwd", {
    validate: value => {
        if (typeof value == "string") return value.match(/^((?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{8,20}).*$/) !== null;
        else if (typeof value == "number")
            return value.toString().match(/^((?!.*[\s])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{8,20}).*$/) !== null;
    }
});
export default extend;