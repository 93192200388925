export default class BaseConfiguration {
    static version = 1.126;
    static clientCode = null;
    static baseURL = null;
    static isDebug = null; //if it is true it uses dev api. false uses prod api
    static paymentDebugUrl = null; //for payment flow
    //static paymentDebugUrl = null;

    static subscriptionURL = null;
    static googleMapKey = null;
    static maxCountForReport = null
    static multiCityLength = null
    static qlUrl = null;
    static qlKey = null;
    static defaultLanguageCode = null;

    static defaultCurrencyObj = null;

    static defaultALImg = null;

    static defaultCitizen = null ///Myanmar
    static defaultTrip = null; ///RoundTrip    
    static paymentTCLink = null;

    static defaultCountryValue = null;
    //static exrateMaxLimit = 100000;
    static defaultForeignCurrencyCode = null;

    static timeout = null; //120 sec
    static defaultAlertCounter = null;
    static defaultAirlineCode = null;
    static isStopsForNonMyanmar = null; // assigning via object as stops for Myanmar // true - will assign actual stops 

    static flightSearchHistoryMaxLimit = null;
    static maxFareList = null;
    static defaultTimeFormat = null
    static defaultDateTimeFormat = null
    static defaultDateFormat = null


    static defaultPostPaidReportDur = null //in months


    static dobValidation = null

    static ppExpiryDate = null; //by month
    // static showMenu = true; // true - from API // remove before publish



    static maxDOBYears = null;
    static maxPPExpryYears = null;
    static sys_dashboard_top_agent_max = null;
    static isMobile = null; /// true - if the device is mobile, false - is web
    //Mobile only pages    
    static not_allowedPages_mbl = null;

    static isTourFlightResultsPage = null;
    static isHotelSearch = null;
    static roomLength = null
    static isEnableDataEditAlert = null;
    static hmsDefaultHotelImg = null //"https://hotel-public.s3-ap-southeast-1.amazonaws.com/images/hotel_default_img.svg";
    static isHmsFlow = false;

    static manageHotelPopup = null;
    static isAddApiLoadingClass = true;
    static isForceClearLocalStorage = false;

    static isFormPricing = null // to differentiate pricing


}