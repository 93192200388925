const state = {
    agentRolesReq: null,
    agentLogoDetRes: null,
    agentFinanceRes: null,
    userId: null,
    isNavigateToBooking:null
};
//getters

const getters = {
    GET_AGENT_LOGO_DET_REQ: (state) => {
        return state.agentLogoDetReq;
    },

    GET_AGENT_LOGO_DET_RES: (state) => {
        return state.agentLogoDetRes;
    },

    GET_USER_ID: (state) => {
        return state.userId;
    },

    GET_AGENT_FINANCE_RES: (state) => {
        return state.agentFinanceRes;
    },

    GET_NAV_BOOL: (state) => {
        return state.isNavigateToBooking;
    },
};

//actions
const actions = {
    CLEAR_AGENT_LOGO_STORE: ({ commit }) => {
        commit("clearAgentRolesStore");
    },

    SET_AGENT_LOGO_DET_REQUEST: ({ commit }, req) => {
        commit("saveAgentLogoDetReq", req);
    },

    SET_AGENT_LOGO_DET_RESPONSE: ({ commit }, res) => {
        commit("saveAgentLogoDetRes", res);
    },

    SET_AGENT_FINANCE_RESPONSE: ({ commit }, res) => {
        commit("saveAgentFinanceRes", res);
    },
    SET_NAV_BOOL: ({ commit }, req) => {

        commit('saveNavBool', req);

    },

};

//mutations
const mutations = {
    clearAgentRolesStore(state) {
        state.agentLogoDetReq = null;
        state.agentLogoDetRes = null;
        state.agentFinanceRes = null;
        state.userId = null;
        state.isNavigateToBooking = null;

    },

    saveNavBool: (state, req) => {
        state.isNavigateToBooking = req;
    },
    saveAgentLogoDetReq(state, req) {
        state.agentLogoDetReq = req;
    },
    saveAgentLogoDetRes(state, res) {
        state.agentLogoDetRes = res;
        if (res && res.userid) {
            state.userId = res.userid.toString().split('-').join('');
        } else {
            state.userId = null;
        }
    },

    saveAgentFinanceRes(state, res) {
        state.agentFinanceRes = res;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};