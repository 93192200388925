import SERVICE_URL from "../config/API_DATA";
import { API_VALUES } from "../config/API_DATA";
import BaseConfiguration from "../config/base.js";
import HttpPost from "../http/httppost.js";
import Logger from './logger.js';
import { FILE_NAME, LOG_TYPE } from "../config/API_DATA";
export default class LanguageList {
    static buildRequest() {
        let returnValue = null;
        try {
            let body = {
                orgid:API_VALUES.ORG_ID
            }


            returnValue = body;
        }
        catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_LANGUAGE_LIST, "error in building body for LanguageList api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            returnValue = await HttpPost.makeHttpPostRequest(SERVICE_URL.LANGUAGE_LIST, pReqOptions);
        }
        catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_GET_LANGUAGE_LIST, "error in getting Response from  LanguageList api ", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}