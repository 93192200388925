import SERVICE_URL, {
  LOG_TYPE
} from "../config/API_DATA";
import {
  httpClient
} from "./httpClient.js";
import main from "./../main";
import {
  Event
} from "./../main.js"
import {
  addClassToBody
} from "../util/util";
import Router from "../router/index";
import BaseConfiguration from "../config/base";
import Logger from "../services/logger";

export default class HttpUI {
  static count = 0;
  static isFareViewCalled = false;
  static async UIprocess() {
    try {
      if (Event)
        Event.$on("isFareViewCalled", (isFareViewCalled) => {
          this.isFareViewCalled = isFareViewCalled;
        });
      httpClient.interceptors.request.use((config) => {
        // for every request start the progress
        if (config.url != SERVICE_URL.BALANCE_DETAIL && config.url != SERVICE_URL.LANGUAGE_LIST) {
          if (main) main.$Progress.start();
          if (this.count >= 0) {
            this.count++;
          } else {
            this.count = 0;
          }
        }
        if (BaseConfiguration.isAddApiLoadingClass) {
          if (this.isFareViewCalled) {
            addClassToBody("Loading " + Router.currentRoute.name);
          }
          if (config.url != SERVICE_URL.RENEW_JWT &&
            config.url != SERVICE_URL.BALANCE_DETAIL && config.url != SERVICE_URL.LANGUAGE_LIST) {
            addClassToBody("Loading " + Router.currentRoute.name);
          }
        }
        return config;
      });

      httpClient.interceptors.response.use((response) => {
        //  finish when a response is received
        if (main)
          this.count--;
        if (this.count == 0) {
          main.$Progress.finish();
          if (BaseConfiguration.isAddApiLoadingClass) {
            addClassToBody(Router.currentRoute.name);
          }
        }
        return response;
      });
    } catch (err) {

      let body = Logger.buildRequest(
        "HttpUI",
        "UIprocess failed",
        err.toString(),
        "UIprocess",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
  }
}