const state = {
    allPostRequest: null,
    allPostResponse: [],
    allPostCount:null,
    projectItemsLs: [],
    countryLs: []
};

const getters = {
    GET_ALL_POST_RESPONSE: (state) => {
        return state.allPostResponse;
    },
    GET_POST_COUNT: (state) => {
        return state.allPostCount;
    },
    GET_PROJECT_ITEM_LS: (state) => {
        return state.projectItemsLs;
    },
    GET_COUNTRY_LS: (state) => {
        return state.countryLs;
    }
};


const actions = {

    CLEAR_MANAGE_POST_STORE: ({ commit }) => {
        commit('clearManagePostStore');
    },
    SET_ALL_POST_RESPONSE: ({ commit }, allPostRes) => {
        commit('saveAllPostResponse', allPostRes);
    },
    SET_POST_COUNT: ({ commit }, count) => {
        commit('savePostCount', count);
    },
    SET_PROJECT_ITEM_LS: ({ commit }, item) => {
        commit('saveProjectItemLs', item);
    },
    SET_COUNTRY_LS: ({ commit }, item) => {
        commit('saveCountryLs', item);
    },
};


const mutations = {
    clearManagePostStore: (state) => {
        state.allPostResponse = null;
        state.allPostRequest = [];
        state.projectItemsLs = [];
        state.countryLs = []
    },
    saveAllPostResponse: (state, allPostRes) => {
        state.allPostResponse = [];
        state.allPostResponse = allPostRes;
    },
    savePostCount: (state, count) => {
        state.allPostCount = count;
    },
    saveProjectItemLs: (state, item) => {
        state.projectItemsLs = item;
    },
    saveCountryLs: (state, item) => {
        state.countryLs = item;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}