import SERVICE_URL, {LOG_TYPE} from "../../config/API_DATA";
import BaseConfiguration from "../../config/base";
import { httpClient } from "../../http/httpClient.js";
import { Event } from "../../main.js";
import main from "../../main";
import Logger from "../../services/logger.js";

export default class AppHttpUI {
  static loader = null;
  // static gotResponse = false;
  static async UIprocess() {
    try {
      httpClient.interceptors.request.use((config) => {
        // for every request start the progress

        Event.$emit("progrsBarBool", true);

        if (config.url == SERVICE_URL.GET_PRICING_SEARCH) {
            this.loadingContent();
        } else if (config.url == SERVICE_URL.CREATE_BOOKING) {
          this.loadingContent();
        } else if (config.url == SERVICE_URL.API_CREATE_PAYMENT_FOR_TICKETING) {
          this.loadingContent();
        } else if (
          config.url == SERVICE_URL.UPDATE_PRICING ||
          config.url == SERVICE_URL.CREATE_PRICING
        ) {
          this.loadingContent();
        }
        return config;
      });

      httpClient.interceptors.response.use((response) => {
        //  finish when a response is received

        Event.$emit("progrsBarBool", false);
        
        // this.gotResponse=true;
    
        // if (this.loader != null) {
        // this.loader.dismiss();
        // }
        return response;
      });
    } catch (err) {
      let body = Logger.buildRequest(
        "HttpUI",
        "UIprocess failed",
        err.toString(),
        "UIprocess",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
  }

  static loadingContent() {
    // let retValue;
    try {
      if (BaseConfiguration.isDebug) console.log("loadingContent");
      setTimeout(async () => {
        if (main) {
          this.loader = await main.$ionic.loadingController.create({
            message: "Please wait...",
          });
       
        this.loader.present();
        }
        this.loader.dismiss();
        
        
      });
    } catch (err) {
      let body = Logger.buildRequest(
        "aHttpUI",
        "error in loadingContent",
        err.toString(),
        "loadingContent",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
    //   return retValue;
  }

  static loaderDismiss() {
      try {
        if(BaseConfiguration.isDebug) console.log("loaderDismiss");
        // setTimeout(async () => {
        //     await this.loader.dismiss();
        //   });
           
          this.loader.dismiss();
  
      } catch (err) {
              let body = Logger.buildRequest(
                "aHttpUI",
                "error in loaderDismiss",
                err.toString(),
                "loaderDismiss",
                LOG_TYPE.ERROR
              );
              Logger.getResponse(body);
            }
        
  }
}
