var moment = require("moment");

import Logger from "./../../services/logger";
import { FILE_NAME, LOG_TYPE } from "./../../config/API_DATA";

export function addYears(pDate, pYears) {
    let returnValue;
    try {
        if (pYears && pDate instanceof Date) {
            returnValue = moment(pDate)
                .add(pYears, "year")
                .toDate();
        } else if (pDate instanceof Date) {
            returnValue = pDate;
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in adding Years",
            err.toString(),
            "addYears()",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}
///add days from the date
export function addDays(pDate, pDays) {
    let returnValue;
    try {
        if (pDays && pDate instanceof Date) {
            returnValue = moment(pDate)
                .add(pDays, "days")
                .toDate();
        } else if (pDate instanceof Date) {
            returnValue = pDate;
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in adding Days",
            err.toString(),
            "addDays()",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}

//subtract years from the date
export function subYears(pDate, pYears) {
    let returnValue;
    try {
        let date;
        if (pDate instanceof Date) {
            date = pDate
        } else {
            date = new Date(pDate);
        }
        if (pYears && date instanceof Date) {
            returnValue = moment(date)
                .subtract(pYears, "year")
                .toDate();
        } else if (date instanceof Date) {
            returnValue = date;
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in subtracting years",
            err.toString(),
            "subYears()",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}

//add month from the date
export function addMonths(pDate, pMonth) {
    let returnValue;
    try {

        if (pDate instanceof Date) {
            pDate = pDate
        } else {
            pDate = new Date(pDate);
        }
        if (pMonth && pDate instanceof Date) {
            returnValue = moment(pDate)
                .add(pMonth, "month")
                .toDate();
        } else if (pDate instanceof Date) {
            returnValue = pDate;
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in adding Months",
            err.toString(),
            "addMonths()",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}
///subtract days from the date

export function subDays(pDate, pDays) {
    let returnValue;
    try {
        let date;
        if (pDate instanceof Date) {
            date = pDate
        } else {
            date = new Date(pDate);
        }
        if (pDays && date instanceof Date) {
            returnValue = moment(date)
                .subtract(pDays, "days")
                .toDate();
        } else if (date instanceof Date) {
            returnValue = date;
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in subtracting Days",
            err.toString(),
            "subDays()",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}



///subtract month from the date

export function subMonths(pDate, pMonths) {
    let returnValue;
    try {
        let date;
        if (pDate instanceof Date) {
            date = pDate
        } else {
            date = new Date(pDate);
        }
        if (pMonths && date instanceof Date) {
            returnValue = moment(date)
                .subtract(pMonths,
                    'months')
                .toDate();
        } else if (date instanceof Date) {
            returnValue = date;
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in subtracting Months",
            err.toString(),
            "subMonths()",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}








///returns Date Object ,all Time is set to initial

export function withOutTime(pDate) {
    let returnValue;

    try {
        if (pDate instanceof Date) {
            pDate.setSeconds(0);
            pDate.setMinutes(0);
            pDate.setHours(0);

            returnValue = pDate; //toDate();
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in remove Time from Date",
            err.toString(),
            "removeTime()",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}

/// returns formatted  String from Date Object

export function formatDate(pDate, pFormat) {
    let returnValue;

    try {
        let dateVal;
        if(pDate!=null) {
        if (pDate instanceof Date) {
            dateVal = pDate;
        } else {
            dateVal = new Date(pDate);
        }
        if (dateVal instanceof Date && pFormat) {
            returnValue = moment(dateVal).format(pFormat);
        } else if (dateVal) {
            returnValue = moment(dateVal).format();
        }
    }
    else{
        returnValue=null;
    }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in formating date",
            err.toString(),
            "formatDate()",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}

///to get Utc Date Time

export function getUtcDate(pDate) {
    let returnValue;

    try {
        if (pDate instanceof Date) {
            returnValue = pDate.toUTCString();

            if (BaseConfiguration.isDebug)  console.log(returnValue);
            returnValue = moment(pDate, "YYY-MM-DDTHH:mm:ssZ").toDate()
            if (BaseConfiguration.isDebug)  console.log(returnValue);
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in formating date",
            err.toString(),
            "getUtcTime()",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}


///to get Local Time from UTC
export function getLocalDate(pDate) {
    let returnValue;

    try {
        if (pDate instanceof Date) {


            returnValue = pDate.toLocaleString()


            returnValue = Date.parse(returnValue);
            returnValue = new Date(returnValue);

        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in getLocal Time",
            err.toString(),
            "getLocalTime)",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}
/// to get Difference between two Dates

export function getDiffBtwDates(pOldDate, pNewDate, pDiffOf) {
    let returnValue;

    try {
        if (pOldDate instanceof Date && pNewDate instanceof Date) {
            pOldDate = moment(formatDate(pOldDate,"YYYY-MM-DD"));
            pNewDate = moment(formatDate(pNewDate,"YYYY-MM-DD"));
            returnValue = pOldDate.diff(pNewDate, pDiffOf);

        }
       
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in get difference in  date",
            err.toString(),
            "getDiff()",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}

//dob Validation
export function isValidDob(pMinDate, pMaxDate, pSelDate) {
    let returnValue;
    try {

        if (pMinDate instanceof Date && pMaxDate instanceof Date) {
      
            pSelDate = new Date(pSelDate);
           
            // var momentString = momentObj.format('YYYY-MM-DD');
            
            if (pSelDate >= pMinDate && pSelDate <= pMaxDate) {
                returnValue = true;
            } else {
                returnValue = false;
            }
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in isValidDob",
            err.toString(),
            "isValidDob",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}

//passport expiry date validation

export function isValidExpiryDate(pMaxDate, pSelDate) {
    let returnValue;
    try {
        if (!pMaxDate instanceof Date)
            pMaxDate = new Date(pMaxDate);
        if (pMaxDate instanceof Date) {

            pSelDate = new Date(pSelDate);
            // var momentString = momentObj.format('YYYY-MM-DD');

            if (pSelDate >= pMaxDate) {
                returnValue = true;
            } else {
                returnValue = false;
            }
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in isValidDob",
            err.toString(),
            "isValidDob",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}

//mins to hour and minutes
export function convertToHrMin(pDuration, pType, pFormat) {
    let retValue;
    try {

        retValue = moment.utc().startOf('day').
        add(pDuration, pType).format(pFormat)

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in convertToHrMin",
            err.toString(),
            "convertToHrMin",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}
export function timeLeftFormat(pDate) {
    let retValue;
    try {
        if (pDate instanceof Date) {
            retValue = moment(pDate).fromNow();
        }
        retValue = retValue.split(" ");
        if(retValue.includes('in')){
          retValue.splice(retValue.indexOf('in'),1);
          // console.log(retValue[retValue.indexOf('in')])
          retValue = retValue.join(' ').concat(' ' +'left');
        }else if(retValue.includes('ago')){
            retValue = moment(pDate).fromNow(true);/// it will remove ago
            retValue=retValue + ' ' +'left';
        }
        else {
            retValue = retValue.join(' ');
        }
        // console.error(retValue)

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in timeAgoFormat",
            err.toString(),
            "timeAgoFormat",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function timeAgoFormat(pDate) {
    let retValue;
    try {
        if (pDate instanceof Date) {
            retValue = moment(pDate).fromNow();
        }
        retValue = retValue.split(" ");
        if(retValue.includes('in')){
          retValue.splice(retValue.indexOf('in'),1);
          // console.log(retValue[retValue.indexOf('in')])
          retValue = retValue.join(' ').concat(' ' +'ago');
        }else {
            retValue = retValue.join(' ');
        }

    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in timeAgoFormat",
            err.toString(),
            "timeAgoFormat",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }
    return retValue;
}

export function convertUTCDateToLocalDate(pDate) {
    let returnValue;

    try {
        if(pDate){
        var newDate = new Date(pDate.getTime()+pDate.getTimezoneOffset()*60*1000);

        var offset = pDate.getTimezoneOffset() / 60;
        var hours = pDate.getHours();

        newDate.setHours(hours - offset);

        returnValue = newDate;
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in convertUTCDateToLocalDate",
            err.toString(),
            "convertUTCDateToLocalDate",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}

export function isPastDate(pSelDate,pRefDate) {
    let returnValue;

    try {
        if(pSelDate){
            if(pRefDate){
                if(moment(formatDate(pSelDate,"YYYY-MM-DD")).isBefore(formatDate(pRefDate,"YYYY-MM-DD")))
                    returnValue = true;
                else
                    returnValue = false;
            }else{
                if(moment(formatDate(pSelDate,"YYYY-MM-DD")).isBefore(formatDate(new Date(),"YYYY-MM-DD")))
                    returnValue = true;
                else
                    returnValue = false;
            }
        }
    } catch (err) {
        let body = Logger.buildRequest(
            FILE_NAME.DATE_FUNCTIONS,
            "error in isPastDate",
            err.toString(),
            "isPastDate",
            LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
    }

    return returnValue;
}

//Difference in number of days
export function differnceofDates(pfrstDate,pSecDate)
{
    var start = moment(pfrstDate, "YYYY-MM-DD");
var end = moment(pSecDate, "YYYY-MM-DD");


let retvalue = moment.duration(start.diff(end)).asDays();
return retvalue;
}


//to get initial and final date of current month


export  function getCurrMonth() {
    let retValue={};
    try {
      
      let date = new Date(); 
                  
      retValue.firstDate =  
          new Date(date.getFullYear(), date.getMonth(), 1); 
            
          retValue.lastDay =  
         new Date(date.getFullYear(), date.getMonth() + 1, 0); 

     


    

    } catch (err) {
            let body = Logger.buildRequest(
              FILE_NAME.DATE_FUNCTIONS,
              "error in getCurrMonth",
              err.toString(),
              "getCurrMonth",
              LOG_TYPE.ERROR
            );
            Logger.getResponse(body);
          }
      return retValue;
    }