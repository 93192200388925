export default class SERVICE_URL {
    static LOGGER_URL = "https://b2bapplog.ascent24.io/b2b.ui";
    // static BASE_URL = "https://b2bapi.ascent24.io/"; have stored these values in env
    // static DEV_BASE_URL = "https://dev.b2bapi.ascent24.io/";

    //List of methods

    static CREATE_ROLE = "/api/userManagement/createrole";
    static GET_CAPTCHA = "api/registration/getcaptcha";
    static GET_SIGNIN_STATUS = "api/auth/signin";
    static LOG_OUT = "api/auth/logout";

    static CREATE_USER = "/api/userManagement/createUser";
    static GET_All_USER = "/api/userManagement/getUser";
    static GET_ALL_DIY_POST = "getAllDiyPost";
    static CREATE_PROJECT_ITEM = "createProjectItem";
    static UPDATE_PROJECT_ITEM = "updateProjectItem";
    static UPDATE_PROJECT_ITEM_LINK = "updateProjectItemLink";
    static CREATE_DIY_CATEGORY = "createDiyCategory";
    static API_UPLOAD_IMAGE = '/api/uploadImageToS3';
    static API_DELETE_IMAGE = '/api/deleteImageFromS3';
    static GET_ALL_DIY_CATEGORY = "getAllDiyCategory";
    static GET_ALL_DIY_FIELD_OF_WORK = "getAllDiyFieldofWork";
    static GET_ALL_DIY_PROJECT_ITEM = "getAllDiyProjectItem";
    static GET_COUNTRY_LIST = "getCountryList";
    static API_GET_DIY_GROUP="getDiyGroup"
    static UPDATE_USER = "/api/userManagement/updateUser";
    static GET_USER_ROLES = "api/userManagement/getRoles";
    static GET_DIY_CATEGORY = "diyCategory";
    static GET_DIY_GROUP = "diyGroup";
    static GET_DIY_TAG = "diyTags";
    static GET_DIY_TAGS_LIST = "diyTagsList";
    static GET_DIY_ITEM_LIST = "diyItemList";
    static GET_DIY_UNIT_LIST = "diyItemUnit";
    static GET_DIY_ECOM_SOURCE = "diyEcomSource";
    static GET_DIY_COUNTRY = "diyCountryLs";
    static API_VALIDATE_TOKEN = "api/userManagement/validateToken";

    static GET_ALL_DIY_NOTIFICATION = "api/notification/get";
    static GET_CREATE_DIY_NOTIFICATION = "api/notification/add";
    static GET_UPDATE_DIY_NOTIFICATION = "api/notification/update";

    static CHANGE_PASSWORD = "api/user/changepassword";
    static GET_ADMIN_SIGNIN_STATUS = "/api/auth/signin";

    static GET_USER_ROLES_AND_PERMISSIONS = "api/userManagement/getPermissions";
    static UPDATE_PERMISSIONS = "api/userManagement/updatePermissions";
    static BOOKING_ID = "bookingId";
    static REJECT_AGENT = "api/registration/rejectAgent";
    static APPROVE_AGENT = "api/registration/approveagent";
    static PENDING_AGENT = "api/registration/getPendingAgentRegistrations";
    static GET_MENUS = "api/utils/getMenus";
    //static GET_USER_LOGO_DET = "api/agent/getuserlogodetails";
    static RENEW_JWT = 'api/auth/renew';
    static STATUS = 'status';
    static NOTIFICATION_STATUS = 'notification-status';
    static SELECT_ORG = 'ORG';
    static LANGUAGE_LIST = "api/utils/getLang";
    static GET_MY_BOOKINGS = 'api/booking/getMyBookingsList';
    static GET_POSTPAID_DET_BY_INVOICE = 'api/postpaid/getPaidDetailsByInvoiceId';
    static GET_POSTPAID_INVOICE_BY_STMNT_ID = 'api/postpaid/getInvoicesByStatementId';
    static FORGOT_PASSWORD = 'api/userManagement/forgotPassword';
    static RESET_PASSWORD = 'api/userManagement/resetPassword';
    static BALANCE_DETAIL = 'api/agent/getBalanceDetails';
    static GET_SETTINGS = '/api/utils/getOrgSettings';
}

export class HTTP_CODES {
    static SUCCESS = 200;
    static ERROR = 400;

    static SUCCESS_STR = "Success";
    static ERROR_STR = "Error";
    static ERROR_JWT = "jwt";
    static ERROR_JWT_01 = "JWT01";
    static ERROR_JWT_02 = "JWT02";
    static ERROR_NET = "Connectivity";

    static ERROR_PRIC_FLI_FAILED = "SES08";
    static ERROR_FLI_INVALID = "SES09";


    static ERROR_SESSION_NOT_EXIST = "SES03";
    static ERROR_PER = "PER01";
    static ERROR_AUT2 = "AUT2";

    static SUBSCRIPTION_FAILED = "start-failed";

    static ERROR_INVOICES_PAID = "PST03";



}

export class LOG_TYPE {
    static INFO = 'info';
    static ERROR = "error";
    static CRITICAL = "critical";
    static WARNING = "warning";
}

export class WEBSOCKET_READYSTATE {
    static CONNECTING = 0; //Socket has been created. The connection is not yet open.
    static OPEN = 1; //The connection is open and ready to communicate.
    static CLOSING = 2; //The connection is in the process of closing.
    static CLOSED = 3; //The connection is closed or couldn't be opened.

}


/* export class DEFAULT_IMAGES {
    static CUS_RATING_DEF_IMG = "https://www.google.com";
} */

export class FILE_NAME {

    static API_ALL_ORG = 'apiAllOrg'
    static API_GET_ALL_DIY_POST = 'apiGetAllDiyPosts'
    static API_UPDATE_DIY_POST = 'apiUpdatepost'
    static API_CREATE_DIY_POST = 'apiCreatepost'
    static API_CREATE_PROJECT_ITEM = 'apiCreateProjectItem'
    static API_UPDATE_PROJECT_ITEM = 'apiUpdateProjectItem'
    static API_UPDATE_PROJECT_ITEM_LINK = 'apiUpdateProjectItemLinks'
    static API_CREATE_CATEGORY = 'apiCreateCategory'
    static API_DELETE_DIY_POST = 'apiDeletepost'
    static API_GET_DIY_CATEGORY = 'apiGetAllDiyCategories'
    static API_GET_DIY_FIELD_OF_WORK = 'apiGetDiyFieldofWork'
    static API_GET_DIY_PROJECT_ITEM = 'apiGetDiyProjectItem'
    static API_GET_COUNTRY_LS = 'apiGetCountryLs'
    static API_GET_DIY_GROUP = 'apiGetAllDiyGroup'
    static APLLO_CLIENT = 'aplloClient'
    static MANAGE_ORG = 'ManageOrg'
    static ENV_CONFIG = 'env-config.js'
    static API_GET_HOTEL_AVAILABILITY = 'apiGetHotelAvail'
    static API_GET_ADMIN_SIGNIN_STATUS = 'apiAdminSignIn.js'
    static API_GET_All_NOTIFICATIONS = 'apiGetAllNotifications.js'
    static API_CREATE_NOTIFICATIONS = 'apiCreateNotifications.js'
    static API_UPDATE_NOTIFICATIONS = 'apiUpdateNotifications.js'
    static NOTIFICATION = 'notification.js'

    static ALL_POST = 'allPost.vue'
    static ADD_DIY_POST = 'addPost.vue'
    static C_HOTEL_LANG = 'cHotelLang.vue'
    static API_GET_HOTEL_LANG = 'apiGetHotelLang.js'
    static API_ADD_STATE = 'apiAddState.js';
    static API_UPDATE_EXTENSION_SETUP = 'apiUpdateExtensionSetUp.js';
    static DASHBOARD = 'DashBoard.vue'
    static CLEAR_STORE = 'clearStore.js '

    static API_SYSTEM_TOPUP_FORECAST_REPORT = 'apiSystemTopupForecastReport.js'
    static API_GET_POSTPAID_REPORT_SUMMARY = 'apiGetPrepaidReportSummary.js'
    static API_PERIODICAL_SALES_REPORT = 'apiPeriodicalSalesReport.js'
    static API_PERIODICAL_SALES_DETAIL_REPORT = 'apiPeriodicalSalesDetailReport.js'
    static API_PREPAID_TOPUP_PROJECTION_REPORT = 'apiPrepaidTopupProjectionReport.js'
    static API_POSTPAID_TOPUP_PROJECTION_REPORT = 'apiPostpaidTopupProjectionReport';
    static API_GET_CREDIT_SALE_REPORT_SUMMARY = 'apiGetCreditSaleReportSummary.js'

    static LOCAl_STORAGE = 'localStorage.js'
    static API_GET_LANGUAGE_LIST = "apiLanguageList.js";
    static API_UPDATE_PERMISSIONS = "apiUpdatePermissions.js";
    static API_ROLES_PERMISSIONS = "apiRolesPermissions.js";
    static ROLES_PERMISSIONS = "RolesPermissions.vue";
    static SYSTEM_LOGIN = "SystemLogin.vue";
    static API_GET_CAPTCHA_LIST = "apiGetCaptchaList.js";
    static API_GET_MENUS = "apiGetMenus.js";
    static API_SIGN_IN = "apiSignIn.js";
    static API_ADMIN_SIGN_IN = "apiAdminSignIn.js";
    static ADD_USER = "AddUser.vue";
    static USER_STATUS = "UserStatus.vue";
    static API_CREATE_USER = "apiCreateUser.js";
    static API_LOG_OUT = "apiLogOut.js";

    static API_SAVE_EXCHANGE_RATE = "apiSaveExchangeRate.js";
    static API_GET_All_USER = "apiGetAllUser.js";
    static API_UPDATE_USER = "apiUpdateUser.js";
    static API_USER_ROLES = "apiUserRoles.js";
    static API_VALIDATE_TOKEN = "apiValidateToken.js";

    static API_CHANGE_PASSWORD = "apiChangePassword.js";
    static CHANGE_PASSWORD = "ChangePassword.vue";
    static cDropDown = "cDropDown.vue";
    static C_LANGUAGE_PICKER = "cLanguagePicker.vue";
    static DATE_FUNCTIONS = "dateFunctions.js";
    static LOGGER = "logger.js";
    static HTTP_POST = "httppost.js";
    static SIDE_BAR = "TheSidebar.vue";
    static APP = "app.vue";
    static THE_HEADER_DROPDOWN_ACCNT = "TheHeaderDropdownAccnt.vue";
    static UTIL = "util.js";
    static API_RENEW_JWT = "apiRenewJWT.js";
    static ROUTER = "router/index.js";
    static API_GET_PRICING_BY_TIERID = "apiGetPricingByTierId.js";
    static API_GET_PRICING_BY_PRICINGID = "apiGetPricingByPricingId.js";

    static API_UPDATE_FARE_RULE = "apiUpdateFareRule.js";
    static API_UPDATE_FLIGHT_FARE = "apiUpdateFlightFare.js";
    static API_IMPORT_VALIDATION_FLIGHT_FARE = "apiImportValidationFlightFare.js";
    static API_IMPORT_FLIGHT_FARE = "apiImportFlightFare.js";

    static API_UPDATE_TIER = "apiUpdateTier.js";
    static API_UPDATE_TIER_STATUS = "apiUpdateTierStatus.js";
    static API_CLONE_PRICING = "apiClonePricing";
    static API_DELETE_PRICING = "apiDeletePricing";
    static API_ASSIGNED_TIERS = "apiGetAssignedTiers";
    static ASSIGN_PRICING = "AssignPricing.vue";
    static ADD_NEW_PRICING = "AddNewPricing.vue";
    static API_UPDATE_PRICING = "apiUpdatePricing";
    static API_CREATE_ROLE = "apiCreateRole.js";

    static API_SEARCH_RESERVATION = 'apiSearchReservationByB2b.js';

    static APP_TOAST = 'appToast.vue';
    static MAIN_FILE = 'main';
    static API_GET_PAYMENT_LIST_AGT_MANAGE = "apiGetPaymentListAgtManage.js";
    static API_GET_TICKET_QUEUE = "apiGetTicketingQueueList.js";
    static API_GET_MY_BOOKINGS = "apiGetMyBookingsList.js";
    static API_FORGOT_PASSWORD = "apiForgotPassword.js"
    static API_RESET_PASSWORD = "apiResetPassword.js";
    static API_BALANCE_DETAIL = 'apiBalanceDetail.js';
    static API_GET_SETTINGS = 'apiGetSettings.js';

    static API_UPLOAD_IMAGE = 'apiUploadImage.js';
    static API_DELETE_IMAGE = 'apiDeleteImage.js';
}

export class API_VALUES {
    static ORG_ID = null;
}