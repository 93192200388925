<template>
  <div>
   
 <ion-header-bar :fixed="isFixedHeader" :with-subheader="false" light class="flex-flow-unset" id="main-content">
    <ion-toolbar class="toolbar">
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <span class="brand-logo-center c-header-brand mx-auto d-lg-none">
        </span>
         <ion-buttons slot="end">
            <TheHeaderDropdownAccnt style="list-style: none;"/>
          </ion-buttons>
        
        
        <CHeaderNav class="d-md-down-none mr-auto">
        </CHeaderNav>
    </ion-toolbar>
  </ion-header-bar>
    <!-- <ion-content id="main-content">
    
  </ion-content> -->
  </div>
</template>
<script>
import router, { navigate, goBack, navigateReplace } from "../../router/index";
import TheHeaderDropdownAccnt from "../../containers/TheHeaderDropdownAccnt";
export default {
  name: "aHeader",
  props: {
    isFixedHeader: {
      type: Boolean,
      default: false
    },
  },
  components: {
    TheHeaderDropdownAccnt
  },
  methods: {
   
   
  }
};
</script>
<style scoped>
/* .toolbar{
    contain: inherit;
    overflow: visible;
} */
</style>