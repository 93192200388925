const state = {
    selectedUserObj: null,
    createUserReq: null,
    createUserRes: null,
    updateUserRes: null,
    updateUserReq: null
};
//gettters
const getters = {
    GET_CREATE_USER_REQUEST: (state) => {
        return state.createUserReq;
    },
    GET_CREATE_USER_RESPONSE: (state) => {
        return state.createUserRes;
    },
    GET_UPDATE_USER_RESPONSE: (state) => {
        return state.updateUserRes;
    },
    GET_UPDATE_USER_REQUEST: (state) => {
        return state.updateUserReq;
    },

    GET_SELECTED_USER: (state) => {
        return state.selectedUserObj;
    }
};
//actions
const actions = {
    CLEAR_ADD_USER_STORE: ({ commit }) => {
        commit('clearAddUserStore');
    },
    SET_SELECTED_USER: ({ commit }, selectedUserObj) => {
        commit("saveSelectedUser", selectedUserObj);
    },

    SET_CREATE_USER_REQUEST: ({ commit }, req) => {
        commit("saveCreateUserReq", req);
    },

    SET_CREATE_USER_RESPONSE: ({ commit }, res) => {
        commit("saveCreateUserRes", res);
    },
    SET_UPDATE_USER_REQUEST: ({ commit }, req) => {
        commit("saveUpdateUserReq", req);
    },
    SET_UPDATE_USER_RESPONSE: ({ commit }, res) => {
        commit('saveUpdateUserRes', res);
    },


};
//mutations

const mutations = {
    clearAddUserStore(state) {

        state.selectedUserObj = null;
        state.createUserReq = null;
        state.createUserRes = null;
        state.updateUserRes = null;
        state.updateUserReq = null;
    },

    saveCreateUserReq(state, req) {
        state.createUserReq = req;
    },
    saveCreateUserRes(state, res) {
        state.createUserRes = res;
    },

    saveSelectedUser(state, selectedUserObj) {
        state.selectedUserObj = selectedUserObj;
    },
    saveUpdateUserReq(state, req) {
        state.saveUpdateUserReq = req;
    },
    saveUpdateUserRes(state, res) {
        state.saveUpdateUserRes = res;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};