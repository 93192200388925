//import SERVICE_URL from "../config/API_DATA"
//import HttpPost from "../http/httppost.js";
import Logger from './logger.js';
import { FILE_NAME, LOG_TYPE } from "../config/API_DATA";
import { getSysLoginResFromLocal } from '../applocalstorage/localStorage.js';

export default class RenewJWT {
    static buildRequest() {
        let returnValue = null;
        try {
            let body = {

            }
            returnValue = body;
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_RENEW_JWT, "error in building body for renew JWT api", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }

    // eslint-disable-next-line no-unused-vars
    static async getResponse(pReqOptions) {
        let returnValue = null;
        try {
            let jwtToken = null;
            let sysLogToken = getSysLoginResFromLocal();
            // let token = agtLogToken ? agtLogToken : sysLogToken ? sysLogToken : null;
            let token =   (sysLogToken ? sysLogToken : null);
            if (token) {
                jwtToken = JSON.parse(token);
                jwtToken =
                    (jwtToken && jwtToken.authorization) ? jwtToken.authorization : null;
            } 
          returnValue=  {
                "status": "Success",
                "data": {
                    "authorization": jwtToken
                }
            }
            //returnValue = await HttpPost.makeHttpPostRequest(SERVICE_URL.RENEW_JWT, pReqOptions);
        } catch (err) {
            let body = Logger.buildRequest(FILE_NAME.API_RENEW_JWT, "error in getting Response from  renew JWT api", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}