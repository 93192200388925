<template>
         <ion-content>
     <ion-slides style="width: 375px;height: 775px;" slot="fixed">
        <ion-slide >
          <div class="slide">
            <h2>Welcome</h2>
            <p>The <b>ionic conference app</b> is a practical preview of the ionic framework in action, and a demonstration of proper code use.</p>
          </div>
        </ion-slide>

        <ion-slide>
          <h2>What is Ionic?</h2>
          <p><b>Ionic Framework</b> is an open source SDK that enables developers to build high quality mobile apps with web technologies like HTML, CSS, and JavaScript.</p>
        </ion-slide>

        <ion-slide>
          <h2>What is Ionic Appflow?</h2>
          <p><b>Ionic Appflow</b> is a powerful set of services and features built on top of Ionic Framework that brings a totally new level of app development agility to mobile dev teams.</p>
        </ion-slide>

        <ion-slide>
          <h2>Ready to Play?</h2>
          <ion-button @click="introSlides()" fill="clear">Continue <ion-icon slot="end" name="arrow-forward"></ion-icon></ion-button>
        </ion-slide>

      </ion-slides>
    </ion-content>
</template>
<script>
import BaseConfiguration from "../../config/base"
import Logger from "../../services/logger";
import { LOG_TYPE,} from "../../config/API_DATA";
export default {
    name: 'aIntroSlides',
   
    methods:{
        introSlides() {
                    try {
                      if(BaseConfiguration.isDebug) console.log("introSlides");
                     this.$emit('introSlides', true);
                
                    } catch (err) {
                            let body = Logger.buildRequest(
                              "aintroSlides",
                              "error in introSlides",
                              err.toString(),
                              "introSlides",
                              LOG_TYPE.ERROR
                            );
                            Logger.getResponse(body);
                          }
                },
    }
}
</script>