import Logger from "./../services/logger";
import {
  FILE_NAME,
  LOG_TYPE
} from "../config/API_DATA";
import BaseConfiguration from "./../config/base";

export function getBaseURL() {
  let retValue;
  try {
    if (BaseConfiguration.isDebug) console.log("getBaseURL");
    retValue = process.env.VUE_APP_BASE_URL

  } catch (err) {
    let body = Logger.buildRequest(
      FILE_NAME.ENV_CONFIG,
      "error in getBaseURL",
      err.toString(),
      "getBaseURL",
      LOG_TYPE.ERROR
    );
    Logger.getResponse(body);
  }
  return retValue;
}

export function getClientCode() {
  let retValue;
  try {
    if (BaseConfiguration.isDebug) console.log("getClientCode");
    retValue = process.env.VUE_APP_CLIENTCODE
  } catch (err) {
    let body = Logger.buildRequest(
      FILE_NAME.ENV_CONFIG,
      "error in getClientCode",
      err.toString(),
      "getClientCode",
      LOG_TYPE.ERROR
    );
    Logger.getResponse(body);
  }
  return retValue;
}

export function isDebugMode() {
  let retValue;
  try {
    retValue = JSON.parse(process.env.VUE_APP_IS_DEBUG)
  } catch (err) {
    let body = Logger.buildRequest(
      FILE_NAME.ENV_CONFIG,
      "error in isDebugMode",
      err.toString(),
      "isDebugMode",
      LOG_TYPE.ERROR
    );
    Logger.getResponse(body);
  }
  return retValue;
}
