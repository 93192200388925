const state = {

    AdminSignInReq: null,
    AdminSignInRes: null,
    orgid: null,
    selProj: null
    // jwtToken:null
}

// getters
const getters = {
    // GET_JWT_TOKEN: () =>{
    //     return state.jwtToken;
    //   },

    GET_FORGOTPWD: (state) => {
        return state.frgtMail;
    },
    GET_ADMIN_SIGNIN_REQ: (state) => {
        return state.AdminSignInReq;
    },
    GET_ADMIN_SIGNIN_RES: (state) => {
        return state.AdminSignInRes;
    },
    GET_ORGID: (state) => {
        return state.orgid;
    },
    GET_SEL_PROJ: (state) => {
        return state.selProj;
    },
}

// actions
const actions = {
    CLEAR_SYS_LOGIN({
        commit
    }) {
        commit("clearSysLogin");
    },
    SET_ADMIN_SIGNIN_REQ({
        commit
    }, req) {
        commit('getAdmSignInReq', req);
    },
    SET_ADMIN_SIGNIN_RES({
        commit
    }, res) {
        commit('getAdmSignInRes', res);
    },
    SET_FORGOTPWD({ commit }, fmail) {
        commit("getforgotPwd", fmail);
    },
    SET_ORGID({ commit }, fmail) {
        commit("getorgid", fmail);
    },
    SET_SEL_PROJ({ commit }, selProj) {
        commit("saveSelProj", selProj);
    },

}

import {
    setSysLoginResToLocal
} from './../../../../applocalstorage/localStorage'
// mutations
const mutations = {
    clearSysLogin(state) {
        state.AdminSignInReq = null;
        state.AdminSignInRes = null;
        state.orgid = null;
        state.selProj = null;
    },
    saveSelProj(state, req) {
        state.selProj = req
    },
    getAdmSignInReq(state, req) {
        state.AdminSignInReq = req
    },
    getAdmSignInRes(state, res) {
        state.AdminSignInRes = res

        setSysLoginResToLocal(JSON.stringify(res))

    },
    getforgotPwd(state, fmail) {
        state.frgtMail = fmail;
    },
    getorgid(state, orgid) {
        state.orgid = orgid;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}