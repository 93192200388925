const state = {
    captchaRes: null,
    captchaText: null
}

// getters
const getters = {
    GET_CAPTCHA_RES: (state) => {
        return state.captchaRes;
    },
    GET_CAPTCHA_TEXT: (state) => {
        return state.captchaText;
    }
}

// actions
const actions = {


    CLEAR_CAPTCHA_STORE({ commit }) {
        commit('clearStore');
    },
    SET_CAPTCHA_RES({ commit }, res) {
        commit('getCaptchaRes', res);
    },
    SET_CAPTCHA_TEXT({ commit }, text) {
        commit('getCaptchaText', text);
    }
}

// mutations
const mutations = {


    clearStore(state) {
        state.captchaRes = null
        state.captchaText = null
    },
    getCaptchaRes(state, res) {
        state.captchaRes = res
    },
    getCaptchaText(state, text) {
        state.captchaText = text
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}