import HttpPost from "../http/httppost.js";
import SERVICE_URL, {
    LOG_TYPE
} from "../config/API_DATA";
import BaseConfiguration from "@/config/base.js"
var moment = require('moment');
import Router from '../router'
export default class Logger {
    static browserName;
    static browserVersion;
    static OSType;
    static appId = 2000;
    static versionCode = 1;
    static logType;

    static buildRequest(pModuleName, pErrTitle, pErrMessage, pFunctionName, pLogType = LOG_TYPE.ERROR) {
        let returnValue = null;
        try {


            let date = new Date();
            let dateString = this.formatDate(date);
            let body = {
                "timestamp": dateString,
                "message": pErrMessage,
                "level": pLogType,
                pathName: this.getPathName(),
                "module": pModuleName,
                "versioncode": BaseConfiguration.version,
                "errtitle": pErrTitle,
                "functionname": pFunctionName,


            };
            returnValue = body;
        } catch (err) {
            // console.log("LOGGER ERROR REQ: "+err.toString());
            let body = Logger.buildRequest("Logger.js", "erroR in building body data", err.toString(), "buildRequest", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }



    static getPathName() {
        let retValue;
        try {
            if (BaseConfiguration.isDebug) console.log("getPathName");

            if (Router && Router.currentRoute && Router.currentRoute.fullPath) {
                retValue = Router.currentRoute.fullPath
            } else {
                retValue = null
            }
        } catch (err) {
            let body = Logger.buildRequest(
                "logger.js",
                "error in getPathName",
                err.toString(),
                "getPathName",
                LOG_TYPE.ERROR
            );
            Logger.getResponse(body);
        }
        return retValue;
    }
    static async getResponse(pRequestBody) {
        let returnValue = null;

        try {
            if (BaseConfiguration.isDebug) console.error("from log service>>>>> " + JSON.stringify(pRequestBody));
            let body = pRequestBody;
            let header = Headers.content_type_header;
            let url = SERVICE_URL.LOGGER_URL;

            if (body != null)
                returnValue = await HttpPost.makeHttpPostRequest(url, body, header);
            else
                throw "logger req null";

        } catch (err) {
            // console.log("LOGGER ERROR RES: "+err.toString());
            let body = Logger.buildRequest("logger.js", "logger not sending data", err.toString(), "getResponse", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;

    }

    static setup() {
        let userAgentString = "testUserAgent"
        if (process.client) userAgentString = window.navigator.userAgent;
        let browserObject;

        try {

            //detecting os type
            if (userAgentString.indexOf("Andriod") != -1) this.OSType = 0;
            else if (userAgentString.indexOf("iP") != -1) this.OSType = 1;
            else this.OSType = 2;

            //for time zone
            Date.prototype.toIsoString = function () {
                var tzo = -this.getTimezoneOffset(),
                    dif = tzo >= 0 ? '+' : '-',
                    pad = function (num) {
                        var norm = Math.floor(Math.abs(num));
                        return (norm < 10 ? '0' : '') + norm;
                    };
                return dif + pad(tzo / 60) + ':' + pad(tzo % 60);
            };

            //get browser name and version
            browserObject = this.get_browser();

            this.browserName = browserObject.name;
            this.browserVersion = browserObject.version;
        } catch (err) {
            let body = Logger.buildRequest("logger.js", "error occured in setup", err.toString(), "setup", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }

    }

    static get_browser() {
        try {
            var ua = navigator.userAgent,
                tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return {
                    name: 'IE',
                    version: (tem[1] || '')
                };
            }
            if (M[1] === 'Chrome') {
                tem = ua.match(/\bOPR|Edge\/(\d+)/)
                if (tem != null) {
                    return {
                        name: 'Opera',
                        version: tem[1]
                    };
                }
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) {
                M.splice(1, 1, tem[1]);
            }
            return {
                name: M[0],
                version: M[1]
            };
        } catch (err) {

            let body = Logger.buildRequest("App.vue", "error in getting browser name and version", err.toString(), "get_browser", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
    }

    static formatDate(DateObject) {

        let returnValue = null;

        try {

            let dateFormat = "YYYY-MM-DD HH:mm:ss"
            let utcDate = moment.utc(DateObject).format(dateFormat);
            return utcDate;

            // let year = DateObject.getFullYear().toString();
            // let month = DateObject.getMonth() + 1;
            // let day = DateObject.getDate();
            // let Hours = DateObject.getHours();
            // let minutes = DateObject.getMinutes();
            // let seconds = DateObject.getSeconds();
            // let timeZoneOffset = DateObject.toISOString();


            // if (month < 10) {
            //     month = '0' + month;
            // }

            // if (day < 10) {
            //     day = '0' + day;
            // }

            // if (Hours < 10) {
            //     Hours = '0' + Hours;
            // }

            // if (minutes < 10) {
            //     minutes = '0' + minutes;
            // }

            // if (seconds < 10) {
            //     seconds = '0' + seconds;
            // }

            // returnValue = year + '-' + month + '-' + day + " " + Hours + ':' + minutes + ':' + seconds + timeZoneOffset;


        } catch (err) {
            let body = Logger.buildRequest("logger.js", "error in formatting the date", err.toString(), "formateDate", LOG_TYPE.ERROR);
            Logger.getResponse(body);
        }
        return returnValue;
    }
}