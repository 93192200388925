<template>
    <cUserInfo></cUserInfo>
</template>
<script>
import cUserInfo from "../../components/userinfo/cUserInfo"
import {addClassToBody, removeClassBody} from "../../util/util.js";
import {COMPONENT_NAME} from "../../util/constants"
export default {
    name: 'aProfile',
    components:{
        cUserInfo
    },
    beforeCreate() {
            addClassToBody(COMPONENT_NAME.APROFILE);
        },

        beforeDestroy() {
            removeClassBody();
        },
}
</script>